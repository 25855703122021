import React from 'react';
import "./index.scss";

import Footer from '../../components/Footer';

export default function TermsOfService(props) {
    return (
        <>
            {props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' || props.location === 'HR' || props.language.ISO === 'HR' ? <div className='route__termsOfService'>
                <h1>Uslovi korištenja</h1>
                <br></br>
                <p>
                    Dobrodošli na naš web shop. Ovi uslovi korištenja definišu pravila i propise za korištenje naše web stranice.
                    <br></br><br></br>
                    <strong>Uvod</strong>
                    <br></br><br></br>
                    Pristupanjem ovoj web stranici, prihvatate da ste vezani ovim uslovima korištenja, svim primjenjivim zakonima i propisima, i slažete se da ste odgovorni za poštovanje bilo kojih primjenjivih lokalnih zakona. Ako se ne slažete sa bilo kojim od ovih uslova, zabranjeno vam je korištenje ili pristupanje ovoj stranici.
                    <br></br><br></br>
                    <strong>Politika dostave</strong>
                    <br></br><br></br>
                    Nudimo dostavu naših proizvoda na adresu koju ste naveli prilikom odjave. Imajte na umu da se vremena isporuke mogu razlikovati ovisno o vašoj lokaciji i dostupnosti proizvoda. Učinit ćemo sve da dostavimo vašu narudžbu unutar procijenjenog vremenskog okvira, ali ne možemo biti odgovorni za bilo kakva kašnjenja koja mogu nastati zbog okolnosti izvan naše kontrole.
                    <br></br><br></br>
                    <strong>Politika povrata novca</strong>
                    <br></br><br></br>
                    Želimo da budete potpuno zadovoljni vašom kupovinom na našem web shopu. Ako iz bilo kojeg razloga niste zadovoljni vašom narudžbom, molimo vas da nas kontaktirate u roku od 14 dana od primitka vaše narudžbe kako biste zatražili povrat novca. Pružit ćemo vam upute o tome kako vratiti proizvod, a nakon što smo primili i pregledali proizvod, izdat ćemo povrat novca na izvorni način plaćanja. Imajte na umu da se troškovi dostave ne vraćaju, a vi ćete biti odgovorni za plaćanje troškova povratne dostave.
                    <br></br><br></br>
                    <strong>Politika otkazivanja</strong>
                    <br></br><br></br>
                    Možete otkazati svoju narudžbu u bilo koje vrijeme prije nego što bude poslana. Ako je vaša narudžba već poslana, još uvijek je možete otkazati, ali bit ćete odgovorni za troškove povratne dostave. Da biste otkazali svoju narudžbu, kontaktirajte nas što je prije moguće.
                    <br></br><br></br>
                    <strong>Prava intelektualnog vlasništva</strong>
                    <br></br><br></br>
                    Svi sadržaji na ovoj web stranici, uključujući, ali ne ograničavajući se na tekst, grafike, logotipe, slike i softver, vlasništvo su našeg web shopa ili naših dobavljača sadržaja i zaštićeni su međunarodnim zakonima o autorskim pravima.
                    <br></br><br></br>
                    <strong>Ograničenje odgovornosti</strong>
                    <br></br><br></br>
                    Ni u kom slučaju naš web shop ili njegovi dobavljači neće biti odgovorni za bilo kakvu štetu (uključujući, bez ograničenja, štetu zbog gubitka podataka ili profita, ili zbog prekida poslovanja) koja proizlazi iz upotrebe ili nemogućnosti korištenja materijala na našoj web stranici, čak i ako smo obaviješteni usmeno ili pismeno o mogućnosti takve štete.
                    <br></br><br></br>
                    <strong>Promjene ovih uvjeta</strong>
                    <br></br><br></br>
                    Zadržavamo pravo da u bilo koje vrijeme bez prethodne najave izmijenimo ove uvjete korištenja. Korištenjem ove web stranice, slažete se da ste vezani trenutnom verzijom ovih uvjeta.
                    <br></br><br></br>
                    <strong>Zakon koji se primjenjuje</strong>
                    <br></br><br></br>
                    Ovi uvjeti korištenja bit će regulirani i tumačeni u skladu s [unesite zakon koji se primjenjuje], a Vi nepovratno prihvaćate isključivu nadležnost sudova u toj državi ili lokaciji.
                    <br></br><br></br>
                    Ako imate bilo kakvih pitanja u vezi s ovim uvjetima korištenja, molimo kontaktirajte nas.
                </p>
            </div> : ''}
            {
                props.location === 'SI' || props.language.ISO === 'SI' ? <div className='route__termsOfService'>
                    <h1>Pogoji uporabe za spletno trgovino</h1>
                    <br></br>
                    <p>
                        Dobrodošli v naši spletni trgovini. Ti pogoji uporabe opisujejo pravila in predpise za uporabo naše spletne strani.
                        <br></br><br></br>
                        <strong>Uvod</strong>
                        <br></br><br></br>
                        Z dostopom do te spletne strani se strinjate s temi pogoji uporabe, vsemi ustrezni zakoni in predpisi ter se strinjate, da ste odgovorni za skladnost z vsemi ustrezni lokalnimi zakoni. Če se s katerim koli od teh pogojev ne strinjate, je uporaba te spletne strani prepovedana.
                        <br></br><br></br>
                        <strong>Politika dostave</strong>
                        <br></br><br></br>
                        Ponujamo dostavo naših izdelkov na naslov, ki ga navedete med oddajo naročila. Upoštevajte, da se lahko časi dostave razlikujejo glede na vašo lokacijo in razpoložljivost izdelka. Potrudili se bomo, da bomo vaše naročilo dostavili v predvidenem časovnem okvirju, vendar ne moremo biti odgovorni za morebitne zamude, ki bi se lahko zgodile zaradi okoliščin, ki so izven našega nadzora.
                        <br></br><br></br>
                        <strong>Politika vračil</strong>
                        <br></br><br></br>
                        Želimo, da ste popolnoma zadovoljni z nakupom v naši spletni trgovini. Če iz kakršnega koli razloga niste zadovoljni s svojim naročilom, nas prosimo kontaktirajte v 14 dneh po prejemu naročila in zahtevajte vračilo. Posredovali vam bomo navodila, kako vrniti izdelek, ko bomo prejeli in pregledali izdelek, vam bomo povrnili kupnino na izvorno način plačila. Upoštevajte, da stroški pošiljanja niso vračljivi in da boste morali plačati stroške vračila pošiljanja.
                        <br></br><br></br>
                        <strong>Politika odpovedi naročila</strong>
                        <br></br><br></br>
                        Naročilo lahko kadar koli prekličete pred odpremo. Če je vaše naročilo že bilo odposlano, lahko še vedno prekličete svoje naročilo, vendar boste odgovorni za stroške vračila pošiljanja. Za odpoved naročila nas kontaktirajte čim prej.
                        <br></br><br></br>
                        <strong>Pravice intelektualne lastnine</strong>
                        <br></br><br></br>
                        Vsebina na tej spletni strani, vključno z, vendar ne omejeno na besedilo, grafiko, logotipe, slikami in programsko opremo, je last naše spletne trgovine ali naših dobaviteljev vsebin in je zaščitena z mednarodnimi zakoni o avtorskih pravicah.
                        <br></br><br></br>
                        <strong>Omejitev odgovornosti</strong>
                        <br></br><br></br>
                        V nobenem primeru naša spletna trgovina ali njeni dobavitelji ne odgovarjajo za morebitno škodo (vključno, vendar ne omejeno na izgubo podatkov ali dobička ali poslovno prekinitev), ki izhaja iz uporabe ali nezmožnosti uporabe gradiv na naši spletni strani, tudi če smo bili ustno ali pisno obveščeni o možnosti takšne škode.
                        <br></br><br></br>
                        <strong>Spremembe teh pogojev</strong>
                        <br></br><br></br>
                        Pridržujemo si pravico do spremembe teh pogojev storitve kadarkoli, brez predhodnega obvestila. Z uporabo te spletne strani se strinjate s trenutno veljavno različico teh pogojev storitve.
                        <br></br><br></br>
                        <strong>Veljavno pravo</strong>
                        <br></br><br></br>
                        Ti pogoji storitve se urejajo in razlagajo v skladu z zakoni [vstavite veljavno pravo], vi pa se nepreklicno podrejate izključni pristojnosti sodišč v tistem državnem okrožju ali lokaciji.
                        <br></br><br></br>
                        Če imate kakršna koli vprašanja o teh pogojih storitve, nas kontaktirajte.
                    </p>
                </div>
                    :
                    <div className='route__termsOfService'>
                        <h1>Terms of Service for Web Shop</h1>
                        <br></br>
                        <p>
                            Welcome to our web shop. These terms of service outline the rules and regulations for the use of our website.
                            <br></br><br></br>
                            <strong>Introduction</strong>
                            <br></br><br></br>
                            By accessing this website, you agree to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.
                            <br></br><br></br>
                            <strong>Delivery Policy</strong>
                            <br></br><br></br>
                            We offer delivery of our products to the address provided by you during checkout. Please note that delivery times may vary depending on your location and the availability of the product. We will make every effort to deliver your order within the estimated time frame, but we cannot be held responsible for any delays that may occur due to circumstances beyond our control.
                            <br></br><br></br>
                            <strong>Refund Policy</strong>
                            <br></br><br></br>
                            We want you to be completely satisfied with your purchase from our web shop. If for any reason you are not happy with your order, please contact us within 14 days of receiving your order to request a refund. We will provide you with instructions on how to return the product, and once we have received and inspected the product, we will issue a refund to the original method of payment. Please note that shipping costs are non-refundable, and you will be responsible for paying for the return shipping costs.
                            <br></br><br></br>
                            <strong>Cancellation Policy</strong>
                            <br></br><br></br>
                            You may cancel your order at any time before it has been shipped. If your order has already been shipped, you may still cancel your order, but you will be responsible for the return shipping costs. To cancel your order, please contact us as soon as possible.
                            <br></br><br></br>
                            <strong>Intellectual Property Rights</strong>
                            <br></br><br></br>
                            All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of our web shop or our content suppliers and is protected by international copyright laws.
                            <br></br><br></br>
                            <strong>Limitation of Liability</strong>
                            <br></br><br></br>
                            In no event shall our web shop or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website, even if we have been notified orally or in writing of the possibility of such damage.
                            <br></br><br></br>
                            <strong>Changes to these Terms</strong>
                            <br></br><br></br>
                            We reserve the right to modify these terms of service at any time, without notice. By using this website, you agree to be bound by the current version of these terms of service.
                            <br></br><br></br>
                            <strong>Governing Law</strong>
                            <br></br><br></br>
                            These terms of service shall be governed by and construed in accordance with the laws of [insert governing law], and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                            <br></br><br></br>
                            If you have any questions about these terms of service, please contact us.
                        </p>
                    </div>
            }
            <Footer location={props.location} language={props.language} />
        </>
    )
}
