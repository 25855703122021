import React from 'react';
import "./index.scss";
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Button from '../../components/customComponents/Button';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/sea-green';
import SingleProduct from '../../components/SingleProduct';
import Footer from "../../../src/components/Footer";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import Spinner from '../../components/customComponents/Spinner';

export default function ProductPage(props) {

    const [currency, setCurrency] = React.useState(props.location === 'EN' || props.language.ISO === 'EN' ? 'EUR' : props.location === 'SI' || props.language.ISO === 'SI' || props.location === 'HR' || props.language.ISO === 'HR' ? 'EUR' : props.location === 'BA' || props.language.ISO === 'BA' ? 'KM' : props.location === 'RS' || props.language.ISO === 'RS' ? 'RSD' : 'EUR');

    const [allProducts, setAllProducts] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);

    const { slog } = useParams();
    const [curProduct, setCurProduct] = React.useState();
    const [curImage, setCurImage] = React.useState(null);
    const [price, setPrice] = React.useState(0);
    const [oldPrice, setOldPrice] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);

    const getAllProducts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProductsPagination`,
            data: {
                pagination: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                //res.data.data = 0;
                setAllProducts(res.data);
                setLoaded(true);
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllProducts();
    }, []);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getProductBySlog`,
            data: {
                Slog: slog,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                const list = String(res.data.data.ListDescription).split('|');
                const listSI = String(res.data.data.ListDescriptionSI).split('|');
                const listEN = String(res.data.data.ListDescriptionEN).split('|');
                setCurProduct({
                    ProductName: res.data.data.ProductName,
                    Description: res.data.data.Description,
                    DescriptionSI: res.data.data.DescriptionSI,
                    DescriptionEN: res.data.data.DescriptionEN,
                    OldPrice: props.location === 'BA' ? res.data.data.OldPrice : props.location === 'RS' ? res.data.data.OldPriceRS : props.location === 'HR' ? res.data.data.OldPriceHR : res.data.data.OldPriceSI,
                    CurrentPrice: props.location === 'BA' ? res.data.data.CurrentPrice : props.location === 'RS' ? res.data.data.CurrentPriceRS : props.location === 'HR' ? res.data.data.CurrentPriceHR : res.data.data.CurrentPriceSI,
                    ImageURL: res.data.data.ImageURL,
                    ImageURLtwo: res.data.data.ImageURLtwo,
                    ImageURLthree: res.data.data.ImageURLthree,
                    Quantity: 1,
                    ID: res.data.data.ID,
                    ListDescription: list,
                    ListDescriptionSI: listSI,
                    ListDescriptionEN: listEN,
                    LongDescription: props.location === 'EN' || props.language.ISO === 'EN' ? res.data.data.LongDescriptionEN :  props.location === 'SI' || props.language.ISO === 'SI' ? res.data.data.LongDescriptionSI : props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' || props.location === 'HR' || props.language.ISO === 'HR' ? res.data.data.LongDescription : res.data.data.LongDescriptionEN,
                    Usage: props.location === 'EN' || props.language.ISO === 'EN' ? res.data.data.UsageEN : props.location === 'SI' || props.language.ISO === 'SI' ? res.data.data.UsageSI : props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' || props.location === 'HR' || props.language.ISO === 'HR' ? res.data.data.Usage : res.data.data.UsageEN,
                    Ingredients: props.location === 'SI' ? JSON.parse(res.data.data.IngredientsSI) : JSON.parse(res.data.data.Ingredients),
                    Warnings: props.location === 'EN' || props.language.ISO === 'EN' ? res.data.data.WarningsEN : props.location === 'SI' || props.language.ISO === 'SI' ? res.data.data.WarningsSI : props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' || props.location === 'HR' || props.language.ISO === 'HR' ? res.data.data.Warnings : res.data.data.WarningsEN,
                });
                setPrice(props.location === 'HR' || props.language.ISO === 'HR' ? res.data.data.CurrentPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? res.data.data.CurrentPriceRS : props.location === 'EN' || props.language.ISO === 'EN' || props.location === 'SI' || props.language.ISO === 'SI' ? res.data.data.CurrentPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? res.data.data.CurrentPrice : res.data.data.CurrentPriceSI);
                setOldPrice(props.location === 'HR' || props.language.ISO === 'HR' ? res.data.data.OldPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? res.data.data.OldPriceRS : props.location === 'EN' || props.language.ISO === 'EN' || props.location === 'SI' || props.language.ISO === 'SI' ? res.data.data.OldPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? res.data.data.OldPrice : res.data.data.OldPriceSI)
                setCurImage(res.data.data.ImageURL);
            };
        }).catch(() => {

        });
        document.querySelector('#root')?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }, [allProducts, slog, props.language]);

    const QuantityField = (props) => {
        const [counter, setCounter] = React.useState(props.quantity);

        const decrease = () => {
            if (counter > 1) {
                setCounter(prev => { return prev - 1 });
                curProduct.Quantity = curProduct.Quantity - 1;
                setPrice(prev => { return (Number(prev) - Number(curProduct.CurrentPrice)).toFixed(2) });
            }
        }

        const increase = () => {
            setCounter(prev => { return prev + 1 });
            curProduct.Quantity = curProduct.Quantity + 1;
            setPrice(prev => { return (Number(prev) + Number(curProduct.CurrentPrice)).toFixed(2) });
        }

        return (
            <div id='quantity'><strong onClick={() => decrease()}>-</strong><p>{counter}</p><strong onClick={() => increase()}>+</strong></div>
        )
    }

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigate = (path, pathENG, pathSI) => {
        if (
            props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR'
        ) {
            animateNavigate(path);
        }
        else if (props.location === 'SI') {
            animateNavigate(pathSI);
        }
        else {
            animateNavigate(pathENG);
        }
    }

    const [popup, setPopup] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [addedProduct, setAddedProduct] = React.useState(null);
    const popupHandler = (ID) => {
        if (!popup) {
            setPopup(p => !p);
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/getProductByID`,
                data: {
                    ID: ID,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setAddedProduct({
                        ProductName: res.data.data.ProductName,
                        CurrentPrice: props.location === 'BA' ? res.data.data.CurrentPrice : props.location === 'RS' ? res.data.data.CurrentPriceRS : props.location === 'HR' ? res.data.data.CurrentPriceHR : res.data.data.CurrentPriceSI,
                        OldPrice: props.location === 'BA' ? res.data.data.OldPrice : props.location === 'RS' ? res.data.data.OldPriceRS : props.location === 'HR' ? res.data.data.OldPriceHR : res.data.data.OldPriceSI,
                        ImageURL: res.data.data.ImageURL,
                    });
                };
            }).catch(() => {

            }).finally(() => {
                setSpinner(false);
            });;
        }
    }

    React.useEffect(() => {
        if (curProduct) {
            setDiscount((price - oldPrice) / oldPrice * 100);
        }
    }, [curProduct]);

    const [activeTab, setActiveTab] = React.useState('about');

    const [filters, setFilters] = React.useState([]);
    const [opinions, setOpinions] = React.useState([]);
    const getAllOpinions = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/opinions/getAllOpinions`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ProductID', op: 'eq', value: curProduct?.ID }
                ]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            setOpinions(res.data);
        }).catch(() => { });
    }

    React.useEffect(() => {
        getAllOpinions();
    }, [filters, curProduct]);

    React.useEffect(() => {
        setCurrency(props.location === 'RS' || props.language.ISO === 'RS' ? 'RSD' : props.location === 'EN' || props.language.ISO === 'EN' ? 'EUR' : props.location === 'SI' || props.language.ISO === 'SI' || props.location === 'HR' || props.language.ISO === 'HR' ? 'EUR' : props.location === 'BA' || props.language.ISO === 'BA' ? 'KM' : 'EUR');
    }, [props.language]);

    return (
        <>
            <div className='route__productPage'>
                <div style={{ display: popup ? 'block' : 'none' }} className="route__landingPage__popup">
                    <div>
                        <div style={{ display: spinner ? 'block' : 'none' }}><Spinner width='32px' height='32px' align='center' /></div>
                        <img id="close" src="images/close.png" alt="" onClick={() => setPopup(false)} />
                        <img id="prodimg" src={addedProduct?.ImageURL} alt='' />
                        <strong>{props.language.Proizvod} {addedProduct?.ProductName} {props.language.JeDodan}</strong>
                        <span><p id='old'>{addedProduct?.OldPrice}</p><p id="new">{addedProduct?.CurrentPrice}</p></span>
                        <span id="btns">
                            <Button className='btn' accent='#405481' value={props.language.NastaviKupovinu} onClick={() => setPopup(false)} />
                            <Button className='btn' accent='#405481' value={props.language.IdiUkosaricu} onClick={() => navigate('/kosarica', '/shoppingcart', '/kosara')} />
                        </span>
                    </div>
                </div>
                <div className='route__productPage__gridbox'>
                    <div id='discount'><p>{Number(discount).toFixed(0)}%</p></div>
                    {
                        loaded && curProduct ?
                            <>
                                <div id='imgbox'>
                                    <div id='sidebar'>
                                        <img onClick={() => setCurImage(curProduct.ImageURL)} src={curProduct.ImageURL} alt='' />
                                        <img style={{ display: curProduct.ImageURLtwo !== 'NO_IMAGE_FOUND' ? 'block' : 'none' }} onClick={() => setCurImage(curProduct.ImageURLtwo)} src={curProduct.ImageURLtwo} alt='' />
                                        <img style={{ display: curProduct.ImageURLtwo !== 'NO_IMAGE_FOUND' ? 'block' : 'none' }} onClick={() => setCurImage(curProduct.ImageURLthree)} src={curProduct.ImageURLthree} alt='' />
                                    </div>
                                    <img id='prodimg' src={curImage} alt='' />
                                </div>
                                <div>
                                    <h2>{curProduct.ProductName}</h2>
                                    <p>{props.location === 'EN' || props.language.ISO === 'EN' ? curProduct.DescriptionEN : props.location === 'SI' || props.language.ISO === 'SI' ? curProduct.DescriptionSI : props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' || props.location === 'HR' || props.language.ISO === 'HR' ? curProduct.Description : curProduct.DescriptionEN}</p>
                                    {
                                        props.location === 'SI' || props.language.ISO === 'SI' ?
                                            curProduct.ListDescriptionSI.map((item) => {
                                                return <span><img src='images/pparrow.png' alt='' /><p>{item}</p></span>
                                            })
                                            :
                                            props.location === 'EN' || props.language.ISO === 'EN' ?
                                                curProduct.ListDescriptionEN.map((item) => {
                                                    return <span><img src='images/pparrow.png' alt='' /><p>{item}</p></span>
                                                })
                                                :
                                                props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' || props.location === 'HR' || props.language.ISO === 'HR' ?
                                                    curProduct.ListDescription.map((item) => {
                                                        return <span><img src='images/pparrow.png' alt='' /><p>{item}</p></span>
                                                    })
                                                    :
                                                    curProduct.ListDescriptionEN.map((item) => {
                                                        return <span><img src='images/pparrow.png' alt='' /><p>{item}</p></span>
                                                    })
                                    }
                                    <div id='delivery'>
                                        <span>
                                            <img src='images/shippingSmall.png' alt='' />
                                            <p>{props.language.ProizvodJeNaStanju}</p>
                                        </span>
                                        <span>
                                            <img src='images/boxSmall.png' alt='' />
                                            <p>{props.language.IsporukaDanas}</p>
                                        </span>
                                    </div>
                                    <strong id='oldprice'>{oldPrice} {currency}</strong>
                                    <h2>{currency} {price}</h2>
                                    <p>{props.language.Kolicina}</p>
                                    <QuantityField quantity={curProduct.Quantity} />
                                    <Button onClick={() => { props.addToCartProductPage(curProduct.ID, curProduct.Quantity); popupHandler(curProduct.ID) }} className='addToBasketBtn' value={props.language.DodajUkorpu} accent='#0458AD' />
                                    <p style={{ display: 'flex', gap: '10px' }}>{props.language.IliNazovite} <span style={{ color: 'rgba(242, 153, 74, 1)' }}>{props.language.CCphoneNumber}</span></p>
                                    <div id='shipping'>
                                        <img src='images/dostava.png' alt='' />
                                        <p>
                                            <strong>{props.language.BesplatnaDostava}</strong>
                                            {props.language.DostavaUroku24sata}
                                        </p>
                                    </div>
                                </div></>
                            : ''
                    }
                </div>
                <div className='route__productPage__description'>
                    <div className='route__productPage__description__tabs'>
                        <div className='route__productPage__description__tabs__nav'>
                            <div onClick={() => setActiveTab('about')} style={{ background: activeTab === 'about' ? '#0458AD' : '#ECECEC' }}>
                                <p style={{ color: activeTab === 'about' ? 'white' : '#001B57' }}>{props.language.Oproizvodu}</p>
                            </div>
                            <div onClick={() => setActiveTab('usage')} style={{ background: activeTab === 'usage' ? '#0458AD' : '#ECECEC' }}>
                                <p style={{ color: activeTab === 'usage' ? 'white' : '#001B57' }}>{props.language.Upotreba}</p>
                            </div>
                            <div onClick={() => setActiveTab('ingredients')} style={{ background: activeTab === 'ingredients' ? '#0458AD' : '#ECECEC' }}>
                                <p style={{ color: activeTab === 'ingredients' ? 'white' : '#001B57' }}>{props.language.Sastojci}</p>
                            </div>
                            <div onClick={() => setActiveTab('warnings')} style={{ background: activeTab === 'warnings' ? '#0458AD' : '#ECECEC' }}>
                                <p style={{ color: activeTab === 'warnings' ? 'white' : '#001B57' }}>{props.language.Upozorenja}</p>
                            </div>
                        </div>
                        <div className='route__productPage__description__tabs__tab'>
                            {
                                activeTab === 'about' && <div style={{ display: activeTab === 'about' ? '' : 'none' }}>
                                    <h1>{curProduct?.ProductName}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: curProduct?.LongDescription }}></p>
                                    <h3>Certifikati</h3>
                                    <span id='cert'>
                                        <img id='tuh' src='images/tuhISO.svg' alt='' />
                                        <img id='tuh' src='images/tuhHacp.svg' alt='' />
                                        <img id='circle' src='images/gmp.svg' alt='' />
                                        <img id='circle' src='images/halal.png' alt='' />
                                    </span>
                                </div>
                            }
                            {
                                activeTab === 'usage' && <div style={{ display: activeTab === 'usage' ? 'block' : 'none' }}>
                                    <p dangerouslySetInnerHTML={{ __html: curProduct?.Usage }}></p>
                                </div>
                            }
                            {
                                activeTab === 'ingredients' && <div style={{ display: activeTab === 'ingredients' ? '' : 'none' }}>
                                    <div className='ingredients__table'>
                                        <div className='ingredients__table__heading'>
                                            <div>
                                                <p>{props.language.Sastojak}</p>
                                            </div>
                                            <div>
                                                <p>{props.language.KolicinaTabela}</p>
                                            </div>
                                        </div>
                                        {
                                            curProduct?.Ingredients.map((item, index) => {
                                                if (item.name !== '') {
                                                    return <div className='ingredients__table__row'>
                                                        <div style={{ background: (index + 1) % 2 === 0 ? '#F6F6F6' : 'white' }}>
                                                            <p>{item.name}</p>
                                                        </div>
                                                        <div style={{ background: (index + 1) % 2 === 0 ? '#F6F6F6' : 'white' }}>
                                                            <p>{item.quantity}</p>
                                                        </div>
                                                    </div>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                activeTab === 'warnings' && <div style={{ display: activeTab === 'warnings' ? '' : 'none' }}>
                                    <p dangerouslySetInnerHTML={{ __html: curProduct?.Warnings }}></p>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='route__productPage__opinions'>
                    <h1>{props.language.MisljenjaKupaca}</h1>
                    <Splide options={{
                        perPage: window.innerWidth > 1189 ? 3 : window.innerWidth > 768 ? 2 : 1
                    }} aria-label="My Favorite Images">
                        {
                            loaded ?
                                (opinions?.data ?? []).map((item, index) => {
                                    return <SplideSlide>

                                        <div style={{ background: `url(${item.ImageURL})` }} className='route__productPage__opinions__item'>
                                            <h3>{props.location === 'BA' ? item.Heading : item.HeadingSI}</h3>
                                            <p>{props.location === 'BA' ? item.Text : item.TextSI}</p>
                                            <strong>{props.location === 'BA' ? item.Name : item.NameSI}</strong>
                                            <h6>{item.Years} godina</h6>
                                        </div>

                                    </SplideSlide>

                                })
                                : ''
                        }
                    </Splide>
                </div>

                <h2 id='recommended'>{props.language.PreporucenoZaVas}</h2>
                <Splide options={{
                    perPage: window.innerWidth > 700 ? Math.floor((window.innerWidth > 1526 ? 1526 : window.innerWidth) / 310) : Math.floor((window.innerWidth > 700 ? 700 : window.innerWidth) / 150)
                }} aria-label="My Favorite Images">
                    {
                        loaded ?
                            allProducts.data?.map((item, index) => {
                                return <SplideSlide>

                                    <SingleProduct
                                        imgURL={item.ImageURL}
                                        ProductName={item.ProductName}
                                        Description={props.location === 'SI' || props.language.ISO === 'SI' ? item.DescriptionSI : props.location === 'EN' || props.language.ISO === 'EN' ? item.DescriptionEN : props.location === 'HR' || props.language.ISO === 'HR' || props.location === 'BA' || props.language.ISO === 'BA' || props.location === 'RS' || props.language.ISO === 'RS' ? item.Description : ''}
                                        Status={item.Status === 'DOSTUPNO' && props.location === 'EN' || props.language.ISO === 'EN' ? 'AVAILABLE' : props.language.ISO === 'BA' || props.location === 'BA' || props.language.ISO === 'HR' || props.location === 'HR' || props.language.ISO === 'RS' || props.location === 'RS' || props.language.ISO === 'SI' || props.location === 'SI' ? 'DOSTUPNO' : 'AVAILABLE'}
                                        OldPrice={props.location === 'EN' || props.language.ISO === 'EN' ? item.OldPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? item.OldPrice : props.location === 'HR' || props.language.ISO === 'HR' ? item.OldPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? item.OldPriceRS : item.OldPriceSI}
                                        CurPrice={props.location === 'EN' || props.language.ISO === 'EN' ? item.CurrentPriceSI : props.location === 'BA' || props.language.ISO === 'BA' ? item.CurrentPrice : props.location === 'HR' || props.language.ISO === 'HR' ? item.CurrentPriceHR : props.location === 'RS' || props.language.ISO === 'RS' ? item.CurrentPriceRS : item.CurrentPriceSI}
                                        Currency={props.location === 'EN' || props.language.ISO === 'EN' ? 'EUR' : props.location === 'SI' || props.language.ISO === 'SI' || props.location === 'HR' || props.language.ISO === 'HR' ? 'EUR' : props.location === 'BA' || props.language.ISO === 'BA' ? 'KM' : props.location === 'RS' || props.language.ISO === 'RS' ? 'RSD' : 'EUR'}
                                        onClick={() => { props.addToCart(item.ID); popupHandler(item.ID) }}
                                        viewProduct={() => { navigate(`/proizvod/${item.Slog}`, `/product/${item.Slog}`, `/izdelek/${item.Slog}`) }}
                                        language={props.language}
                                    />

                                </SplideSlide>

                            })
                            : ''
                    }
                </Splide>
            </div>
            <Footer location={props.location} language={props.language} />
        </>
    )
}
