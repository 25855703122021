import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';

export default function SingleProduct(props) {

  const [error, setError] = React.useState(false);
  const showErrorMessage = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2500);
  }

  return (
    <div className='component__singleProduct'>
      <div style={{ display: error ? 'block' : 'none' }} className='component__singleProduct__error'>
        <p>{props.language.NemaNaStanju}</p>
      </div>
      <div className='component__singleProduct__image'>
        <img src={props.imgURL} alt='' onClick={props.viewProduct} />
      </div>

      <div className='component__singleProduct__description'>
        <h3 onClick={props.viewProduct}>{props.ProductName}</h3>
        <p onClick={props.viewProduct}>{props.Description}</p>
        <span><img src='images/4.5.png' alt='' /> <p onClick={props.viewProduct}>{props.Status}</p></span>
      </div>
      <div className='component__singleProduct__price'>
        <div>
          <span id='discount'><p onClick={props.viewProduct}>{props.Currency}</p><p onClick={props.viewProduct}>{props.OldPrice}</p></span>
          <span id='curPrice'><p onClick={props.viewProduct}>{props.Currency}</p><p onClick={props.viewProduct}>{props.CurPrice}</p></span>
        </div>
        <Button className='Btn' value={props.language.DodajUkorpu} accent='#0458AD' onClick={props.Status === 'DOSTUPNO' ? props.onClick : () => { showErrorMessage() }} />
      </div>
    </div>
  )
}
