import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";

import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";

import BioplarTextEditor from '../../richTextEditor';

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import moment from "moment";
import Dropdown from '../../components/customComponents/Dropdown';

export default function AdminPanel(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const curDispatch = useDispatch();
    const [spinner, setSpinner] = React.useState(false);
    const [access, setAccess] = React.useState(props.isLogged ? true : false);
    const usernameRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const msgRef = React.useRef(null);
    const [filters, setFilters] = React.useState([]);
    const [isCC, setIsCC] = React.useState(false);

    const [activePage, setActivePage] = React.useState(null);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.UserInfo.Flags.isAdmin === false && res.data.data.UserInfo.Flags.isCCagent === false) {
                    animateNavigate('/');
                }
                else {
                    setActivePage('korisnici');
                }
                if (res.data.data.UserInfo.Flags.isCCagent === true) {
                    setIsCC(true);
                    setActivePage('narudzbe');
                }
                else {
                    setIsCC(false);
                }
            }
            else {

            }
        }).catch(() => {

        });
    }, []);

    const loginHandler = () => {
        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/auth/checkLogin`,
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        if (res.data.data.UserInfo.Flags.isAdmin === true || res.data.data.UserInfo.Flags.isCCagent === true) {
                            setAccess(true);
                        }
                        else {
                            animateNavigate('/');
                        }
                    }
                    else {

                    }
                }).catch(() => {

                });
            }
            else {
                msgRef.current.innerText = 'Incorrect username or password';
                msgRef.current.style.color = 'red';
                setTimeout(() => {
                    msgRef.current.innerText = '';
                }, 3000);
            }
        }).catch(() => {
            msgRef.current.value = "Server is not responding";
            msgRef.current.style.color = 'red';
            setTimeout(() => {
                msgRef.current.innerText = '';
            }, 3000);
        }).finally(() => {
            setSpinner(false);
        });
    }

    const logout = () => {
        let data;
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                setAccess(false);
            }
            else {

            }
        }).catch(() => {

        });
    }

    const [status, setStatus] = React.useState('DOSTUPNO');
    const [illness, setIllness] = React.useState('nedefinisano');
    const nameRef = React.useRef(null);
    const descRef = React.useRef(null);
    const descSiRef = React.useRef(null);
    const descEnRef = React.useRef(null);
    const descLongRef = React.useRef(null);
    const descLongSiRef = React.useRef(null);
    const descLongEnRef = React.useRef(null);
    const listDescRef = React.useRef(null);
    const listDescSiRef = React.useRef(null);
    const listDescEnRef = React.useRef(null);
    const usageRef = React.useRef(null);
    const usageSiRef = React.useRef(null);
    const usageEnRef = React.useRef(null);
    const warningsRef = React.useRef(null);
    const warningsSiRef = React.useRef(null);
    const warningsEnRef = React.useRef(null);
    const oldPriceRef = React.useRef(null);
    const oldPriceRsRef = React.useRef(null);
    const oldPriceHrRef = React.useRef(null);
    const oldPriceSiRef = React.useRef(null);
    const curPriceRef = React.useRef(null);
    const curPriceHrRef = React.useRef(null);
    const curPriceRsRef = React.useRef(null);
    const curPriceSiRef = React.useRef(null);
    const discountQuantityRef = React.useRef(null);
    const discountValueRef = React.useRef(null);
    const errorMsgRef = React.useRef(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [selectedImageTwo, setSelectedImageTwo] = React.useState(null);
    const [selectedImageThree, setSelectedImageThree] = React.useState(null);
    const [ingredients, setIngradients] = React.useState([]);
    const [ingredientsSI, setIngradientsSI] = React.useState([]);

    const [ingredientsModal, setIngredientsModal] = React.useState(false);
    const openIngredientsModal = () => {
        setIngredientsModal(m => !m);
    }

    const [ingredientsModalSI, setIngredientsModalSI] = React.useState(false);
    const openIngredientsModalSI = () => {
        setIngredientsModalSI(m => !m);
    }

    const [rows, setRows] = React.useState([0, 0]);
    const addNewRow = () => {
        setRows(prev => {
            return [
                ...prev, 0
            ]
        });
    }

    const addIngredients = () => {
        const names = document.querySelectorAll('#ingn');
        const quantities = document.querySelectorAll('#ingq');

        for (let i = 0; i < names.length; i++) {
            if (names[i].value) {
                if (quantities[i].value) {
                    let counter = 0;
                    ingredients.forEach(item => {
                        if (item.name === names[i].value && item.quantity === quantities[i].value) {
                            counter++;
                        }
                    })
                    if (counter === 0) {
                        ingredients.push({ name: names[i].value, quantity: quantities[i].value });
                    }
                }
            }
        }
        setIngredientsModal(false)
    }

    const addIngredientsSI = () => {
        const names = document.querySelectorAll('#ingns');
        const quantities = document.querySelectorAll('#ingqs');

        for (let i = 0; i < names.length; i++) {
            if (names[i].value) {
                if (quantities[i].value) {
                    let counter = 0;
                    ingredientsSI.forEach(item => {
                        if (item.name === names[i].value && item.quantity === quantities[i].value) {
                            counter++;
                        }
                    })
                    if (counter === 0) {
                        ingredientsSI.push({ name: names[i].value, quantity: quantities[i].value });
                    }
                }
            }
        }

        setIngredientsModalSI(false);
    }

    const addProduct = () => {

        if ((descRef.current.value).length > 40 || (descSiRef.current.value).length > 40) {
            return errorMsgRef.current.innerText = 'Kratki opis proizvoda ne smije biti duži od 40 karaktera!'
        }

        if (
            !nameRef.current.value ||
            !descRef.current.value ||
            !oldPriceRef.current.value ||
            !oldPriceRsRef.current.value ||
            !oldPriceHrRef.current.value ||
            !oldPriceSiRef.current.value ||
            !curPriceRef.current.value ||
            !curPriceHrRef.current.value ||
            !curPriceRsRef.current.value ||
            !curPriceSiRef.current.value ||
            !selectedImage ||
            !listDescRef.current.value ||
            !listDescSiRef.current.value ||
            !listDescEnRef.current.value ||
            !descLongRef.current.value ||
            !descLongSiRef.current.value ||
            !descLongEnRef.current.value ||
            !warningsRef.current.value ||
            !warningsSiRef.current.value ||
            !warningsEnRef.current.value ||
            !usageRef.current.value ||
            !usageSiRef.current.value ||
            !usageEnRef.current.value
        ) {
            return errorMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
        }

        if (selectedImage) {
            let imgFile = new FormData();
            let imgFileTwo = new FormData();
            let imgFileThree = new FormData();
            if (selectedImage && selectedImageTwo && selectedImageThree) {
                imgFile.append('image', selectedImage);
                imgFile.append('ImageName', selectedImage.name);
                imgFile.append('tag', 'prod-img');
                imgFileTwo.append('image', selectedImageTwo);
                imgFileTwo.append('ImageName', selectedImageTwo.name);
                imgFileTwo.append('tag', 'prod-img');
                imgFileThree.append('image', selectedImageThree);
                imgFileThree.append('ImageName', selectedImageThree.name);
                imgFileThree.append('tag', 'prod-img');
            }
            else if (selectedImage && selectedImageTwo) {
                imgFile.append('image', selectedImage);
                imgFile.append('ImageName', selectedImage.name);
                imgFile.append('tag', 'prod-img');
                imgFileTwo.append('image', selectedImageTwo);
                imgFileTwo.append('ImageName', selectedImageTwo.name);
                imgFileTwo.append('tag', 'prod-img');
            }
            else {
                imgFile.append('image', selectedImage);
                imgFile.append('ImageName', selectedImage.name);
                imgFile.append('tag', 'prod-img');
            }
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: imgFile,
                ...backendModule.axiosConfig,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(
                resone => {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/images/uploadImage`,
                        data: imgFileTwo,
                        ...backendModule.axiosConfig,
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then(restwo => {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/images/uploadImage`,
                            data: imgFileThree,
                            ...backendModule.axiosConfig,
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }).then(resthree => {
                            axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/products/addProduct`,
                                data: {
                                    ProductName: nameRef.current.value,
                                    Description: descRef.current.value,
                                    DescriptionSI: descSiRef.current.value,
                                    DescriptionEN: descEnRef.current.value,
                                    ListDescription: listDescRef.current.value,
                                    ListDescriptionSI: listDescSiRef.current.value,
                                    ListDescriptionEN: listDescEnRef.current.value,
                                    Warnings: warningsRef.current.value,
                                    WarningsSI: warningsSiRef.current.value,
                                    WarningsEN: warningsEnRef.current.value,
                                    LongDescription: descLongRef.current.value,
                                    LongDescriptionSI: descLongSiRef.current.value,
                                    LongDescriptionEN: descLongEnRef.current.value,
                                    Illness: illness,
                                    OldPrice: oldPriceRef.current.value,
                                    OldPriceRS: oldPriceRsRef.current.value,
                                    OldPriceHR: oldPriceHrRef.current.value,
                                    OldPriceSI: oldPriceSiRef.current.value,
                                    CurrentPrice: curPriceRef.current.value,
                                    CurrentPriceRS: curPriceRsRef.current.value,
                                    CurrentPriceHR: curPriceHrRef.current.value,
                                    CurrentPriceSI: curPriceSiRef.current.value,
                                    Status: status,
                                    ImageURL: resone.data.data,
                                    ImageURLtwo: restwo.data.data,
                                    ImageURLthree: resthree.data.data,
                                    Discount: { Quantity: discountQuantityRef.current.value, Value: discountValueRef.current.value },
                                    Ingredients: ingredients,
                                    IngredientsSI: ingredientsSI,
                                    Usage: usageRef.current.value,
                                    UsageSI: usageSiRef.current.value,
                                    UsageEN: usageEnRef.current.value
                                },
                                ...backendModule.axiosConfig
                            }).then(res => {
                                if (res.data.status === "ok") {
                                    getAllProducts();
                                    addNewProductHandler();
                                    nameRef.current.value = '';
                                    descRef.current.value = '';
                                    descSiRef.current.value = '';
                                    listDescRef.current.value = '';
                                    listDescSiRef.current.value = '';
                                    warningsRef.current.value = '';
                                    warningsSiRef.current.value = '';
                                    descLongRef.current.value = '';
                                    descLongSiRef.current.value = '';
                                    setIllness('nedefinisano');
                                    oldPriceRef.current.value = '';
                                    oldPriceRsRef.current.value = '';
                                    oldPriceHrRef.current.value = '';
                                    oldPriceSiRef.current.value = '';
                                    curPriceRef.current.value = '';
                                    curPriceRsRef.current.value = '';
                                    curPriceHrRef.current.value = '';
                                    curPriceSiRef.current.value = '';
                                    discountQuantityRef.current.value = '';
                                    discountValueRef.current.value = '';
                                    setIngradients([]);
                                    setIngradientsSI([]);
                                    usageRef.current.value = '';
                                    usageSiRef.current.value = '';
                                }
                                else if (res.data.status === 'error') {
                                    if (res.data.data === 'ERROR_SLOG_ALREADY_EXISTING') {
                                        errorMsgRef.current.innerText = 'Već postoji proizvod sa istim imenom!';
                                    }
                                }
                            }).catch(() => {
                                errorMsgRef.current.innerText = 'Došlo je do greške...';
                            });
                        }).catch(() => { })
                    }).catch(() => { })
                }
            ).catch();
        }
    }

    const [products, setProducts] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);

    const getAllProducts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProducts`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setProducts(res.data);
                setLoaded(true);
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllProducts();
    }, [filters]);

    const deleteProduct = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/deleteProduct`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllProducts();
            };
        }).catch(() => {

        });
    }

    const [couponOption, setCouponOption] = React.useState('%');
    const couponNameRef = React.useRef(null);
    const couponCodeRef = React.useRef(null);
    const couponValueRef = React.useRef(null);
    const couponValueRsRef = React.useRef(null);
    const couponValueHrRef = React.useRef(null);
    const couponValueSiRef = React.useRef(null);
    const expiresRef = React.useRef(null);

    const addCoupons = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/coupons/addCoupon`,
            data: {
                CouponName: couponNameRef.current.value,
                CouponCode: couponCodeRef.current.value,
                CouponValue: couponValueRef.current.value,
                CouponValueRS: couponValueRsRef.current.value,
                CouponValueHR: couponValueHrRef.current.value,
                CouponValueSI: couponValueSiRef.current.value,
                Expires: expiresRef.current.value,
                CouponOption: couponOption
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllCoupons();
                addNewCouponHandler();
            };
        }).catch(() => {

        });
    }

    const [coupons, setCoupons] = React.useState([]);
    const getAllCoupons = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/coupons/getAllCoupons`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCoupons(res.data);
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllCoupons();
    }, [filters]);

    const deleteCoupon = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/coupons/deleteCoupon`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllCoupons();
        }).catch(() => {

        });
    }

    const [settings, setSettings] = React.useState(false);
    const [addNewProduct, setAddNewProduct] = React.useState(false);

    const addNewProductHandler = () => {
        setAddNewProduct(a => !a);
    }

    const productRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    const [curProduct, setCurProduct] = React.useState([]);
    const [editProduct, setEditProduct] = React.useState(false);
    const [newImage, setNewImage] = React.useState(null);
    const [newImageTwo, setNewImageTwo] = React.useState(null);
    const [newImageThree, setNewImageThree] = React.useState(null);
    const [newStatus, setNewStatus] = React.useState(null);
    const [newIllness, setNewIllness] = React.useState(null);
    const newProductNameRef = React.useRef(null);
    const newDescriptionRef = React.useRef(null);
    const newOldPriceRef = React.useRef(null);
    const newOldPriceRsRef = React.useRef(null);
    const newOldPriceHrRef = React.useRef(null);
    const newOldPriceSiRef = React.useRef(null);
    const newCurrentPriceRef = React.useRef(null);
    const newCurrentPriceRsRef = React.useRef(null);
    const newCurrentPriceHrRef = React.useRef(null);
    const newCurrentPriceSiRef = React.useRef(null);
    const newDiscountQuantityRef = React.useRef(null);
    const newDiscountValueRef = React.useRef(null);
    const newDescriptionSiRef = React.useRef(null);
    const newDescriptionEnRef = React.useRef(null);
    const newListDescRef = React.useRef(null);
    const newListDescSiRef = React.useRef(null);
    const newListDescEnRef = React.useRef(null);
    const newWarningsRef = React.useRef(null);
    const newWarningsSiRef = React.useRef(null);
    const newWarningsEnRef = React.useRef(null);
    const newUsageRef = React.useRef(null);
    const newUsageSiRef = React.useRef(null);
    const newUsageEnRef = React.useRef(null);
    const newLongDescRef = React.useRef(null);
    const newLongDescSiRef = React.useRef(null);
    const newLongDescEnRef = React.useRef(null);
    const [oldImageURL, setOldImageURL] = React.useState(null);
    const [oldImageURLtwo, setOldImageURLtwo] = React.useState(null);
    const [oldImageURLthree, setOldImageURLthree] = React.useState(null);
    const [oldIngredients, setOldIngredients] = React.useState([]);
    const [oldIngredientsSI, setOldIngredientsSI] = React.useState([]);
    const [newIngredients, setNewIngradients] = React.useState([]);
    const [newIngredientsSI, setNewIngradientsSI] = React.useState([]);

    const editProductHandler = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getProductByID`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCurProduct(res.data);
            setNewIllness(res.data.data.Illness);
            setNewStatus(res.data.data.Status);
            setOldImageURL(res.data.data.ImageURL);
            setOldImageURLtwo(res.data.data.ImageURLtwo);
            setOldImageURLthree(res.data.data.ImageURLthree);
            setOldIngredients(JSON.parse(res.data.data.Ingredients));
            setOldIngredientsSI(JSON.parse(res.data.data.IngredientsSI));
        }).catch(() => {

        });
        setEditProduct(e => !e);
        setTimeout(() => {
            scrollToRef(productRef);
        }, 100);
    }

    const [ingredientsEditModal, setIngredientsEditModal] = React.useState(false);
    const openIngredientsEditModal = () => {
        setIngredientsEditModal(m => !m);
    }

    const [ingredientsEditModalSI, setIngredientsEditModalSI] = React.useState(false);
    const openIngredientsEditModalSI = () => {
        setIngredientsEditModalSI(m => !m);
    }

    const saveIngredients = () => {
        const names = document.querySelectorAll('#ingne');
        const quantities = document.querySelectorAll('#ingqe');
        let tmp = oldIngredients;

        for (let i = 0; i < names.length; i++) {
            tmp[i].name = names[i].value;
            tmp[i].quantity = quantities[i].value;
        }

        setOldIngredients(tmp);
        setIngredientsEditModal(false)
    }

    const saveIngredientsSI = () => {
        const names = document.querySelectorAll('#ingnse');
        const quantities = document.querySelectorAll('#ingqse');
        let tmp = oldIngredientsSI;

        for (let i = 0; i < names.length; i++) {
            tmp[i].name = names[i].value;
            tmp[i].quantity = quantities[i].value;
        }

        setOldIngredientsSI(tmp);
        setIngredientsEditModalSI(false)
    }

    const saveProduct = (id) => {
        if (newImage || newImageTwo || newImageThree) {
            let imgFile = new FormData();
            let imgFileTwo = new FormData();
            let imgFileThree = new FormData();
            if (newImage) {
                imgFile.append('image', newImage);
                imgFile.append('ImageName', newImage.name);
                imgFile.append('tag', 'prod-img');
            }
            if (newImageTwo) {
                imgFileTwo.append('image', newImageTwo);
                imgFileTwo.append('ImageName', newImageTwo.name);
                imgFileTwo.append('tag', 'prod-img');
            }
            if (newImageThree) {
                imgFileThree.append('image', newImageThree);
                imgFileThree.append('ImageName', newImageThree.name);
                imgFileThree.append('tag', 'prod-img');
            }
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: imgFile,
                ...backendModule.axiosConfig,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(
                res => {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/images/uploadImage`,
                        data: imgFileTwo,
                        ...backendModule.axiosConfig,
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then(restwo => {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/images/uploadImage`,
                            data: imgFileThree,
                            ...backendModule.axiosConfig,
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }).then(resthree => {
                            axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/products/editProduct`,
                                data: {
                                    ID: id,
                                    ProductName: newProductNameRef.current.value,
                                    Description: newDescriptionRef.current.value,
                                    DescriptionSI: newDescriptionSiRef.current.value,
                                    DescriptionEN: newDescriptionEnRef.current.value,
                                    OldPrice: newOldPriceRef.current.value,
                                    OldPriceRS: newOldPriceRsRef.current.value,
                                    OldPriceHR: newOldPriceHrRef.current.value,
                                    OldPriceSI: newOldPriceSiRef.current.value,
                                    CurrentPrice: newCurrentPriceRef.current.value,
                                    CurrentPriceRS: newCurrentPriceRsRef.current.value,
                                    CurrentPriceHR: newCurrentPriceHrRef.current.value,
                                    CurrentPriceSI: newCurrentPriceSiRef.current.value,
                                    Status: newStatus,
                                    ImageURL: newImage ? res.data.data : oldImageURL,
                                    ImageURLtwo: newImageTwo ? restwo.data.data : oldImageURLtwo,
                                    ImageURLthree: newImageThree ? resthree.data.data : oldImageURLthree,
                                    Illness: newIllness,
                                    Discount: { Quantity: newDiscountQuantityRef.current.value, Value: newDiscountValueRef.current.value },
                                    ListDescription: newListDescRef.current.value,
                                    ListDescriptionSI: newListDescSiRef.current.value,
                                    ListDescriptionEN: newListDescEnRef.current.value,
                                    Warnings: newWarningsRef.current.value,
                                    WarningsSI: newWarningsSiRef.current.value,
                                    WarningsEN: newWarningsEnRef.current.value,
                                    Usage: newUsageRef.current.value,
                                    UsageSI: newUsageSiRef.current.value,
                                    UsageEN: newUsageEnRef.current.value,
                                    Ingredients: oldIngredients,
                                    IngredientsSI: oldIngredientsSI,
                                    LongDescription: newLongDescRef.current.value,
                                    LongDescriptionSI: newLongDescSiRef.current.value,
                                    LongDescriptionEN: newLongDescEnRef.current.value,
                                },
                                ...backendModule.axiosConfig
                            }).then(res => {
                                if (res.data.status = 'ok') {
                                    getAllProducts();
                                    editProductHandler();
                                }
                            }).catch(() => {

                            });
                        }).catch(() => { })
                    }).catch(() => { })
                }
            ).catch();
        }
        else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/products/editProduct`,
                data: {
                    ID: id,
                    ProductName: newProductNameRef.current.value,
                    Description: newDescriptionRef.current.value,
                    DescriptionSI: newDescriptionSiRef.current.value,
                    DescriptionEN: newDescriptionEnRef.current.value,
                    OldPrice: newOldPriceRef.current.value,
                    OldPriceRS: newOldPriceRsRef.current.value,
                    OldPriceHR: newOldPriceHrRef.current.value,
                    OldPriceSI: newOldPriceSiRef.current.value,
                    CurrentPrice: newCurrentPriceRef.current.value,
                    CurrentPriceRS: newCurrentPriceRsRef.current.value,
                    CurrentPriceHR: newCurrentPriceHrRef.current.value,
                    CurrentPriceSI: newCurrentPriceSiRef.current.value,
                    Status: newStatus,
                    ImageURL: oldImageURL,
                    ImageURLtwo: oldImageURLtwo,
                    ImageURLthree: oldImageURLthree,
                    Illness: newIllness,
                    Discount: { Quantity: newDiscountQuantityRef.current.value, Value: newDiscountValueRef.current.value },
                    ListDescription: newListDescRef.current.value,
                    ListDescriptionSI: newListDescSiRef.current.value,
                    ListDescriptionEN: newListDescEnRef.current.value,
                    Warnings: newWarningsRef.current.value,
                    WarningsSI: newWarningsSiRef.current.value,
                    WarningsEN: newWarningsEnRef.current.value,
                    Usage: newUsageRef.current.value,
                    UsageSI: newUsageSiRef.current.value,
                    UsageEN: newUsageEnRef.current.value,
                    Ingredients: oldIngredients,
                    IngredientsSI: oldIngredientsSI,
                    LongDescription: newLongDescRef.current.value,
                    LongDescriptionSI: newLongDescSiRef.current.value,
                    LongDescriptionEN: newLongDescEnRef.current.value,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status = 'ok') {
                    getAllProducts();
                    editProductHandler();
                }
            }).catch(() => {

            });
        }
    }

    const addProductsToTable = () => {
        if (products.data) {
            return products?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ProductName}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Description}</p></div> },
                    {
                        keyID: String(item.ID), type: "custom", data: <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ textAlign: 'center' }}>{item.OldPrice} BAM</p>
                            <p style={{ textAlign: 'center' }}>{item.OldPriceRS} RSD</p>
                            <p style={{ textAlign: 'center' }}>{item.OldPriceHR} HRK</p>
                            <p style={{ textAlign: 'center' }}>{item.OldPriceSI} EUR</p>
                        </div>
                    },
                    {
                        keyID: String(item.ID), type: "custom", data: <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ textAlign: 'center' }}>{item.CurrentPrice} BAM</p>
                            <p style={{ textAlign: 'center' }}>{item.CurrentPriceRS} RSD</p>
                            <p style={{ textAlign: 'center' }}>{item.CurrentPriceHR} HRK</p>
                            <p style={{ textAlign: 'center' }}>{item.CurrentPriceSI} EUR</p>
                        </div>
                    },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '50px', width: 'fit-content', padding: '5px', color: 'white', textAlign: 'center', background: item.Status === 'DOSTUPNO' ? 'green' : 'red' }}>{item.Status}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            { keyID: String(item.ID), type: "button", text: "Otvori sliku 1", onClick: e => { window.open(`/#/image/${String(item.ImageURL).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank') } },
                            { keyID: String(item.ID), type: "button", text: "Otvori sliku 2", onClick: e => { window.open(`/#/image/${String(item.ImageURLtwo).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank') } },
                            { keyID: String(item.ID), type: "button", text: "Otvori sliku 3", onClick: e => { window.open(`/#/image/${String(item.ImageURLthree).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank') } },
                            { keyID: String(item.ID), type: "button", text: "Uredi", onClick: e => { editProductHandler(item.ID) } },
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteProduct(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži popuste na količinu", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ display: JSON.parse(item.Discount).Value ? 'block' : 'none' }}>Postavljeno je {JSON.parse(item.Discount).Value} % popusta na količinu od {JSON.parse(item.Discount).Quantity} pakovanja</p>
                                            <p style={{ display: JSON.parse(item.Discount).Value ? 'none' : 'block' }}>Nije postavljen nikakav popust</p>
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    },
                ];
            });
        }
    }

    const [addNewCoupon, setAddNewCoupon] = React.useState(false);

    const addNewCouponHandler = () => {
        setAddNewCoupon(c => !c);
    }

    const [editCoupon, setEditCoupon] = React.useState(false);
    const [curCoupon, setCurCoupon] = React.useState([]);
    const editCouponHandler = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/coupons/getCouponByID`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCurCoupon(res.data);
            setNewCouponOption(res.data.data.CouponOption);
        }).catch(() => {

        });
        setEditCoupon(c => !c);
    }

    const newCouponCodeRef = React.useRef(null);
    const newCouponNameRef = React.useRef(null);
    const newCouponValueRef = React.useRef(null);
    const newCouponValueRsRef = React.useRef(null);
    const newCouponValueHrRef = React.useRef(null);
    const newCouponValueSiRef = React.useRef(null);
    const newExpiresRef = React.useRef(null);
    const [newCouponOption, setNewCouponOption] = React.useState(null);

    const saveCoupon = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/coupons/editCoupon`,
            data: {
                ID: id,
                CouponName: newCouponNameRef.current.value,
                CouponCode: newCouponCodeRef.current.value,
                CouponValue: newCouponValueRef.current.value,
                CouponValueRS: newCouponValueRsRef.current.value,
                CouponValueHR: newCouponValueHrRef.current.value,
                CouponValueSI: newCouponValueSiRef.current.value,
                CouponOption: newCouponOption,
                Expires: newExpiresRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllCoupons();
            editCouponHandler();
        }).catch(() => {

        });
    }

    const addCouponsToTable = () => {
        if (coupons.data) {
            return coupons?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.CouponName}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.CouponCode}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.CouponOption}</p></div> },
                    {
                        keyID: String(item.ID), type: "custom", data: <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                item.CouponOption === 'Currency' ?
                                    <>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValue} BAM</p>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValueRS} RSD</p>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValueHR} HRK</p>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValueSI} EUR</p>
                                    </>
                                    : <>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValue} BA</p>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValueRS} RS</p>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValueHR} HR</p>
                                        <p style={{ textAlign: 'center' }}>{item.CouponValueSI} SI</p>
                                    </>
                            }
                        </div>
                    },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.Expires).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            { keyID: String(item.ID), type: "button", text: "Uredi", onClick: e => { editCouponHandler(item.ID) } },
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteCoupon(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [users, setUsers] = React.useState([]);
    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllUsers();
    }, [filters]);

    const deleteUser = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/deleteUser`,
            data: {
                id: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllUsers();
        }).catch(() => {

        });
    }

    const addUsersToTable = () => {
        if (users.data) {
            return users?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '50px', width: 'fit-content', padding: '5px', color: 'white', textAlign: 'center', background: item.isAdmin === true ? 'green' : item.isCCagent === true ? 'rgb(30 157 159)' : 'rgb(4, 88, 173)' }}>{item.isAdmin === true ? 'ADMIN' : item.isCCagent === true ? 'AGENT' : 'CLIENT'}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>{item.isAdmin === true ? 'Ne možete obrisati admin korisnika' : 'Da li ste sigurni?'}</p>
                                        <br></br>
                                        <div style={{ display: item.isAdmin === true ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteUser(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [changePasswordModal, setChangePasswordModal] = React.useState(false);
    const changePasswordHandler = () => {
        setChangePasswordModal(p => !p);
    }

    const oldPasswordRef = React.useRef(null);
    const newPasswordRef = React.useRef(null);
    const passMsgRef = React.useRef(null);

    const changePassword = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                oldPassword: oldPasswordRef.current.value,
                newPassword: newPasswordRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                passMsgRef.current.style.color = 'green';
                passMsgRef.current.innerText = 'Uspješno promijenjena šifra!';
                setTimeout(() => {
                    changePasswordHandler();
                }, 1500);
            }
            else {
                passMsgRef.current.style.color = 'red';
                passMsgRef.current.innerText = res.data.data;
            }
        }).catch(() => {
            passMsgRef.current.style.color = 'red';
            passMsgRef.current.innerText = 'Server error';
        });
    }

    const [allOrders, setAllOrders] = React.useState([]);

    const getAllOrders = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getAllOrders`,
            data: {
                pagination: 0,
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllOrders(res.data);
            setLoaded(true);
            if (res.data.data.length === 20) {
                setTimeout(() => setCanPaginate(true), 1000);
            }
        }).catch((e) => {
        });
    }

    React.useEffect(() => {
        if (activePage === 'narudzbe') {
            getAllOrders();
        }
    }, [filters, activePage]);

    const deleteOrder = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/deleteOrder`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllOrders();
        }).catch(() => {

        });
    }

    const addOrdersToTable = () => {
        if (allOrders.data) {
            return allOrders?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Country}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', display: item.Discount !== null ? 'block' : 'none' }}>{item.Discount} {item.DiscountOption === 'Currency' ? item.Country === 'BA' ? 'BAM' : item.Country === 'RS' ? 'RSD' : item.Country === 'HR' ? 'HRK' : 'EUR' : '%'}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price} {item.Country === 'BA' ? 'BAM' : item.Country === 'RS' ? 'RSD' : item.Country === 'HR' ? 'HRK' : 'EUR'}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PaymentMethod}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '50px', width: 'fit-content', padding: '5px', color: 'white', textAlign: 'center', background: item.isPaid === true ? 'green' : 'red' }}>{item.isPaid === true ? 'DA' : 'NE'}</p></div> },
                    {

                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteOrder(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Podaci dostave", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'baseline', alignItems: 'baseline' }}>
                                        <h3>PODACI DOSTAVE</h3>
                                        <p><strong>Ime i prezime:</strong> {JSON.parse(item.ShippingData).ShippingName}</p>
                                        <p><strong>Grad:</strong> {JSON.parse(item.ShippingData).City}</p>
                                        <p><strong>Ulica:</strong> {JSON.parse(item.ShippingData).Street}</p>
                                        <p><strong>Broj kuće:</strong> {JSON.parse(item.ShippingData).HouseNumber}</p>
                                        <p><strong>ZIP:</strong> {JSON.parse(item.ShippingData).ZIP}</p>
                                        <p><strong>Broj telefona:</strong> {JSON.parse(item.ShippingData).PhoneNumber}</p>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Podaci plaćanja", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'baseline', alignItems: 'baseline' }}>
                                        <h3>PODACI PLAĆANJA</h3>
                                        <p><strong>Ime i prezime:</strong> {JSON.parse(item.BillingData).Name}</p>
                                        <p><strong>Grad:</strong> {JSON.parse(item.BillingData).City}</p>
                                        <p><strong>Ulica:</strong> {JSON.parse(item.BillingData).Street}</p>
                                        <p><strong>Broj kuće:</strong> {JSON.parse(item.BillingData).HouseNumber}</p>
                                        <p><strong>ZIP:</strong> {JSON.parse(item.BillingData).ZIP}</p>
                                        <p><strong>Broj telefona:</strong> {JSON.parse(item.BillingData).PhoneNumber}</p>
                                        <div style={{ display: item.PaymentMethod === 'Virman' ? 'block' : 'none' }}>
                                            <p><strong>Naziv kompanije:</strong> {JSON.parse(item.BillingData).CompanyName}</p>
                                            <p><strong>Adresa kompanije:</strong> {JSON.parse(item.BillingData).CompanyAddress}</p>
                                            <p><strong>ID broj:</strong> {JSON.parse(item.BillingData).IDnumber}</p>
                                            <p><strong>PDV broj:</strong> {JSON.parse(item.BillingData).PDVnumber}</p>
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj artikle", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'grid' }} id='articles-table'>
                                        <FilteredCustomTable
                                            id='table'
                                            accent='#0458AD'
                                            theme='light'
                                            headers={['Proizvod', 'Količina']}
                                            data={
                                                JSON.parse(item.Products).map((item) => {
                                                    return [
                                                        { keyID: 'noData', type: 'custom', data: <p style={{ textAlign: 'center' }}>{item.ProductName}</p> },
                                                        { keyID: 'noData', type: 'custom', data: <p style={{ textAlign: 'center' }}>{item.Quantity}</p> }
                                                    ]
                                                })
                                            }
                                        />
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj korisnika", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p>{item.User}</p>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj napomenu", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p>{item.Remark ? item.Remark : 'Nema napomene'}</p>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [canPaginate, setCanPaginate] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const paginationTriggerRef = React.useRef(null);

    const paginationData = (isInViewPort) => {
        if (isInViewPort) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/orders/getAllOrders`,
                data: {
                    pagination: allOrders.data.length,

                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    if (res.data.data.length > 0) {
                        setLoadingData(true);
                        setTimeout(() => setLoadingData(false), 100);
                        res.data.data?.map((item) => {
                            allOrders.data.push(item);
                        });
                    }
                };
            }).catch(() => {
                setAllOrders({ status: 'error', data: 'Data fetch failed' });
                return setServerError(true);
            }).finally(() => {
                setSpinner(false);
            });
        }
    }

    const useIsInViewport = () => {
        const [isIntersecting, setIsIntersecting] = React.useState(false);

        const observer = React.useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                    //setSpinner(true)
                ),
            [],
        );

        React.useEffect(() => {
            if (activePage === 'narudzbe') {
                observer.observe(paginationTriggerRef.current);

                return () => {
                    observer.disconnect();
                };
            }
        }, [paginationTriggerRef, observer, activePage]);
        if (isIntersecting) {
            paginationData(isIntersecting);
        }
    }

    useIsInViewport();

    const [allMessages, setAllMessages] = React.useState([]);
    const getAllMessages = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/messages/getAllMessages`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setAllMessages(res.data);
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllMessages();
    }, [filters]);

    const deleteMessage = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/messages/deleteMessage`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllMessages();
            }
        }).catch(() => {

        });
    }

    const addMessagesToTable = () => {
        if (allMessages.data) {
            return allMessages?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.User}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PhoneNumber}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Text ? String(item.Text).slice(0, 15) + '...' : ''}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteMessage(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj cijelu poruku", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p>{item.Text ? item.Text : <p style={{ color: 'red' }}>Nema poruke</p>}</p>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [generateModal, setGenerateModal] = React.useState(false);
    const generateModalHandler = () => {
        setGenerateModal(m => !m);
    }

    const [dropdown, setDropdown] = React.useState(false);
    const dropdownHandler = () => {
        setDropdown(d => !d);
    }

    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [selectedProductPrice, setSelectedProductPrice] = React.useState(null);
    const [selectedProductPriceRS, setSelectedProductPriceRS] = React.useState(null);
    const [selectedProductPriceHR, setSelectedProductPriceHR] = React.useState(null);
    const [selectedProductPriceSI, setSelectedProductPriceSI] = React.useState(null);
    const [selectedProductID, setSelectedProductID] = React.useState(null);
    const selectProduct = (product, price, priceHR, priceRS, priceSI, ID) => {
        setSelectedProduct(product);
        setSelectedProductPrice(price);
        setSelectedProductPriceSI(priceSI);
        setSelectedProductPriceHR(priceHR);
        setSelectedProductPriceRS(priceRS);
        setSelectedProductID(ID);
        setDropdown(true);
    }

    const [countryDropdown, setCountryDropdown] = React.useState(false);
    const countryDropdownHandler = () => {
        setCountryDropdown(c => !c);
    }

    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const selectCountry = (country) => {
        setSelectedCountry(country);
        setCountryDropdown(true);
    }

    const genQuantityRef = React.useRef(null);
    const genMsgRef = React.useRef(null);
    const [preparedProducts, setPreparedProducts] = React.useState([]);
    const prepareProductForGeneration = (product, quantity, price, priceRS, priceHR, priceSI, ID) => {
        if (genQuantityRef.current.value && selectedProduct !== null) {
            price = quantity * price;
            priceRS = quantity * priceRS;
            priceHR = quantity * priceHR;
            priceSI = quantity * priceSI;
            setPreparedProducts(d => [...d, { ProductID: ID, Product: product, Quantity: quantity, Price: price, PriceRS: priceRS, PriceHR: priceHR, PriceSI: priceSI }]);
        }
        else {
            genMsgRef.current.innerText = 'Morate odabrati proizvod i količinu!';
            setTimeout(() => {
                genMsgRef.current.innerText = '';
            }, 3000);
        }
    }

    const [generatedURL, setGeneratedURL] = React.useState(null);
    const [generatedModal, setGeneratedModal] = React.useState(false);
    const [shoppingCartURL, setShoppingCartURL] = React.useState(
        props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR' ||
            props.location === 'SI' ? 'kosarica' : 'shoppingcart'
    );

    const generateURL = () => {
        if (selectedCountry !== null && preparedProducts.length > 0) {

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/generatedOrders/generateOrder`,
                data: {
                    Products: preparedProducts,
                    Country: selectedCountry
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setGeneratedURL(`${window.location.protocol}//${window.location.host}/#/${shoppingCartURL}?orderID=${res.data.data}`);
                    setGeneratedModal(true);
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/generatedOrders/editGeneratedOrder`,
                        data: {
                            ID: res.data.data,
                            URL: `${window.location.protocol}//${window.location.host}/#/${shoppingCartURL}?orderID=${res.data.data}`
                        },
                        ...backendModule.axiosConfig
                    }).then(response => {
                        if (response.data.status === 'ok') {
                            getAllGeneratedOrders();
                            setPreparedProducts([]);
                            setSelectedCountry(null);
                            setSelectedProduct(null);
                            genQuantityRef.current.value = '';
                        }
                    }).catch(() => {

                    });
                }
            }).catch(() => {

            });
        }
    }

    const deletePreparedProducts = () => {
        setPreparedProducts([]);
    }

    const [generatedOrders, setGeneratedOrders] = React.useState([]);
    const getAllGeneratedOrders = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/generatedOrders/getAllGeneratedOrders`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setGeneratedOrders(res.data);
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        if (activePage === 'generisanje') {
            getAllGeneratedOrders();
        }
    }, [activePage, filters]);

    const deleteGeneratedOrder = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/generatedOrders/deleteGeneratedOrder`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllGeneratedOrders();
            }
        }).catch(() => {

        });
    }

    const addGeneratedOrdersToTable = () => {
        if (generatedOrders.data) {
            return generatedOrders?.data?.map((item) => {
                let total = 0;
                JSON.parse(item.Products).map((p) => {
                    switch (item.Country) {
                        case 'Bosna i Hercegovina':
                            total = total + p.Price;
                            break;
                        case 'Srbija':
                            total = total + p.PriceRS;
                            break;
                        case 'Hrvatska':
                            total = total + p.PriceHR;
                            break;
                        case 'Slovenija':
                            total = total + p.PriceSI;
                            break;
                        default: return p.PriceSI;
                    }
                })
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Country}</p></div> },
                    {
                        keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{total.toFixed(2)} {
                            (() => {
                                switch (item.Country) {
                                    case 'Bosna i Hercegovina':
                                        return 'BAM';
                                        break;
                                    case 'Srbija':
                                        return 'RSD';
                                        break;
                                    case 'Hrvatska':
                                        return 'HRK';
                                        break;
                                    case 'Slovenija':
                                        return 'EUR';
                                        break;
                                    default: return 'EUR';
                                }
                            })()
                        }</p></div>
                    },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '50px', width: 'fit-content', padding: '5px', color: 'white', textAlign: 'center', background: item.isPaid === true ? 'green' : 'red' }}>{item.isPaid === true ? 'DA' : 'NE'}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteGeneratedOrder(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj artikle", triggerDropdown: true, triggerData: e => {
                                    return (<div>
                                        <FilteredCustomTable
                                            id='table'
                                            accent='#0458AD'
                                            theme='light'
                                            headers={['Proizvod', 'Količina']}
                                            data={
                                                JSON.parse(item.Products).map((item) => {
                                                    return [
                                                        { keyID: 'noData', type: 'custom', data: <p style={{ textAlign: 'center' }}>{item.Product}</p> },
                                                        { keyID: 'noData', type: 'custom', data: <p style={{ textAlign: 'center' }}>{item.Quantity}</p> }
                                                    ]
                                                })
                                            }
                                        />
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj link", triggerDropdown: true, triggerData: e => {
                                    return (<div>
                                        <p>{item.URL}</p>
                                    </div>);
                                }
                            }
                        ]
                    }
                ];
            });
        }
    }

    const [navbar, setNavbar] = React.useState(false);
    const navbarHandler = () => {
        setNavbar(n => !n);
    }

    const [addOpinionModal, setAddOpinionModal] = React.useState(false);
    const addOpinionModalHandler = () => {
        setAddOpinionModal(m => !m);
    }

    const [opinions, setOpinions] = React.useState([]);
    const getAllOpinions = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/opinions/getAllOpinions`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            setOpinions(res.data);
        }).catch(() => { });
    }

    React.useEffect(() => {
        if (activePage === 'misljenja') {
            getAllOpinions();
        }
    }, [activePage, filters]);

    const [selectedOpinionImage, setSelectedOpinionImage] = React.useState(null);
    const headingRef = React.useRef(null);
    const [opinionProduct, setOpinionProduct] = React.useState(null);
    const headingSiRef = React.useRef(null);
    const headingEnRef = React.useRef(null);
    const textRef = React.useRef(null);
    const textSiRef = React.useRef(null);
    const textEnRef = React.useRef(null);
    const opinionNameRef = React.useRef(null);
    const opinionNameSiRef = React.useRef(null);
    const opinionNameEnRef = React.useRef(null);
    const yearsRef = React.useRef(null);
    const opinionsMsgRef = React.useRef(null);

    const addOpinion = () => {

        if (
            !headingRef.current.value ||
            !headingSiRef.current.value ||
            !headingEnRef.current.value ||
            !textSiRef.current.value ||
            !textRef.current.value ||
            !textEnRef.current.value ||
            !opinionNameRef.current.value ||
            !opinionNameSiRef.current.value ||
            !opinionNameEnRef.current.value ||
            !yearsRef.current.value ||
            !opinionProduct
        ) {
            return opinionsMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
        }

        let imgFile = new FormData();
        imgFile.append('image', selectedOpinionImage);
        imgFile.append('ImageName', selectedOpinionImage.name);
        imgFile.append('tag', 'per-img');
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/uploadImage`,
            data: imgFile,
            ...backendModule.axiosConfig,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/opinions/addOpinion`,
                data: {
                    Heading: headingRef.current.value,
                    HeadingSI: headingSiRef.current.value,
                    HeadingEN: headingEnRef.current.value,
                    Text: textRef.current.value,
                    TextSI: textSiRef.current.value,
                    TextEN: textEnRef.current.value,
                    Name: opinionNameRef.current.value,
                    NameSI: opinionNameSiRef.current.value,
                    NameEN: opinionNameEnRef.current.value,
                    Years: yearsRef.current.value,
                    ImageURL: res.data.data,
                    ProductID: opinionProduct
                },
                ...backendModule.axiosConfig,
            }).then(opres => {
                if (opres.data.status = 'ok') {
                    addOpinionModalHandler();
                    getAllOpinions();
                }
                else {
                    opinionsMsgRef.current.innerText = 'Provjerite da li su sva polja popunjena!';
                }
            })
        }).catch(() => { });
    }

    const deleteOpinion = (id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/opinions/deleteOpinion`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            getAllOpinions();
        }).catch(() => { });
    }

    const addOpinionsToTable = () => {
        if (opinions.data) {
            return opinions?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ProductID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Heading}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Years}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteOpinion(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pogledaj tekst", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p>{item.Text ? item.Text : <p style={{ color: 'red' }}>Nema teksta</p>}</p>
                                    </div>);
                                }
                            },
                            { keyID: String(item.ID), type: "button", text: "Otvori sliku", onClick: e => { window.open(`/#/image/${String(item.ImageURL).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank') } },
                        ]
                    }
                ];
            });
        }
    }

    const [emails, setEmails] = React.useState([]);
    const getAllEmails = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/emails/getAllEmails`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            setEmails(res.data);
        }).catch(() => { });
    }

    React.useEffect(() => {
        if (activePage === 'mails') {
            getAllEmails();
        }
    }, [activePage, filters]);

    const deleteEmail = (id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/emails/deleteEmail`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            getAllEmails();
        }).catch(() => { });
    }

    const addEmailsToTable = () => {
        if (emails.data) {
            return emails?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.User}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='#0458AD' value='DA' onClick={() => deleteEmail(item.ID)} />
                                            <Button accent='#0458AD' value='NE' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Pošalji mail", onClick: () => {
                                    setNewEmailModal(true);
                                    emailRef.current.value = item.Email
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [newEmailModal, setNewEmailModal] = React.useState(false);
    const newEmailModalHandler = () => {
        emailRef.current.value = '';
        setNewEmailModal(m => !m);
    }

    const [htmlVal, setHtmlVal] = React.useState("");
    const onChange = (value) => {
        setHtmlVal(value);
    };

    const emailRef = React.useRef(null);
    const subjectRef = React.useRef(null);
    const mailMsgRef = React.useRef(null);
    const sendToAllRef = React.useRef(null);
    const [mailSpinner, setMailSpinner] = React.useState(false);
    const [toAll, setToAll] = React.useState(false);
    const sendEmail = () => {

        if (toAll) {
            if (!subjectRef.current.value) {
                return mailMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
            }
        }
        else {
            if (!emailRef.current.value || !subjectRef.current.value || !emailRef.current.value.includes('@')) {
                return mailMsgRef.current.innerText = 'Sva polja moraju biti popunjena ispravnim informacijama!';
            }
        }

        setMailSpinner(true);

        if (!sendToAllRef.current.checked) {
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/emails/sendEmail`,
                data: {
                    Email: emailRef.current.value,
                    Subject: subjectRef.current.value,
                    Html: htmlVal
                },
                ...backendModule.axiosConfig,
            }).then(res => {
                if (res.data.status === 'error') {
                    mailMsgRef.current.innerText = 'Došlo je do greške';
                }
            }).catch(() => {
                mailMsgRef.current.innerText = 'Došlo je do greške';
            }).finally(() => {
                emailRef.current.value = '';
                subjectRef.current.value = '';
                setMailSpinner(false);
                newEmailModalHandler();
            });
        }
        else {
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/emails/sendEmailToAll`,
                data: {
                    Subject: subjectRef.current.value,
                    Html: htmlVal
                },
                ...backendModule.axiosConfig,
            }).then(res => {
                if (res.data.status === 'error') {
                    mailMsgRef.current.innerText = 'Došlo je do greške';
                }
            }).catch(() => {
                mailMsgRef.current.innerText = 'Došlo je do greške';
            }).finally(() => {
                setToAll(false);
                setTimeout(() => {
                    emailRef.current.value = '';
                    subjectRef.current.value = '';
                    sendToAllRef.current.checked = false;
                    setMailSpinner(false);
                    newEmailModalHandler();
                }, 3000);
            });
        }
    }

    const [addUserModal, setAddUserModal] = React.useState(false);
    const addNewUserModalHandler = () => {
        setAddUserModal(m => !m);
    }

    const usernameCreateRef = React.useRef(null);
    const nameCreateRef = React.useRef(null);
    const passwordCreateRef = React.useRef(null);
    const emailCreateRef = React.useRef(null);
    const msgCreateRef = React.useRef(null);
    const ccAgentRef = React.useRef(null);
    const adminRef = React.useRef(null);

    const addUser = () => {
        if (
            !nameCreateRef.current.value ||
            !passwordCreateRef.current.value ||
            !usernameCreateRef.current.value ||
            !emailCreateRef.current.value
        ) {
            msgCreateRef.current.style.color = 'red';
            msgCreateRef.current.style.textAlign = 'center';
            return msgCreateRef.current.innerText = 'Sva polja moraju biti popunjena!';
        }

        if (passwordCreateRef.current.value.length < 8) {
            msgCreateRef.current.style.color = 'red';
            msgCreateRef.current.style.textAlign = 'center';
            return msgCreateRef.current.innerText = 'Šifra imati minimalno 8 karaktera';
        }

        if (!ccAgentRef.current.checked && !adminRef.current.checked) {
            msgCreateRef.current.style.color = 'red';
            msgCreateRef.current.style.textAlign = 'center';
            return msgCreateRef.current.innerText = 'Role nije izabran!';
        }

        axios({
            method: "POST",
            url: ccAgentRef.current.checked ? `${backendModule.backendURL}/users/createNewUserCCagent` : `${backendModule.backendURL}/users/createNewAdminUser`,
            data: {
                name: nameCreateRef.current.value,
                username: usernameCreateRef.current.value,
                password: passwordCreateRef.current.value,
                email: emailCreateRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                msgCreateRef.current.style.color = 'green';
                msgCreateRef.current.style.textAlign = 'center';
                msgCreateRef.current.innerText = 'Uspješno dodan korisnik';
                setTimeout(() => {
                    getAllUsers();
                    addNewUserModalHandler();
                }, 1500);
            }
            else {
                msgCreateRef.current.style.color = 'red';
                msgCreateRef.current.style.textAlign = 'center';
                msgCreateRef.current.innerText = res.data.data;
            }
        }).catch(() => {
            msgCreateRef.current.style.color = 'red';
            msgCreateRef.current.style.textAlign = 'center';
            msgCreateRef.current.innerText = 'Server error';
        });
    }

    return <div className='route__adminPanel'>
        <div style={{ display: props.isLogged && access ? 'none' : 'flex' }} className='route__adminPanel__login'>
            <h1>Please login</h1>
            <input ref={usernameRef} type='text' placeholder='Username' />
            <input ref={passwordRef} type='password' placeholder='Password' />
            <Button className='logoutBtn' value='Login' accent='#001B57' onClick={() => loginHandler()} />
            <br></br>
            <p id='msg' ref={msgRef}></p>
        </div>

        <div style={{ display: props.isLogged && access ? 'grid' : 'none' }} className='route__adminPanel__body'>
            <div style={{ display: navbar ? 'flex' : '' }} className='route__adminPanel__body__navbar'>
                <img id='close' src='images/closeWhite.svg' alt='' onClick={() => navbarHandler()} />
                <h2>Admin Panel</h2>
                <p onClick={() => { setActivePage('korisnici') }} style={{ display: isCC ? 'none' : '', color: activePage === 'korisnici' ? '#00a3ff' : '' }}>Lista korisnika</p>
                <p onClick={() => { setActivePage('proizvodi') }} style={{ display: isCC ? 'none' : '', color: activePage === 'proizvodi' ? '#00a3ff' : '' }}>Proizvodi</p>
                <p onClick={() => { setActivePage('kuponi') }} style={{ display: isCC ? 'none' : '', color: activePage === 'kuponi' ? '#00a3ff' : '' }}>Kuponi</p>
                <p onClick={() => { setActivePage('narudzbe') }} style={{ color: activePage === 'narudzbe' ? '#00a3ff' : '' }}>Narudžbe</p>
                <p onClick={() => { setActivePage('generisanje') }} style={{ color: activePage === 'generisanje' ? '#00a3ff' : '' }}>Generiši narudžbu</p>
                <p onClick={() => { setActivePage('poruke') }} style={{ color: activePage === 'poruke' ? '#00a3ff' : '' }}>Poruke</p>
                <p onClick={() => { setActivePage('misljenja') }} style={{ display: isCC ? 'none' : '', color: activePage === 'misljenja' ? '#00a3ff' : '' }}>Mišljenja korisnika</p>
                <p onClick={() => { setActivePage('mails') }} style={{ color: activePage === 'mails' ? '#00a3ff' : '' }}>Email lista</p>
            </div>
            <div className='route__adminPanel__body__content'>
                <div style={{ display: props.isLogged && access ? 'flex' : 'none' }} className='route__adminPanel__body__content__header'>
                    <img id='hamburger' src='images/hamburger.png' alt='' onClick={() => navbarHandler()} />
                    <span><img src='images/userPic.png' alt='' /><p>{props.loggedUser}</p></span>
                    <img onClick={() => { setSettings(s => !s) }} src='images/settings.png' alt='' />
                    <div style={{ display: settings ? 'flex' : 'none' }}>
                        <Button className='logoutBtn' value='Shop' accent='#0458AD' onClick={() => animateNavigate('/')} />
                        <Button className='logoutBtn' value='Odjavi se' accent='#0458AD' onClick={() => logout()} />
                        <Button className='logoutBtn' value='Promijeni šifru' accent='#0458AD' onClick={() => changePasswordHandler()} />
                        <div style={{ display: changePasswordModal ? 'flex' : 'none' }}>
                            <img src='images/close.png' alt='' onClick={() => changePasswordHandler()} />
                            <input type='password' placeholder='Trenutna šifra' ref={oldPasswordRef} />
                            <input type='password' placeholder='Nova šifra' ref={newPasswordRef} />
                            <Button className='logoutBtn' value='Spremi' accent='#0458AD' onClick={() => changePassword()} />
                            <p id='passMsg' ref={passMsgRef}></p>
                        </div>
                    </div>
                </div>
                {activePage === 'proizvodi' && <div style={{ display: activePage === 'proizvodi' ? '' : 'none' }} className='route__adminPanel__body__content__products'>
                    <div style={{ display: addNewProduct ? '' : 'none' }} className='route__adminPanel__body__content__products__addNew'>
                        <div className='route__adminPanel__body__content__products__addNew__modal'>
                            <div style={{ display: ingredientsModal ? 'flex' : 'none' }} className='route__adminPanel__body__content__products__addNew__modal__ingredients'>
                                <div id='ing'>
                                    <img src='images/close.png' alt='' onClick={() => openIngredientsModal()} />
                                    <p>Sastojci</p>
                                    <div>
                                        {
                                            rows?.map((item) => {
                                                return <span>
                                                    <input id='ingn' type='text' placeholder='Naziv sastojka' />
                                                    <input id='ingq' type='text' placeholder='Kolicina sastojka' />
                                                </span>
                                            })
                                        }
                                        <Button value='+' accent='#001B57' className='moreBtn' onClick={() => addNewRow()} />
                                    </div>
                                    <Button value='Sačuvaj' accent='#001B57' className='saveBtn' onClick={() => addIngredients()} />
                                </div>
                            </div>
                            <div style={{ display: ingredientsModalSI ? 'flex' : 'none' }} className='route__adminPanel__body__content__products__addNew__modal__ingredients'>
                                <div id='ing'>
                                    <img src='images/close.png' alt='' onClick={() => openIngredientsModalSI()} />
                                    <p>Sastojci SI</p>
                                    <div>
                                        {
                                            rows?.map((item) => {
                                                return <span>
                                                    <input id='ingns' type='text' placeholder='Naziv sastojka' />
                                                    <input id='ingqs' type='text' placeholder='Kolicina sastojka' />
                                                </span>
                                            })
                                        }
                                        <Button value='+' accent='#001B57' className='moreBtn' onClick={() => addNewRow()} />
                                    </div>
                                    <Button value='Sačuvaj' accent='#001B57' className='saveBtn' onClick={() => addIngredientsSI()} />
                                </div>
                            </div>
                            <img src='images/close.png' alt='' onClick={() => addNewProductHandler()} />
                            <h2>Dodaj novi proizvod</h2>
                            <div id='grid'>
                                <div>
                                    <p>Ime proizvoda</p>
                                    <input ref={nameRef} type='text' />
                                </div>
                                <div>
                                    <p>Opis proizvoda</p>
                                    <input ref={descRef} type='text' />
                                </div>
                                <div>
                                    <p>Opis proizvoda SI</p>
                                    <input ref={descSiRef} type='text' />
                                </div>
                                <div>
                                    <p>Opis proizvoda EN</p>
                                    <input ref={descEnRef} type='text' />
                                </div>
                                <div>
                                    <p>Opis lista</p>
                                    <input ref={listDescRef} type='text' />
                                </div>
                                <div>
                                    <p>Opis lista SI</p>
                                    <input ref={listDescSiRef} type='text' />
                                </div>
                                <div>
                                    <p>Opis lista EN</p>
                                    <input ref={listDescEnRef} type='text' />
                                </div>
                                <div>
                                    <p>Upozorenja</p>
                                    <input ref={warningsRef} type='text' />
                                </div>
                                <div>
                                    <p>Upozorenja SI</p>
                                    <input ref={warningsSiRef} type='text' />
                                </div>
                                <div>
                                    <p>Upozorenja EN</p>
                                    <input ref={warningsEnRef} type='text' />
                                </div>
                                <div>
                                    <p>Upotreba</p>
                                    <input type='text' ref={usageRef} />
                                </div>
                                <div>
                                    <p>Upotreba SI</p>
                                    <input type='text' ref={usageSiRef} />
                                </div>
                                <div>
                                    <p>Upotreba EN</p>
                                    <input type='text' ref={usageEnRef} />
                                </div>
                                <div id='btns'>
                                    <Button className='ingBtn' value='Dodaj sastojke' accent='#001B57' onClick={() => openIngredientsModal()} />
                                    <Button className='ingBtn' value='Dodaj sastojke SI' accent='#001B57' onClick={() => openIngredientsModalSI()} />
                                </div>
                                <div>
                                    <p>Opširan opis</p>
                                    <textarea ref={descLongRef} />
                                </div>
                                <div>
                                    <p>Opširan opis SI</p>
                                    <textarea ref={descLongSiRef} />
                                </div>
                                <div>
                                    <p>Opširan opis EN</p>
                                    <textarea ref={descLongEnRef} />
                                </div>
                            </div>
                            <p>Oboljenje</p>
                            <div id='illness'>
                                <p onClick={() => { setIllness('potencija') }} style={{ background: illness === 'potencija' ? '#EB5757' : '' }}>Potencija</p>
                                <p onClick={() => { setIllness('hemoroidi') }} style={{ background: illness === 'hemoroidi' ? '#EB5757' : '' }}>Hemoroidi</p>
                                <p onClick={() => { setIllness('krvni pritisak') }} style={{ background: illness === 'krvni pritisak' ? '#EB5757' : '' }}>Krvni pritisak</p>
                                <p onClick={() => { setIllness('prostata') }} style={{ background: illness === 'prostata' ? '#EB5757' : '' }}>Prostata</p>
                                <p onClick={() => { setIllness('mrsavljenje') }} style={{ background: illness === 'mrsavljenje' ? '#EB5757' : '' }}>Mršavljenje</p>
                                <p onClick={() => { setIllness('dijabetes') }} style={{ background: illness === 'dijabetes' ? '#EB5757' : '' }}>Dijabetes</p>
                                <p onClick={() => { setIllness('celulit') }} style={{ background: illness === 'celulit' ? '#EB5757' : '' }}>Celulit</p>
                                <p onClick={() => { setIllness('sluh') }} style={{ background: illness === 'sluh' ? '#EB5757' : '' }}>Sluh</p>
                                <p onClick={() => { setIllness('vid') }} style={{ background: illness === 'vid' ? '#EB5757' : '' }}>Vid</p>
                                <p onClick={() => { setIllness('gljivice') }} style={{ background: illness === 'gljivice' ? '#EB5757' : '' }}>Gljivična infekcija</p>
                                <p onClick={() => { setIllness('koza') }} style={{ background: illness === 'koza' ? '#EB5757' : '' }}>Koža</p>
                                <p onClick={() => { setIllness('nedefinisano') }} style={{ background: illness === 'nedefinisano' ? '#EB5757' : '' }}>Nedefinisano</p>
                            </div>
                            <p>Status</p>
                            <div><p style={{ border: status === 'DOSTUPNO' ? '1px solid #EB5757' : '1px solid transparent' }} onClick={() => { setStatus('DOSTUPNO') }}>DOSTUPNO</p><p style={{ border: status === 'NEDOSTUPNO' ? '1px solid #EB5757' : '1px solid transparent' }} onClick={() => { setStatus('NEDOSTUPNO') }}>NEDOSTUPNO</p></div>
                            <div id='pricing'>
                                <span>
                                    <p>Stara cijena BA</p>
                                    <input ref={oldPriceRef} type='number' />
                                </span>
                                <span>
                                    <p>Stara cijena RS</p>
                                    <input ref={oldPriceRsRef} type='number' />
                                </span>
                                <span>
                                    <p>Stara cijena SI</p>
                                    <input ref={oldPriceSiRef} type='number' />
                                </span>
                                <span>
                                    <p>Stara cijena HR</p>
                                    <input ref={oldPriceHrRef} type='number' />
                                </span>
                            </div>
                            <div id='pricing'>
                                <span>
                                    <p>Trenutna cijena BA</p>
                                    <input ref={curPriceRef} type='number' />
                                </span>
                                <span>
                                    <p>Trenutna cijena RS</p>
                                    <input ref={curPriceRsRef} type='number' />
                                </span>
                                <span>
                                    <p>Trenutna cijena SI</p>
                                    <input ref={curPriceSiRef} type='number' />
                                </span>
                                <span>
                                    <p>Trenutna cijena HR</p>
                                    <input ref={curPriceHrRef} type='number' />
                                </span>
                            </div>
                            <div id='diss'>
                                <p>Popust na količinu</p>
                                <input type='text' ref={discountQuantityRef} />
                            </div>
                            <div id='diss'>
                                <p>Iznos popusta na količinu (%)</p>
                                <input type='number' ref={discountValueRef} />
                            </div>
                            <div id='picgrid'>
                                <div>
                                    <p>Slika 1</p>
                                    <input
                                        type='file'
                                        name='productimage'
                                        onChange={
                                            (event) => {
                                                setSelectedImage(event.target.files[0]);
                                            }
                                        }
                                    />
                                    <p>{selectedImage?.name}</p>
                                </div>
                                <div>
                                    <p>Slika 2</p>
                                    <input
                                        type='file'
                                        name='productimage'
                                        onChange={
                                            (event) => {
                                                setSelectedImageTwo(event.target.files[0]);
                                            }
                                        }
                                    />
                                    <p>{selectedImageTwo?.name}</p>
                                </div>
                                <div>
                                    <p>Slika 3</p>
                                    <input
                                        type='file'
                                        name='productimage'
                                        onChange={
                                            (event) => {
                                                setSelectedImageThree(event.target.files[0]);
                                            }
                                        }
                                    />
                                    <p>{selectedImageThree?.name}</p>
                                </div>
                            </div>
                            <Button className='addBtn' value='Dodaj' accent='#0458AD' onClick={() => addProduct()} />
                            <strong id='errorMsg' ref={errorMsgRef}></strong>
                        </div>
                    </div>
                    <div ref={productRef} style={{ display: editProduct ? 'block' : 'none' }} className='route__adminPanel__body__content__products__editProduct'>
                        <div className='route__adminPanel__body__content__products__editProduct__modal'>
                            <div style={{ display: ingredientsEditModal ? 'block' : 'none' }} className='route__adminPanel__body__content__products__editProduct__ingredients'>
                                <div id='ing'>
                                    <img src='images/close.png' alt='' onClick={() => openIngredientsEditModal()} />
                                    <p>Uredi sastojke</p>
                                    <div>
                                        {
                                            curProduct?.data && ingredientsEditModal ?
                                                oldIngredients.map((item) => {
                                                    return <span>
                                                        <input id='ingne' type='text' placeholder='Naziv sastojka' defaultValue={item.name} />
                                                        <input id='ingqe' type='text' placeholder='Kolicina sastojka' defaultValue={item.quantity} />
                                                    </span>
                                                })
                                                : ''
                                        }
                                    </div>
                                    <Button value='Sačuvaj' accent='#001B57' className='saveBtn' onClick={() => saveIngredients()} />
                                </div>
                            </div>
                            <div style={{ display: ingredientsEditModalSI ? 'block' : 'none' }} className='route__adminPanel__body__content__products__editProduct__ingredients'>
                                <div id='ing'>
                                    <img src='images/close.png' alt='' onClick={() => openIngredientsEditModalSI()} />
                                    <p>Uredi sastojke SI</p>
                                    <div>
                                        {
                                            curProduct.data && ingredientsEditModalSI ?
                                                oldIngredientsSI.map((item) => {
                                                    return <span>
                                                        <input id='ingnse' type='text' placeholder='Naziv sastojka' defaultValue={item.name} />
                                                        <input id='ingqse' type='text' placeholder='Kolicina sastojka' defaultValue={item.quantity} />
                                                    </span>
                                                })
                                                : ''
                                        }
                                    </div>
                                    <Button value='Sačuvaj' accent='#001B57' className='saveBtn' onClick={() => saveIngredientsSI()} />
                                </div>
                            </div>
                            <img src='images/close.png' alt='' onClick={() => editProductHandler()} />
                            <h2>Uredi proizvod</h2>
                            <div id='grid'>
                                <div>
                                    <p>Ime proizvoda</p>
                                    <input type='text' defaultValue={curProduct.data?.ProductName} ref={newProductNameRef} />
                                </div>
                                <div>
                                    <p>Opis proizvoda</p>
                                    <input type='text' defaultValue={curProduct.data?.Description} ref={newDescriptionRef} />
                                </div>
                                <div>
                                    <p>Opis proizvoda SI</p>
                                    <input type='text' defaultValue={curProduct.data?.DescriptionSI} ref={newDescriptionSiRef} />
                                </div>
                                <div>
                                    <p>Opis proizvoda EN</p>
                                    <input type='text' defaultValue={curProduct.data?.DescriptionEN} ref={newDescriptionEnRef} />
                                </div>
                                <div>
                                    <p>Opis lista</p>
                                    <input type='text' defaultValue={curProduct.data?.ListDescription} ref={newListDescRef} />
                                </div>
                                <div>
                                    <p>Opis lista SI</p>
                                    <input type='text' defaultValue={curProduct.data?.ListDescriptionSI} ref={newListDescSiRef} />
                                </div>
                                <div>
                                    <p>Opis lista EN</p>
                                    <input type='text' defaultValue={curProduct.data?.ListDescriptionEN} ref={newListDescEnRef} />
                                </div>
                                <div>
                                    <p>Upozorenja</p>
                                    <input type='text' defaultValue={curProduct.data?.Warnings} ref={newWarningsRef} />
                                </div>
                                <div>
                                    <p>Upozorenja SI</p>
                                    <input type='text' defaultValue={curProduct.data?.WarningsSI} ref={newWarningsSiRef} />
                                </div>
                                <div>
                                    <p>Upozorenja EN</p>
                                    <input type='text' defaultValue={curProduct.data?.WarningsEN} ref={newWarningsEnRef} />
                                </div>
                                <div>
                                    <p>Upotreba</p>
                                    <input type='text' defaultValue={curProduct.data?.Usage} ref={newUsageRef} />
                                </div>
                                <div>
                                    <p>Upotreba SI</p>
                                    <input type='text' defaultValue={curProduct.data?.UsageSI} ref={newUsageSiRef} />
                                </div>
                                <div>
                                    <p>Upotreba EN</p>
                                    <input type='text' defaultValue={curProduct.data?.UsageEN} ref={newUsageEnRef} />
                                </div>
                                <div id='btns'>
                                    <Button className='ingBtn' value='Uredi sastojke' accent='rgb(0, 27, 87)' onClick={() => openIngredientsEditModal()} />
                                    <Button className='ingBtn' value='Uredi sastojke SI' accent='rgb(0, 27, 87)' onClick={() => openIngredientsEditModalSI()} />
                                </div>
                                <div>
                                    <p>Opširan opis</p>
                                    <textarea type='text' defaultValue={curProduct.data?.LongDescription} ref={newLongDescRef} />
                                </div>
                                <div>
                                    <p>Opširan opis SI</p>
                                    <textarea type='text' defaultValue={curProduct.data?.LongDescriptionSI} ref={newLongDescSiRef} />
                                </div>
                                <div>
                                    <p>Opširan opis EN</p>
                                    <textarea type='text' defaultValue={curProduct.data?.LongDescriptionEN} ref={newLongDescEnRef} />
                                </div>
                            </div>
                            <p>Oboljenje</p>
                            <div id='illness'>
                                <p onClick={() => setNewIllness('potencija')} style={{ background: newIllness === 'potencija' ? '#EB5757' : '' }}>Potencija</p>
                                <p onClick={() => setNewIllness('hemoroidi')} style={{ background: newIllness === 'hemoroidi' ? '#EB5757' : '' }}>Hemoroidi</p>
                                <p onClick={() => setNewIllness('krvni pritisak')} style={{ background: newIllness === 'krvni pritisak' ? '#EB5757' : '' }}>Krvni pritisak</p>
                                <p onClick={() => setNewIllness('prostata')} style={{ background: newIllness === 'prostata' ? '#EB5757' : '' }}>Prostata</p>
                                <p onClick={() => setNewIllness('mrsavljenje')} style={{ background: newIllness === 'mrsavljenje' ? '#EB5757' : '' }}>Mršavljenje</p>
                                <p onClick={() => setNewIllness('dijabetes')} style={{ background: newIllness === 'dijabetes' ? '#EB5757' : '' }}>Dijabetes</p>
                                <p onClick={() => setNewIllness('celulit')} style={{ background: newIllness === 'celulit' ? '#EB5757' : '' }}>Celulit</p>
                                <p onClick={() => setNewIllness('sluh')} style={{ background: newIllness === 'sluh' ? '#EB5757' : '' }}>Sluh</p>
                                <p onClick={() => setNewIllness('vid')} style={{ background: newIllness === 'vid' ? '#EB5757' : '' }}>Vid</p>
                                <p onClick={() => setNewIllness('gljivice')} style={{ background: newIllness === 'gljivice' ? '#EB5757' : '' }}>Gljivična infekcija</p>
                                <p onClick={() => setNewIllness('koza')} style={{ background: newIllness === 'koza' ? '#EB5757' : '' }}>Koža</p>
                                <p onClick={() => setNewIllness('nedefinisano')} style={{ background: newIllness === 'nedefinisano' ? '#EB5757' : '' }}>Nedefinisano</p>
                            </div>
                            <p>Status</p>
                            <div><p style={{ border: newStatus === 'DOSTUPNO' ? '1px solid #EB5757' : '1px solid transparent' }} onClick={() => { setNewStatus('DOSTUPNO') }}>DOSTUPNO</p><p style={{ border: newStatus === 'NEDOSTUPNO' ? '1px solid #EB5757' : '1px solid transparent' }} onClick={() => { setNewStatus('NEDOSTUPNO') }}>NEDOSTUPNO</p></div>
                            <div id='pricing'>
                                <span>
                                    <p>Stara cijena BA</p>
                                    <input ref={newOldPriceRef} type='number' defaultValue={curProduct.data?.OldPrice} />
                                </span>
                                <span>
                                    <p>Stara cijena RS</p>
                                    <input ref={newOldPriceRsRef} type='number' defaultValue={curProduct.data?.OldPriceRS} />
                                </span>
                                <span>
                                    <p>Stara cijena SI</p>
                                    <input ref={newOldPriceSiRef} type='number' defaultValue={curProduct.data?.OldPriceSI} />
                                </span>
                                <span>
                                    <p>Stara cijena HR</p>
                                    <input ref={newOldPriceHrRef} type='number' defaultValue={curProduct.data?.OldPriceHR} />
                                </span>
                            </div>
                            <div id='pricing'>
                                <span>
                                    <p>Trenutna cijena BA</p>
                                    <input ref={newCurrentPriceRef} type='number' defaultValue={curProduct.data?.CurrentPrice} />
                                </span>
                                <span>
                                    <p>Trenutna cijena RS</p>
                                    <input ref={newCurrentPriceRsRef} type='number' defaultValue={curProduct.data?.CurrentPriceRS} />
                                </span>
                                <span>
                                    <p>Trenutna cijena SI</p>
                                    <input ref={newCurrentPriceSiRef} type='number' defaultValue={curProduct.data?.CurrentPriceSI} />
                                </span>
                                <span>
                                    <p>Trenutna cijena HR</p>
                                    <input ref={newCurrentPriceHrRef} type='number' defaultValue={curProduct.data?.CurrentPriceHR} />
                                </span>
                            </div>
                            <div id='diss'>
                                <p>Popust na količinu</p>
                                <input type='text' ref={newDiscountQuantityRef} defaultValue={curProduct.data?.Discount ? JSON.parse(curProduct.data?.Discount).Quantity : ''} />
                            </div>
                            <div id='diss'>
                                <p>Iznos popusta na količinu (%)</p>
                                <input type='number' ref={newDiscountValueRef} defaultValue={curProduct.data?.Discount ? JSON.parse(curProduct.data?.Discount).Value : ''} />
                            </div>
                            <div id='picgrid'>
                                <div>
                                    <p>Slika 1</p>
                                    <input
                                        type='file'
                                        name='productimage'
                                        onChange={
                                            (event) => {
                                                setNewImage(event.target.files[0]);
                                            }
                                        }
                                    />
                                    <p>{newImage?.name}</p>
                                </div>
                                <div>
                                    <p>Slika 2</p>
                                    <input
                                        type='file'
                                        name='productimage'
                                        onChange={
                                            (event) => {
                                                setNewImageTwo(event.target.files[0]);
                                            }
                                        }
                                    />
                                    <p>{newImageTwo?.name}</p>
                                </div>
                                <div>
                                    <p>Slika 3</p>
                                    <input
                                        type='file'
                                        name='productimage'
                                        onChange={
                                            (event) => {
                                                setNewImageThree(event.target.files[0]);
                                            }
                                        }
                                    />
                                    <p>{newImageThree?.name}</p>
                                </div>
                            </div>
                            <Button className='addBtn' value='Sačuvaj' accent='#0458AD' onClick={() => saveProduct(curProduct.data?.ID)} />
                        </div>
                    </div>
                    <div className='route__adminPanel__body__content__products__productsList'>
                        <div className='route__adminPanel__body__content__products__productsList__header'>
                            <h2>Lista proizvoda</h2>
                            <Button className='addNewBtn' value='Dodaj novi' accent='#0458AD' onClick={() => addNewProductHandler()} />
                        </div>
                        <br></br>
                        <div className='route__adminPanel__body__content__products__productsList__list'>
                            <FilteredCustomTable
                                id='table'
                                accent='#0458AD'
                                theme='light'
                                headers={['Ime Proizvoda', 'Opis', 'Stara cijena', 'Trenutna cijena', 'Status']}
                                filters={[
                                    { name: "ProductName", friendlyName: "Ime proizvoda", type: "string" },
                                ]}
                                filterCB={f => setFilters(f)}
                                data={products.data?.length > 0 ? addProductsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                            />
                        </div>
                    </div>
                </div>}
                {activePage === 'kuponi' && <div style={{ display: activePage === 'kuponi' ? '' : 'none' }} className='route__adminPanel__body__content__couponCodes'>
                    <div style={{ display: addNewCoupon ? '' : 'none' }} className='route__adminPanel__body__content__couponCodes__addNew'>
                        <div className='route__adminPanel__body__content__couponCodes__addNew__modal'>
                            <img src='images/close.png' alt='' onClick={() => addNewCouponHandler()} />
                            <h2>Dodaj novi kupon kod</h2>
                            <p>Ime kupona</p>
                            <input ref={couponNameRef} type='text' />
                            <p>Kod</p>
                            <input ref={couponCodeRef} type='text' />
                            <div id='select'>
                                <p>Odaberite: <span style={{ border: couponOption === '%' ? '1px solid #EB5757' : '1px solid transparent' }} onClick={() => { setCouponOption('%') }}>%</span> ili <span style={{ border: couponOption === 'Currency' ? '1px solid #EB5757' : '1px solid transparent' }} onClick={() => { setCouponOption('Currency') }}>VALUTA</span></p>
                            </div>
                            <div id='coupon-countires'>
                                <span>
                                    <p>Popust BA</p>
                                    <input ref={couponValueRef} type='number' />
                                </span>
                                <span>
                                    <p>Popust RS</p>
                                    <input ref={couponValueRsRef} type='number' />
                                </span>
                                <span>
                                    <p>Popust HR</p>
                                    <input ref={couponValueHrRef} type='number' />
                                </span>
                                <span>
                                    <p>Popust SI</p>
                                    <input ref={couponValueSiRef} type='number' />
                                </span>
                            </div>
                            <p>Ističe</p>
                            <input ref={expiresRef} type='date' />
                            <Button className='addCouponBtn' value='Dodaj' accent='#0458AD' onClick={() => addCoupons()} />
                        </div>
                    </div>
                    <div style={{ display: editCoupon ? 'block' : 'none' }} className='route__adminPanel__body__content__couponCodes__editCoupon'>
                        <div className='route__adminPanel__body__content__couponCodes__editCoupon__modal'>
                            <img src='images/close.png' alt='' onClick={() => editCouponHandler()} />
                            <h2>Uredi kupon</h2>
                            <p>Ime kupona</p>
                            <input type='text' ref={newCouponNameRef} defaultValue={curCoupon.data?.CouponName} />
                            <p>Kod</p>
                            <input type='text' ref={newCouponCodeRef} defaultValue={curCoupon.data?.CouponCode} />
                            <div id='select'><p>Odaberite: <span style={{ border: newCouponOption === '%' ? '1px solid rgb(235, 87, 87)' : '1px solid transparent' }} onClick={() => { setNewCouponOption('%') }}>%</span> ili <span style={{ border: newCouponOption === 'Currency' ? '1px solid rgb(235, 87, 87)' : '1px solid transparent' }} onClick={() => { setNewCouponOption('Currency') }}>VALUTA</span></p></div>
                            <div id='coupon-countires'>
                                <span>
                                    <p>Popust BA</p>
                                    <input type='number' ref={newCouponValueRef} defaultValue={curCoupon.data?.CouponValue} />
                                </span>
                                <span>
                                    <p>Popust RS</p>
                                    <input ref={newCouponValueRsRef} type='number' defaultValue={curCoupon.data?.CouponValueRS} />
                                </span>
                                <span>
                                    <p>Popust HR</p>
                                    <input ref={newCouponValueHrRef} type='number' defaultValue={curCoupon.data?.CouponValueHR} />
                                </span>
                                <span>
                                    <p>Popust SI</p>
                                    <input ref={newCouponValueSiRef} type='number' defaultValue={curCoupon.data?.CouponValueSI} />
                                </span>
                            </div>
                            <p>Ističe</p>
                            <input type='date' ref={newExpiresRef} />
                            <Button className='addCouponBtn' value='Sačuvaj' accent='#0458AD' onClick={() => saveCoupon(curCoupon.data?.ID)} />
                        </div>
                    </div>
                    <div className='route__adminPanel__body__content__couponCodes__couponsList'>
                        <div className='route__adminPanel__body__content__couponCodes__couponsList__header'>
                            <h2>Lista kupon kodova</h2>
                            <Button className='addNewBtn' value='Dodaj novi' accent='#0458AD' onClick={() => addNewCouponHandler()} />
                        </div>
                        <br></br>
                        <div className='route__adminPanel__body__content__couponCodes__couponsList__list'>
                            <FilteredCustomTable
                                id='table'
                                accent='#0458AD'
                                theme='light'
                                headers={['Ime kupona', 'Kod', 'Opcija', 'Vrijednost', 'Ističe']}
                                filters={[
                                    { name: "CouponName", friendlyName: "Ime kupona", type: "string" },
                                    { name: "CouponCode", friendlyName: "Kod", type: "string" },
                                    { name: "CouponOption", friendlyName: "Opcija", type: "string" },
                                    { name: "CouponValue", friendlyName: "Vrijednost", type: "number" },
                                    { name: "Expires", friendlyName: "Ističe", type: "date" },
                                ]}
                                filterCB={f => setFilters(f)}
                                data={coupons.data?.length > 0 ? addCouponsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                            />
                        </div>
                    </div>
                </div>}
                {activePage === 'korisnici' && <div style={{ display: activePage === 'korisnici' ? 'block' : 'none' }} className='route__adminPanel__body__content__users'>
                    <div style={{ display: addUserModal ? 'block' : 'none' }} className='route__adminPanel__body__content__users__addNewUserModal'>
                        <div id='box'>
                            <img src='images/close.png' alt='' onClick={() => addNewUserModalHandler()} />
                            <h1>Dodaj novog korisnika</h1>
                            <p>Ime i prezime</p>
                            <input type='text' ref={nameCreateRef} />
                            <p>Korisničko ime</p>
                            <input type='text' ref={usernameCreateRef} />
                            <p>Email</p>
                            <input type='text' ref={emailCreateRef} />
                            <p>Lozinka</p>
                            <input type='password' ref={passwordCreateRef} />
                            <p>Izaberi role</p>
                            <div>
                                <span>
                                    <input ref={ccAgentRef} type='checkbox' />
                                    <p>CC agent</p>
                                </span>
                                <span>
                                    <input ref={adminRef} type='checkbox' />
                                    <p>Admin</p>
                                </span>
                            </div>
                            <Button className='addBtn' accent='rgb(4, 88, 173)' value='Dodaj' onClick={() => addUser()} />
                            <br></br>
                            <p ref={msgCreateRef}></p>
                        </div>
                    </div>
                    <div className='route__adminPanel__body__content__users__header'>
                        <h2>Lista korisnika</h2>
                        <Button className='addUsrBtn' value='Dodaj novog korisnika' accent='rgb(4, 88, 173)' onClick={() => addNewUserModalHandler()} />
                    </div>
                    <br></br>
                    <FilteredCustomTable
                        id='table'
                        accent='#0458AD'
                        theme='light'
                        headers={['Ime i prezime', 'Korisničko ime', 'Email', 'Role',]}
                        filters={[
                            { name: "Name", friendlyName: "Ime i prezime", type: "string" },
                            { name: "Username", friendlyName: "Korisnicko ime", type: "string" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={users.data?.length > 0 ? addUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>}
                {activePage === "narudzbe" && <div style={{ display: activePage === 'narudzbe' ? 'block' : 'none' }} className='route__adminPanel__body__content__orders'>
                    <div className='route__adminPanel__body__content__orders__header'>
                        <h2>Narudžbe</h2>
                    </div>
                    <br></br>
                    <FilteredCustomTable
                        id='table'
                        accent='#0458AD'
                        theme='light'
                        headers={['ID', 'Datum kreiranja', 'Država', 'Popust', 'Cijena', 'Način plaćanja', 'Plaćeno',]}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "Country", friendlyName: "Država", type: "string" },
                            { name: "isPaid", friendlyName: "Plaćeno", type: "boolean" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={allOrders.data?.length > 0 ? addOrdersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                    <div style={{ display: canPaginate && allOrders.data.length >= 20 && activePage === 'narudzbe' ? 'block' : 'none' }} ref={paginationTriggerRef}></div>
                    {spinner && <Spinner style={{ width: "100%", height: "64px" }} align='center' />}
                </div>}
                {activePage === 'poruke' && <div style={{ display: activePage === 'poruke' ? 'block' : 'none' }} className='route__adminPanel__body__content__messages' >
                    <div className='route__adminPanel__body__content__messages__header'>
                        <h2>Pristigle poruke</h2>
                    </div>
                    <br></br>
                    <FilteredCustomTable
                        id='table'
                        accent='#0458AD'
                        theme='light'
                        headers={['ID', 'Korisnik', 'Ime i prezime', 'Email', 'Broj telefona', 'Poruka', 'Datum',]}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "User", friendlyName: "Korisnik", type: "string" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={allMessages.data?.length > 0 ? addMessagesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>}
                {activePage === 'generisanje' && <div style={{ display: activePage === 'generisanje' ? 'block' : 'none' }} className='route__adminPanel__body__content__createOrder'>
                    <div className='route__adminPanel__body__content__createOrder__header'>
                        <h2>Generisane narudžbe</h2>
                        <Button className='genOrderBtn' value='Generiši novu narudžbu' accent='#0458AD' onClick={() => generateModalHandler()} />
                    </div>
                    <br></br>
                    <div style={{ display: generateModal ? 'block' : 'none' }} className='route__adminPanel__body__content__createOrder__generateNewOrderModal'>
                        <div className='route__adminPanel__body__content__createOrder__generateNewOrderModal__box'>
                            <div style={{ display: generatedModal ? 'block' : 'none' }} className='route__adminPanel__body__content__createOrder__generateNewOrderModal__box__generated'>
                                <div>
                                    <img id='close' src='images/close.png' alt='' onClick={() => setGeneratedModal(false)} />
                                    <img src='images/tick.png' alt='' />
                                    <h1>Uspješno ste generisali link za narudžbu</h1>
                                    <p>{generatedURL}</p>
                                </div>
                            </div>
                            <img id='closeicn' src='images/close.png' alt='' onClick={() => generateModalHandler()} />
                            <h2>Nova narudžba</h2>
                            <div className='route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox'>
                                <div id='col'>
                                    <div>
                                        <p>Proizvod</p>
                                        <div className='route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox__dropdown' onClick={() => dropdownHandler()}>
                                            <p>{selectedProduct !== null ? selectedProduct : 'IZBABERITE'} <img src='images/ddicnb.svg' alt='' /></p>
                                            <div style={{ display: dropdown ? 'flex' : 'none' }}>
                                                {
                                                    products.data?.map((item) => {
                                                        return <p onClick={() => selectProduct(item.ProductName, item.CurrentPrice, item.CurrentPriceHR, item.CurrentPriceRS, item.CurrentPriceSI, item.ID)}>{item.ProductName}</p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Količina</p>
                                        <input type='number' ref={genQuantityRef} />
                                    </div>
                                    <p ref={genMsgRef} style={{ color: 'red', marginTop: '20px' }}></p>
                                    <Button value='Dodaj' accent='#0458AD' className='generateBtn' onClick={() => prepareProductForGeneration(selectedProduct, genQuantityRef.current.value, selectedProductPrice, selectedProductPriceRS, selectedProductPriceHR, selectedProductPriceSI, selectedProductID)} />
                                </div>
                                <div id='col'>
                                    <div>
                                        <p>Država</p>
                                        <div className='route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox__countryDropdown' onClick={() => countryDropdownHandler()}>
                                            <p>{selectedCountry !== null ? selectedCountry : 'IZBABERITE'} <img src='images/ddicnb.svg' alt='' /></p>
                                            <div style={{ display: countryDropdown ? 'flex' : 'none' }}>
                                                <p onClick={() => selectCountry('Bosna i Hercegovina')}>Bosna i Hercegovina</p>
                                                <p onClick={() => selectCountry('Srbija')}>Srbija</p>
                                                <p onClick={() => selectCountry('Hrvatska')}>Hrvatska</p>
                                                <p onClick={() => selectCountry('Slovenija')}>Slovenija</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='prepared-products'>
                                        <div>
                                            <strong>Proizvod</strong>
                                            <p>Količina</p>
                                        </div>
                                        {
                                            preparedProducts.map((item) => {
                                                return <div>
                                                    <strong>{item.Product}</strong>
                                                    <p>{item.Quantity}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <Button value='Očisti' accent='black' className='clearBtn' onClick={() => deletePreparedProducts()} />
                                    <Button value='Generiši link' accent='#0458AD' className='generateBtn' onClick={() => generateURL()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FilteredCustomTable
                        id='table'
                        accent='#0458AD'
                        theme='light'
                        headers={['ID', 'Država', 'Cijena', 'Datum', 'Plaćeno']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "string" },
                            { name: "Country", friendlyName: "Država", type: "string" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={generatedOrders.data?.length > 0 ? addGeneratedOrdersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>}
                {activePage === 'misljenja' && <div style={{ display: activePage === 'misljenja' ? 'block' : 'none' }} className='route__adminPanel__body__content__opinions'>
                    <div style={{ display: addOpinionModal ? 'flex' : 'none' }} className='route__adminPanel__body__content__opinions__addNew'>
                        <div id='box'>
                            <img src='images/close.png' alt='' onClick={() => addOpinionModalHandler()} />
                            <h1>Dodaj novo mišljenje korisnika</h1>
                            <div id='box-gridbox'>
                                <div>
                                    <p>Proizvod</p>
                                    <Dropdown data={products.data?.map(item => {
                                        return { name: item.ProductName, value: item.ID }
                                    })} selected={0} onChange={e => setOpinionProduct(e?.value)} />
                                </div>
                                <div>
                                    <p>Naslov</p>
                                    <input type='text' ref={headingRef} />
                                </div>
                                <div>
                                    <p>Naslov SI</p>
                                    <input type='text' ref={headingSiRef} />
                                </div>
                                <div>
                                    <p>Naslov EN</p>
                                    <input type='text' ref={headingEnRef} />
                                </div>
                                <div>
                                    <p>Ime</p>
                                    <input type='text' ref={opinionNameRef} />
                                </div>
                                <div>
                                    <p>Ime SI</p>
                                    <input type='text' ref={opinionNameSiRef} />
                                </div>
                                <div>
                                    <p>Ime EN</p>
                                    <input type='text' ref={opinionNameEnRef} />
                                </div>
                                <div>
                                    <p>Godine</p>
                                    <input type='number' ref={yearsRef} />
                                </div>
                                <div></div>
                                <div>
                                    <p>Text</p>
                                    <textarea ref={textRef} />
                                </div>
                                <div>
                                    <p>Text SI</p>
                                    <textarea ref={textSiRef} />
                                </div>
                                <div>
                                    <p>Text EN</p>
                                    <textarea ref={textEnRef} />
                                </div>
                            </div>
                            <p>Slika</p>
                            <input
                                type='file'
                                name='personimage'
                                onChange={
                                    (event) => {
                                        setSelectedOpinionImage(event.target.files[0]);
                                    }
                                }
                            />
                            <p>{selectedOpinionImage?.name}</p>
                            <Button className='addOpinionBtn' value='Dodaj' accent='rgb(4, 88, 173)' onClick={() => addOpinion()} />
                            <p id='msg' ref={opinionsMsgRef}></p>
                        </div>
                    </div>
                    <div className='route__adminPanel__body__content__opinions__header'>
                        <h2>Mišljenja korisnika</h2>
                        <Button className='addOpinion' value='Dodaj još' accent='#0458AD' onClick={() => addOpinionModalHandler()} />
                    </div>
                    <br></br>
                    <FilteredCustomTable
                        id='table'
                        accent='#0458AD'
                        theme='light'
                        headers={['ID', 'Proizvod', 'Naslov', 'Ime', 'Godine', 'Datum kreiranja']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "Years", friendlyName: "Godine", type: "number" },
                            { name: "Heading", friendlyName: "Naslov", type: "string" },
                            { name: "Product", friendlyName: "Proizvod", type: "string" },
                            { name: "updatedAt", friendlyName: "Datum", type: "date" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={opinions.data?.length > 0 ? addOpinionsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>}
                {activePage === 'mails' && <div style={{ display: activePage === 'mails' ? 'block' : 'none' }} className='route__adminPanel__body__content__emails'>
                    <div style={{ display: newEmailModal ? 'flex' : 'none' }} className='route__adminPanel__body__content__emails__newEmail'>
                        <div id='emailModal'>
                            <div style={{ display: mailSpinner ? 'block' : 'none' }} id='spinner'>
                                <Spinner color='rgb(4, 88, 173)' align='center' />
                            </div>
                            <img id='close' src='images/close.png' alt='' onClick={() => newEmailModalHandler()} />
                            <span id='ms'><h1 id='mh'>Pošaljite novi email</h1><span><p>pošalji svima</p><input onChange={() => setToAll(c => !c)} type='checkbox' ref={sendToAllRef} /></span></span>
                            <p style={{ display: toAll ? 'none' : 'block' }} id='mp'>Primatelj</p>
                            <input style={{ display: toAll ? 'none' : 'block' }} type='text' placeholder='Email' ref={emailRef} />
                            <p id='mp'>Naslov</p>
                            <input type='text' placeholder='Subject line' ref={subjectRef} />
                            <p id='mp'>Tijelo</p>
                            <BioplarTextEditor markup="" onChange={onChange} />
                            <p id='mailmsg' ref={mailMsgRef}></p>
                            <Button className='sendEmailBtn' accent='rgb(4, 88, 173)' value='Pošalji' onClick={() => sendEmail()} />
                        </div>
                    </div>
                    <div className='route__adminPanel__body__content__emails__header'>
                        <h2>Email Lista</h2>
                        <Button className='addEmail' value='Pošalji mail' accent='#0458AD' onClick={() => newEmailModalHandler()} />
                    </div>
                    <br></br>
                    <FilteredCustomTable
                        id='table'
                        accent='#0458AD'
                        theme='light'
                        headers={['ID', 'Korisnik', 'Email', 'Datum kreiranja']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "User", friendlyName: "Korisnik", type: "string" },
                            { name: "Email", friendlyName: "Email", type: "string" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={emails.data?.length > 0 ? addEmailsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>}
            </div>
        </div>
    </div>
}
