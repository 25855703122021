import {HashRouter as Router, Routes, Route} from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";
import Spinner from "./components/customComponents/Spinner";

import LandingPage from "./routes/LandingPage";
import AdminPanel from "../src/routes/AdminPanel";
import ShoppingCart from "./routes/ShoppingCart";
import AboutUs from "./routes/AboutUs";
import ProductPage from "./routes/ProductPage";
import Catalog from "./routes/Catalog";
import WhyNatural from "./routes/WhyNatural";
import ContactUs from "./routes/ContactUs";
import ViewImage from "./routes/ViewImage";
import TermsOfService from "./routes/TermsOfService";

import * as backendModule from "../src/modules/backendModule";
import axios from "axios";
import React from "react";
import UserPanel from "./routes/UserPanel";

import {bosnian} from "../src/languages/bosnian";
import {serbian} from "../src/languages/serbian";
import {slovenian} from "../src/languages/slovenian";
import {english} from "../src/languages/english";
import {croatian} from "./languages/croatian";

const App = () => {
  const [location, setLocation] = React.useState('BA');

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/location/getLocation`,
      ...backendModule.axiosConfig
    }).then(res => {
      setLocation(res.data.data.countryCode);
    }).catch(() => {

    });
  }, []);

  const [language, setLanguage] = React.useState(bosnian);
  React.useEffect(() => {
    switch(location){
      case 'BA':
        setLanguage(bosnian);
      break;
      case 'RS':
        setLanguage(serbian);
      break;
      case 'SI':
        setLanguage(slovenian);
      break;
      case 'HR':
        setLanguage(croatian);
      break;
      default: setLanguage(english)
    }
  }, [location]);
  
  const timestampSelector = useSelector(state => state.timestamp);
  const [logged, setLogged] = React.useState(null);
  const [inCartNum, setInCartNum] = React.useState(0);
  const [inCartPP, setInCartPP] = React.useState([]);
  const [inCart, setInCart] = React.useState([]);
  const [loggedUser, setLoggedUser] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setLogged(true);
        setLoggedUser(res.data.data.UserInfo.Username);
        setIsAdmin(res.data.data.UserInfo.Flags.isAdmin || res.data.data.UserInfo.Flags.isCCagent ? true : false);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, [timestampSelector]);

  if (logged === null) return <Spinner style={{ width: "64px", height: "64px" }} color="#3F73FF" align="center" />

  const addToCart = (id) => {
    let counter = 0;
    inCart.map((item) => {
      if(item === id){
        counter++;
      }
    });
    if(counter === 0){
      inCart.push(id);
      setInCartNum(prev => {return prev + 1});
    }
  }

  const addToCartProductPage = (id, quantity) => {
    let counter = 0;
    inCartPP.map((item, index) => {
      if(item.ID === id){
        counter++;
      }
    });
    if(counter === 0){
      inCartPP.push({ID: id, Quantity: quantity});
      setInCartNum(prev => {return prev + 1});
    }
  }

  const removeFromCart = (id) => {
    setInCartNum(prev => {return prev - 1});
    setInCart(i => i.filter((_, idx) => idx !== id));
  }

  const removeFromCartProductPage = (id) => {
    setInCartNum(prev => {return prev - 1});
    setInCartPP(i => i.filter((_, idx) => idx !== id));
  }

  return <Router>
    <Header location={location} language={language} setLanguage={setLanguage} inCart={inCartNum} />
    <ContentWrapper>
      <Routes>

        <Route path="/" element={<LandingPage language={language} location={location} addToCart={addToCart} />} />
        {isAdmin && <Route path="/adminpanel" element={<AdminPanel location={location} loggedUser={loggedUser} isLogged={logged} />} />}
        
        <Route path="/shoppingcart" element={<ShoppingCart language={language} location={location} productsPP={inCartPP} removeFromCartPP={removeFromCartProductPage} removeFromCart={removeFromCart} products={inCart} />} />
        <Route path="/kosarica" element={<ShoppingCart language={language} location={location} productsPP={inCartPP} removeFromCartPP={removeFromCartProductPage} removeFromCart={removeFromCart} products={inCart} />} />
        <Route path="/kosara" element={<ShoppingCart language={language} location={location} productsPP={inCartPP} removeFromCartPP={removeFromCartProductPage} removeFromCart={removeFromCart} products={inCart} />} />

        <Route path="/aboutus" element={<AboutUs location={location} language={language} />} />
        <Route path="/o-nama" element={<AboutUs location={location} language={language} />} />
        <Route path="/onas" element={<AboutUs location={location} language={language} />} />
        
        <Route path="/product/:slog" element={<ProductPage language={language} location={location} addToCartProductPage={addToCartProductPage} addToCart={addToCart} />} />
        <Route path="/proizvod/:slog" element={<ProductPage language={language} location={location} addToCartProductPage={addToCartProductPage} addToCart={addToCart} />} />
        <Route path="/izdelek/:slog" element={<ProductPage language={language} location={location} addToCartProductPage={addToCartProductPage} addToCart={addToCart} />} />

        <Route path="/catalog" element={<Catalog language={language} location={location} addToCart={addToCart} />} />
        <Route path="/katalog" element={<Catalog language={language} location={location} addToCart={addToCart} />} />

        <Route path="/whynatural" element={<WhyNatural location={location} language={language} />} />
        <Route path="/zasto-prirodno" element={<WhyNatural location={location} language={language} />} />
        <Route path="/zakaj-naravno" element={<WhyNatural location={location} language={language} />} />

        <Route path="/contactus" element={<ContactUs location={location} language={language} />} />
        <Route path="/kontakt" element={<ContactUs location={location} language={language} />} />
        <Route path="/stik" element={<ContactUs location={location} language={language} />} />

        <Route path="/userpanel" element={<UserPanel language={language} location={location} />} />
        <Route path="/korisnickipanel" element={<UserPanel language={language} location={location} />} />

        <Route path="/image/:src" element={<ViewImage />} />

        <Route path="/terms-of-service" element={<TermsOfService location={location} language={language} />} />
        <Route path="/uslovi-koristenja" element={<TermsOfService location={location} language={language} />} />
        <Route path="/pogoji-uporabe" element={<TermsOfService location={location} language={language} />} />
        
      </Routes>
    </ContentWrapper>
  </Router>
};

export default App;
