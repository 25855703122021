import React from 'react';
import Button from '../../components/customComponents/Button';
import "./index.scss";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import moment from 'moment';
import Footer from "../../components/Footer";

export default function UserPanel(props) {

    const [currency, setCurrency] = React.useState(props.location === 'BA' ? 'BAM' : props.location === 'RS' ? 'RSD' : props.location === 'HR' ? 'HRK' : 'EUR');

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [access, setAccess] = React.useState(false);
    const [curUser, setCurUser] = React.useState(null);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.UserInfo.Flags.isAdmin === true) {
                    animateNavigate('/');
                    setAccess(false);
                }
                else {
                    setAccess(true);
                    setCurUser(res.data.data.UserInfo.Username);
                    setCurUserData(res.data.data.UserInfo);
                }
            }
            else {

            }
        }).catch(() => {

        });
    }, []);

    const [curUserData, setCurUserData] = React.useState(null);

    const getCurUserData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getCurUserDetails`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCurUserData(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getCurUserData();
    }, []);


    const curDispatch = useDispatch();
    const logout = () => {
        let data;
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                animateNavigate('/');
            }
            else {

            }
        }).catch(() => {

        });
    }

    const [activePage, setActivePage] = React.useState('settings');

    const [shippingDetails, setShippingDetails] = React.useState([]);
    const [dataLength, setDataLength] = React.useState(0);

    const getUserData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getCurUserShippingDetails`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                Object.values(res.data.data).map((item) => {
                    if (item !== '') {
                        setDataLength(prev => { return prev + 1 });
                    }
                });
                setShippingDetails(res.data.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getUserData();
    }, []);


    const nameRef = React.useRef(null);
    const houseNumberRef = React.useRef(null);
    const cityRef = React.useRef(null);
    const streetRef = React.useRef(null);
    const zipRef = React.useRef(null);
    const phoneNumberRef = React.useRef(null);
    const msgRef = React.useRef(null);

    const addDetails = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUserDetails`,
            data: {
                ShippingName: nameRef.current.value,
                HouseNumber: houseNumberRef.current.value,
                City: cityRef.current.value,
                Street: streetRef.current.value,
                ZIP: zipRef.current.value,
                PhoneNumber: phoneNumberRef.current.value,
                PaymentMethod: shippingDetails.PaymentMethod ? shippingDetails.PaymentMethod : '',
                BillingDetails: shippingDetails.BillingDetails ? JSON.parse(shippingDetails.BillingDetails) : {}
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getUserData();
            }
            else {
                msgRef.current.innerText = 'Sva polja moraju biti popunjena!';
            }
        }).catch(() => {
            msgRef.current.innerText = 'Server error';
        });
    }

    const [edit, setEdit] = React.useState(false);
    const newShippingNameRef = React.useRef(null);
    const newHouseNumberRef = React.useRef(null);
    const newCityRef = React.useRef(null);
    const newStreetRef = React.useRef(null);
    const newZIPref = React.useRef(null);
    const newPhoneNumberRef = React.useRef(null);
    const eMsgRef = React.useRef(null);

    const editDetailsHandler = () => {
        if (edit) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/editUserDetails`,
                data: {
                    ShippingName: newShippingNameRef.current.value,
                    HouseNumber: newHouseNumberRef.current.value,
                    City: newCityRef.current.value,
                    Street: newStreetRef.current.value,
                    ZIP: newZIPref.current.value,
                    PhoneNumber: newPhoneNumberRef.current.value,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    getUserData();
                    setEdit(e => !e);
                }
                else {
                    eMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                }
            }).catch(() => {
                eMsgRef.current.innerText = 'Server error';
            });
        }
        else {
            setEdit(e => !e);
        }
    }

    const [dropdown, setDropdown] = React.useState(false);

    const dropdownHandler = () => {
        setDropdown(d => !d);
    }

    const [dropdownSelected, setDropdownSelected] = React.useState(null);

    const billingNameRef = React.useRef(null);
    const billingHouseNumberRef = React.useRef(null);
    const billingCityRef = React.useRef(null);
    const billingStreetRef = React.useRef(null);
    const billingZIPref = React.useRef(null);
    const billingPhoneNumberRef = React.useRef(null);

    const newBillingNameRef = React.useRef(null);
    const newBillingHouseNumberRef = React.useRef(null);
    const newBillingCityRef = React.useRef(null);
    const newBillingStreetRef = React.useRef(null);
    const newBillingZIPref = React.useRef(null);
    const newBillingPhoneNumberRef = React.useRef(null);
    const companyNameRef = React.useRef(null);
    const companyAddressRef = React.useRef(null);
    const companyIDnumberRef = React.useRef(null);
    const companyPDVnumberRef = React.useRef(null);
    const newCompanyNameRef = React.useRef(null);
    const newCompanyAddressRef = React.useRef(null);
    const newCompanyIDnumberRef = React.useRef(null);
    const newCompanyPDVnumberRef = React.useRef(null);

    const paymentMsgRef = React.useRef(null);
    const [editPayment, setEditPayment] = React.useState(false);

    const addPaymentMethod = () => {

        if (Object.keys(JSON.parse(shippingDetails.BillingDetails)).length > 0) {
            if (editPayment) {
                let billingDetails = {};

                if (
                    !newBillingNameRef.current.value ||
                    !newBillingHouseNumberRef.current.value ||
                    !newBillingCityRef.current.value ||
                    !newBillingZIPref.current.value ||
                    !newBillingStreetRef.current.value ||
                    !newBillingPhoneNumberRef.current.value
                ) {
                    return paymentMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                }
                else {
                    if (dropdownSelected === 'Virman') {
                        billingDetails = {
                            Name: newBillingNameRef.current.value,
                            HouseNumber: newBillingHouseNumberRef.current.value,
                            City: newBillingCityRef.current.value,
                            ZIP: newBillingZIPref.current.value,
                            Street: newBillingStreetRef.current.value,
                            PhoneNumber: newBillingPhoneNumberRef.current.value,
                            CompanyName: newCompanyNameRef.current.value,
                            CompanyAddress: newCompanyAddressRef.current.value,
                            CompanyIDnumber: newCompanyIDnumberRef.current.value,
                            CompanyPDVnumber: newCompanyPDVnumberRef.current.value,
                        };
                    }
                    else {
                        billingDetails = {
                            Name: newBillingNameRef.current.value,
                            HouseNumber: newBillingHouseNumberRef.current.value,
                            City: newBillingCityRef.current.value,
                            ZIP: newBillingZIPref.current.value,
                            Street: newBillingStreetRef.current.value,
                            PhoneNumber: newBillingPhoneNumberRef.current.value
                        };
                    }
                }

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/users/editUserDetails`,
                    data: {
                        ShippingName: shippingDetails.ShippingName ? shippingDetails.ShippingName : '',
                        HouseNumber: shippingDetails.HouseNumber ? shippingDetails.HouseNumber : '',
                        City: shippingDetails.City ? shippingDetails.City : '',
                        Street: shippingDetails.Street ? shippingDetails.Street : '',
                        ZIP: shippingDetails.ZIP ? shippingDetails.ZIP : '',
                        PhoneNumber: shippingDetails.PhoneNumber ? shippingDetails.PhoneNumber : '',
                        PaymentMethod: dropdownSelected ? dropdownSelected : shippingDetails.PaymentMethod,
                        BillingDetails: billingDetails
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        getUserData();
                        setEditPayment(e => !e);
                    }
                    else {
                        paymentMsgRef.current.innerText = res.data.data;
                    }
                }).catch(() => {
                    paymentMsgRef.current.innerText = 'Server error';
                });
            }
            else {
                setEditPayment(e => !e);
            }
        }
        else {
            let billingDetails = {};

            if (
                !billingNameRef.current.value ||
                !billingHouseNumberRef.current.value ||
                !billingCityRef.current.value ||
                !billingZIPref.current.value ||
                !billingStreetRef.current.value ||
                !billingPhoneNumberRef.current.value
            ) {
                return paymentMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
            }
            else {
                if (dropdownSelected === 'Virman') {
                    billingDetails = {
                        Name: billingNameRef.current.value,
                        HouseNumber: billingHouseNumberRef.current.value,
                        City: billingCityRef.current.value,
                        ZIP: billingZIPref.current.value,
                        Street: billingStreetRef.current.value,
                        PhoneNumber: billingPhoneNumberRef.current.value,
                        CompanyName: companyNameRef.current.value,
                        CompanyAddress: companyAddressRef.current.value,
                        CompanyIDnumber: companyIDnumberRef.current.value,
                        CompanyPDVnumber: companyPDVnumberRef.current.value,
                    };
                }
                else {
                    billingDetails = {
                        Name: billingNameRef.current.value,
                        HouseNumber: billingHouseNumberRef.current.value,
                        City: billingCityRef.current.value,
                        ZIP: billingZIPref.current.value,
                        Street: billingStreetRef.current.value,
                        PhoneNumber: billingPhoneNumberRef.current.value
                    };
                }
            }

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/editUserDetails`,
                data: {
                    ShippingName: shippingDetails.ShippingName ? shippingDetails.ShippingName : '',
                    HouseNumber: shippingDetails.HouseNumber ? shippingDetails.HouseNumber : '',
                    City: shippingDetails.City ? shippingDetails.City : '',
                    Street: shippingDetails.Street ? shippingDetails.Street : '',
                    ZIP: shippingDetails.ZIP ? shippingDetails.ZIP : '',
                    PhoneNumber: shippingDetails.PhoneNumber ? shippingDetails.PhoneNumber : '',
                    PaymentMethod: dropdownSelected ? dropdownSelected : shippingDetails.PaymentMethod,
                    BillingDetails: billingDetails
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    getUserData();
                }
                else {
                    paymentMsgRef.current.innerText = res.data.data;
                }
            }).catch(() => {
                paymentMsgRef.current.innerText = 'Server error';
            });
        }
    }

    const [filters, setFilters] = React.useState([]);
    const [userOrders, setUserOrders] = React.useState([]);
    const getUserOrders = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getUserOrders`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setUserOrders(res.data);
            }
            else {
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getUserOrders();
    }, [filters]);

    const addOrdersToTable = () => {
        if (userOrders.data) {
            return userOrders?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Discount} {item.Discount > 0 ? item.DiscountOption === 'Currency' ? currency : '%' : '-'}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price} {currency}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PaymentMethod}</p></div> },
                    {

                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: props.language.PodaciDostave2, triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'baseline', alignItems: 'baseline' }}>
                                        <h3>{props.language.PodaciDostave}</h3>
                                        <p><strong>Ime i prezime:</strong> {JSON.parse(item.ShippingData).ShippingName}</p>
                                        <p><strong>Grad:</strong> {JSON.parse(item.ShippingData).City}</p>
                                        <p><strong>Ulica:</strong> {JSON.parse(item.ShippingData).Street}</p>
                                        <p><strong>Broj kuće:</strong> {JSON.parse(item.ShippingData).HouseNumber}</p>
                                        <p><strong>ZIP:</strong> {JSON.parse(item.ShippingData).ZIP}</p>
                                        <p><strong>Broj telefona:</strong> {JSON.parse(item.ShippingData).PhoneNumber}</p>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: props.language.PodaciPlacanja2, triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'baseline', alignItems: 'baseline' }}>
                                        <h3>{props.language.PodaciPlacanja}</h3>
                                        <p><strong>Ime i prezime:</strong> {JSON.parse(item.BillingData).Name}</p>
                                        <p><strong>Grad:</strong> {JSON.parse(item.BillingData).City}</p>
                                        <p><strong>Ulica:</strong> {JSON.parse(item.BillingData).Street}</p>
                                        <p><strong>Broj kuće:</strong> {JSON.parse(item.BillingData).HouseNumber}</p>
                                        <p><strong>ZIP:</strong> {JSON.parse(item.BillingData).ZIP}</p>
                                        <p><strong>Broj telefona:</strong> {JSON.parse(item.BillingData).PhoneNumber}</p>
                                        <div style={{ display: JSON.parse(item.BillingData).CompanyName ? 'block' : 'none' }}>
                                            <p><strong>Naziv kompanije:</strong> {JSON.parse(item.BillingData).CompanyName}</p>
                                            <p><strong>Adresa kompanije:</strong> {JSON.parse(item.BillingData).CompanyAddress}</p>
                                            <p><strong>PDV broj:</strong> {JSON.parse(item.BillingData).PDVnumber}</p>
                                            <p><strong>ID broj:</strong> {JSON.parse(item.BillingData).IDnumber}</p>
                                        </div>
                                    </div>);
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: props.language.PogledajArtikle, triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'grid' }} id='articles-table'>
                                        <FilteredCustomTable
                                            id='table'
                                            accent='#0458AD'
                                            theme='light'
                                            headers={[props.language.Proizvod, props.language.Kolicina]}
                                            data={
                                                JSON.parse(item.Products).map((item) => {
                                                    return [
                                                        { keyID: 'noData', type: 'custom', data: <p style={{ textAlign: 'center' }}>{item.ProductName}</p> },
                                                        { keyID: 'noData', type: 'custom', data: <p style={{ textAlign: 'center' }}>{item.Quantity}</p> }
                                                    ]
                                                })
                                            }
                                        />
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [editUser, setEditUser] = React.useState(false);
    const newNameRef = React.useRef(null);
    const newUsernameRef = React.useRef(null);
    const newEmailRef = React.useRef(null);
    const editMsgRef = React.useRef(null);

    const editUserHandler = () => {
        if (!editUser) {
            setEditUser(true);
        }
        else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/editCurUserDetails`,
                data: {
                    Name: newNameRef.current.value,
                    Username: newUsernameRef.current.value,
                    Email: newEmailRef.current.value
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    getCurUserData();
                    setEditUser(false);
                }
                else {
                    editMsgRef.current.innerText = 'Došlo je do greške';
                    editMsgRef.current.style.color = 'red';
                    editMsgRef.current.style.textAlign = 'center';
                    setTimeout(() => {
                        editMsgRef.current.innerText = '';
                    }, 2000);
                }
            }).catch(() => {
                editMsgRef.current.innerText = 'Server error';
                editMsgRef.current.style.color = 'red';
                editMsgRef.current.style.textAlign = 'center';
                setTimeout(() => {
                    editMsgRef.current.innerText = '';
                }, 2000);
            });
        }
    }

    const [passwordModal, setPasswordsModal] = React.useState(false);
    const changePasswordHandler = () => {
        setPasswordsModal(p => !p);
    }

    const oldPasswordRef = React.useRef(null);
    const newPasswordRef = React.useRef(null);
    const passMsgRef = React.useRef(null);

    const savePassword = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                oldPassword: oldPasswordRef.current.value,
                newPassword: newPasswordRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                passMsgRef.current.style.color = 'green';
                passMsgRef.current.innerText = 'Uspješno promijenjena šifra!';
                setTimeout(() => {
                    changePasswordHandler();
                }, 1500);
            }
            else {
                passMsgRef.current.style.color = 'red';
                passMsgRef.current.innerText = res.data.data;
            }
        }).catch(() => {
            passMsgRef.current.style.color = 'red';
            passMsgRef.current.innerText = 'Server error';
        });
    }

    const [navbar, setNavbar] = React.useState(false);
    const navbarHandler = () => {
        setNavbar(n => !n);
    }

    return (
        <>
            <div style={{ display: access ? '' : 'none' }} className='route__userPanel'>
                <span id='mobile-wel'>
                    <img id='hamburger' src='images/hamburger.png' alt='' onClick={() => navbarHandler()} />
                    <span>
                        <img id='bigProfilePic' src='images/profileBig.png' alt='' />
                        <p id='curuser'>{curUser}</p>
                    </span>
                </span>
                <div style={{ display: navbar ? 'grid' : '' }} className='route__userPanel__navbar'>
                    <span id='wel'>
                        <img id='bigProfilePic' src='images/profileBig.png' alt='' />
                        <p id='curuser'>{curUser}</p>
                    </span>
                    <div onClick={() => { setActivePage('settings') }}>
                        <img src='images/moj profil.png' alt='' />
                        <p style={{ fontWeight: activePage === 'settings' ? '700' : '400', color: activePage === 'settings' ? '#001B57' : '#8A8C90' }}>{props.language.PostavkeProfila}</p>
                    </div>
                    <div onClick={() => { setActivePage('shipping') }}>
                        <img src='images/shipicn.png' alt='' />
                        <p style={{ fontWeight: activePage === 'shipping' ? '700' : '400', color: activePage === 'shipping' ? '#001B57' : '#8A8C90' }}>{props.language.DetaljiDostave}</p>
                    </div>
                    <div onClick={() => { setActivePage('payment') }}>
                        <img src='images/transakcije.png' alt='' />
                        <p style={{ fontWeight: activePage === 'payment' ? '700' : '400', color: activePage === 'payment' ? '#001B57' : '#8A8C90' }}>{props.language.NaciniPlacanja}</p>
                    </div>
                    <div onClick={() => { setActivePage('orders') }}>
                        <img src='images/narudzbe.png' alt='' />
                        <p style={{ fontWeight: activePage === 'orders' ? '700' : '400', color: activePage === 'orders' ? '#001B57' : '#8A8C90' }}>{props.language.VaseNarudzbe}</p>
                    </div>
                    <div onClick={() => { setActivePage('changepassword') }}>
                        <img src='images/passicn.png' alt='' />
                        <p style={{ fontWeight: activePage === 'changepassword' ? '700' : '400', color: activePage === 'changepassword' ? '#001B57' : '#8A8C90' }}>{props.language.PromjenaLozinke}</p>
                    </div>
                    <div onClick={() => { logout() }}>
                        <img src='images/logout.png' alt='' />
                        <p>{props.language.OdjaviSe}</p>
                    </div>
                </div>
                <div className='route__userPanel__body'>
                    {activePage === 'shipping' && <div style={{ display: activePage === 'shipping' ? 'block' : 'none' }} className='route__userPanel__body__shippingDetails'>
                        {
                            dataLength > 2 ?
                                <div className='route__userPanel__body__shippingDetails__details'>
                                    <span><h2>{props.language.VasiPodaciDostave}</h2><Button className='editShippingBtn' value={!edit ? props.language.Uredi : props.language.Sacuvaj} accent='#001B57' onClick={() => editDetailsHandler()} /></span>
                                    <div className='route__userPanel__body__shippingDetails__details__gridbox'>
                                        <div>
                                            <p>{props.language.ImeIprezime}</p>
                                            <strong style={{ display: !edit ? 'block' : 'none' }}>{shippingDetails.ShippingName}</strong>
                                            <input style={{ display: edit ? 'block' : 'none' }} ref={newShippingNameRef} type='text' defaultValue={shippingDetails.ShippingName} />
                                        </div>
                                        <div>
                                            <p>{props.language.BrojKuce}</p>
                                            <strong style={{ display: !edit ? 'block' : 'none' }}>{shippingDetails.HouseNumber}</strong>
                                            <input style={{ display: edit ? 'block' : 'none' }} ref={newHouseNumberRef} type='text' defaultValue={shippingDetails.HouseNumber} />
                                        </div>
                                        <div>
                                            <p>{props.language.Grad}</p>
                                            <strong style={{ display: !edit ? 'block' : 'none' }}>{shippingDetails.City}</strong>
                                            <input style={{ display: edit ? 'block' : 'none' }} ref={newCityRef} type='text' defaultValue={shippingDetails.City} />
                                        </div>
                                        <div>
                                            <p>{props.language.Ulica}</p>
                                            <strong style={{ display: !edit ? 'block' : 'none' }}>{shippingDetails.Street}</strong>
                                            <input style={{ display: edit ? 'block' : 'none' }} ref={newStreetRef} type='text' defaultValue={shippingDetails.Street} />
                                        </div>
                                        <div>
                                            <p>{props.language.PostanskiBroj}</p>
                                            <strong style={{ display: !edit ? 'block' : 'none' }}>{shippingDetails.ZIP}</strong>
                                            <input style={{ display: edit ? 'block' : 'none' }} ref={newZIPref} type='text' defaultValue={shippingDetails.ZIP} />
                                        </div>
                                        <div>
                                            <p>{props.language.BrojTelefona}</p>
                                            <strong style={{ display: !edit ? 'block' : 'none' }}>{shippingDetails.PhoneNumber}</strong>
                                            <input style={{ display: edit ? 'block' : 'none' }} ref={newPhoneNumberRef} type='text' defaultValue={shippingDetails.PhoneNumber} />
                                        </div>
                                    </div>
                                    <strong ref={eMsgRef}></strong>
                                </div>
                                :
                                <div className='route__userPanel__body__shippingDetails__add'>
                                    <span><h2>{props.language.DodajPodatkeZaDostavu}</h2><Button className='saveShippingBtn' value='Sačuvaj' accent='#001B57' onClick={() => addDetails()} /></span>
                                    <div className='route__userPanel__body__shippingDetails__add__gridbox'>
                                        <div>
                                            <p>{props.language.ImeIprezime}</p>
                                            <input type='text' ref={nameRef} />
                                        </div>
                                        <div>
                                            <p>{props.language.BrojKuce}</p>
                                            <input type='text' ref={houseNumberRef} />
                                        </div>
                                        <div>
                                            <p>{props.language.Grad}</p>
                                            <input type='text' ref={cityRef} />
                                        </div>
                                        <div>
                                            <p>{props.language.Ulica}</p>
                                            <input type='text' ref={streetRef} />
                                        </div>
                                        <div>
                                            <p>{props.language.PostanskiBroj}</p>
                                            <input type='text' ref={zipRef} />
                                        </div>
                                        <div>
                                            <p>{props.language.BrojTelefona}</p>
                                            <input type='text' ref={phoneNumberRef} />
                                        </div>
                                    </div>
                                    <p>{props.language.TrenutnoNematePodatkeZaDostavu}</p>
                                    <strong ref={msgRef}></strong>
                                </div>
                        }
                    </div>}
                    {activePage === 'payment' && <div style={{ display: activePage === 'payment' ? 'block' : 'none' }} className='route__userPanel__body__payment' >
                        <div className='route__userPanel__body__payment__box'>
                            <span><h2>{props.language.VasiNaciniPlacanja}</h2><Button value={Object.keys(shippingDetails).length > 0 && Object.keys(JSON.parse(shippingDetails.BillingDetails)).length > 0 && !editPayment ? props.language.Uredi : props.language.Sacuvaj} className='savePaymentMethodBtn' accent='#001B57' onClick={() => addPaymentMethod()} /></span>
                            <p>{shippingDetails.PaymentMethod ? `${props.language.VasTrenutniNacinPlacanja} ${shippingDetails.PaymentMethod}.` : props.language.PostaviteZadaniNacinPlacanja}</p>
                            <p style={{ textAlign: 'left', marginTop: '10px' }}>{shippingDetails.PaymentMethod ? props.language.Izmijenite : ''}</p>
                            <div className='route__userPanel__body__payment__box__dropdown' onClick={() => dropdownHandler()}>
                                <p>{dropdownSelected === null ? props.language.Izaberite : dropdownSelected} <img src='images/ddicn.svg' /></p>
                                <div style={{ display: dropdown ? 'flex' : 'none' }} className='route__userPanel__body__payment__box__dropdown__body'>
                                    <p onClick={() => { setDropdownSelected('Mastercard') }}>Mastercard</p>
                                    <p onClick={() => { setDropdownSelected('Visa') }}>Visa</p>
                                    <p onClick={() => { setDropdownSelected('Payoneer') }}>Payoneer</p>
                                    <p onClick={() => { setDropdownSelected('Paypal') }}>Paypal</p>
                                    <p onClick={() => { setDropdownSelected('Plaćanje pouzećem') }}>Plaćanje pouzećem</p>
                                    <p onClick={() => { setDropdownSelected('Virman') }}>Virman</p>
                                </div>
                            </div>
                            <h2>{props.language.PodaciNaplate}</h2>
                            {
                                shippingDetails.BillingDetails ?
                                    Object.keys(JSON.parse(shippingDetails.BillingDetails)).length < 1 ?
                                        <div className='route__userPanel__body__payment__box__billingDetails'>
                                            <div>
                                                <p>{props.language.ImeIprezime}</p>
                                                <input type='text' ref={billingNameRef} />
                                            </div>
                                            <div>
                                                <p>{props.language.BrojKuce}</p>
                                                <input type='text' ref={billingHouseNumberRef} />
                                            </div>
                                            <div>
                                                <p>{props.language.Grad}</p>
                                                <input type='text' ref={billingCityRef} />
                                            </div>
                                            <div>
                                                <p>{props.language.Ulica}</p>
                                                <input type='text' ref={billingStreetRef} />
                                            </div>
                                            <div>
                                                <p>{props.language.PostanskiBroj}</p>
                                                <input type='text' ref={billingZIPref} />
                                            </div>
                                            <div>
                                                <p>{props.language.BrojTelefona}</p>
                                                <input type='text' ref={billingPhoneNumberRef} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                                <p>{props.language.NazivKompanije}</p>
                                                <input type='text' ref={companyNameRef} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                                <p>{props.language.AdresaKompanije}</p>
                                                <input type='text' ref={companyAddressRef} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                                <p>{props.language.IDbroj}</p>
                                                <input type='text' ref={companyIDnumberRef} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                                <p>{props.language.PDVbroj}</p>
                                                <input type='text' ref={companyPDVnumberRef} />
                                            </div>
                                        </div>
                                        :
                                        <div className='route__userPanel__body__payment__box__billingDetailsPreview'>
                                            <div>
                                                <p>{props.language.ImeIprezime}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).Name}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newBillingNameRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).Name} />
                                            </div>
                                            <div>
                                                <p>{props.language.BrojKuce}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).HouseNumber}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newBillingHouseNumberRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).HouseNumber} />
                                            </div>
                                            <div>
                                                <p>{props.language.Grad}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).City}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newBillingCityRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).City} />
                                            </div>
                                            <div>
                                                <p>{props.language.Ulica}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).Street}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newBillingStreetRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).Street} />
                                            </div>
                                            <div>
                                                <p>{props.language.PostanskiBroj}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).ZIP}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newBillingZIPref} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).ZIP} />
                                            </div>
                                            <div>
                                                <p>{props.language.BrojTelefona}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).PhoneNumber}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newBillingPhoneNumberRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).PhoneNumber} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? '' : 'none' }}>
                                                <p>{props.language.NazivKompanije}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).CompanyName}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newCompanyNameRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).CompanyName} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? '' : 'none' }}>
                                                <p>{props.language.AdresaKompanije}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).CompanyAddress}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newCompanyAddressRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).CompanyAddress} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? '' : 'none' }}>
                                                <p>{props.language.IDbroj}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).CompanyIDnumber}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newCompanyIDnumberRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).CompanyIDnumber} />
                                            </div>
                                            <div style={{ display: dropdownSelected === 'Virman' ? '' : 'none' }}>
                                                <p>{props.language.PDVbroj}</p>
                                                <strong style={{ display: !editPayment ? 'block' : 'none' }}>{JSON.parse(shippingDetails.BillingDetails).CompanyPDVnumber}</strong>
                                                <input style={{ display: editPayment ? 'block' : 'none' }} ref={newCompanyPDVnumberRef} type='text' defaultValue={JSON.parse(shippingDetails.BillingDetails).CompanyPDVnumber} />
                                            </div>
                                        </div>
                                    : ''
                            }
                            <p ref={paymentMsgRef} id='paymentMsg'></p>
                        </div>
                    </div>}
                    {activePage === 'orders' && <div style={{ display: activePage === 'orders' ? 'block' : 'none' }} className='route__userPanel__body__orders'>
                        <div className='route__userPanel__body__orders__header'>
                            <h2>{props.language.VaseNarudzbe}</h2>
                        </div>
                        <br></br>
                        <FilteredCustomTable
                            id='table'
                            accent='#0458AD'
                            theme='light'
                            headers={['ID', props.language.DatumKreiranja, props.language.Popust, props.language.CijenaMalim, props.language.NacinPlacanja]}
                            filters={[
                                { name: "ID", friendlyName: "ID", type: "number" },
                            ]}
                            filterCB={f => setFilters(f)}
                            data={userOrders.data?.length > 0 ? addOrdersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                        />
                    </div>}
                    {activePage === 'settings' && <div style={{ display: activePage === 'settings' ? 'block' : 'none' }} className='route__userPanel__body__profileSettings'>
                        <div className='route__userPanel__body__profileSettings__box'>
                            <span><Button accent='#001B57' value={editUser ? props.language.Sacuvaj : props.language.Uredi} className='settingsBtn' onClick={() => editUserHandler()} /></span>
                            <br></br>
                            <div className='route__userPanel__body__profileSettings__box__gridbox'>
                                <div>
                                    <span>
                                        <img src='images/moj profil.png' alt='' />
                                        <strong style={{ display: editUser ? 'none' : 'block' }} >{curUser && curUserData.data?.Name}</strong>
                                        <input ref={newNameRef} style={{ display: editUser ? 'block' : 'none' }} type='text' defaultValue={curUser && curUserData.data?.Name} />
                                    </span>
                                    <span>
                                        <p style={{ display: editUser ? 'none' : 'block' }}>{curUser && curUserData.data?.Email}</p>
                                        <input id='secondInput' ref={newEmailRef} style={{ display: editUser ? 'block' : 'none' }} type='text' defaultValue={curUser && curUserData.data?.Email} />
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <img src='images/mydata.png' alt='' />
                                        <strong style={{ display: editUser ? 'none' : 'block' }}>{curUser && curUserData.data?.Username}</strong>
                                        <input ref={newUsernameRef} style={{ display: editUser ? 'block' : 'none' }} type='text' defaultValue={curUser && curUserData.data?.Username} />
                                    </span>
                                </div>
                            </div>
                            <p ref={editMsgRef}></p>
                        </div>
                    </div>}
                    {activePage === 'changepassword' && <div style={{ display: activePage === 'changepassword' ? 'block' : 'none' }} className='route__userPanel__body__changePassword'>
                        <div>
                            <span>
                                <p>{props.language.NovaLozinka}</p>
                                <input ref={newPasswordRef} type='password' placeholder={props.language.NovaLozinka} />
                            </span>
                            <span>
                                <p>{props.language.TrenutnaLozinka}</p>
                                <input ref={oldPasswordRef} type='password' placeholder={props.language.TrenutnaLozinka} />
                                <Button value={props.language.SpremiLozinku} className='savePassBtn' accent='rgb(4, 88, 173)' onClick={() => savePassword()} />
                                <p ref={passMsgRef} id='passMsg'></p>
                            </span>
                        </div>
                    </div>}
                </div>
            </div>
            <Footer language={props.language} />
        </>
    )
}
