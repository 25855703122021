import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import * as backendModule from "../../modules/backendModule";
import axios from "axios";

export default function Footer(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const nameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const messageRef = React.useRef(null);

    const [messageSent, setMessageSent] = React.useState(false);
    const msgRef = React.useRef(null);

    const sendMessage = () => {

        if (isNaN(phoneRef.current.value)) {
            return;
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/messages/addMessage`,
            data: {
                Name: nameRef.current.value,
                PhoneNumber: phoneRef.current.value,
                Country: props.location
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setMessageSent(true);
                setTimeout(() => {
                    setMessageSent(false);
                    nameRef.current.value = '';
                    phoneRef.current.value = '';
                }, 2000);
            }
            else {
                setMessageSent(false);
                msgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                msgRef.current.style.textAlign = 'center';
            }
        }).catch(() => {

        });
    }

    const lastEmailRef = React.useRef(null);
    const [subscribed, setSubscribed] = React.useState(false);
    const subscribe = () => {

        if (!lastEmailRef.current.value) {
            return;
        }

        if (!lastEmailRef.current.value.includes('@')) {
            return;
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/emails/addEmail`,
            data: {
                Email: lastEmailRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                lastEmailRef.current.value = '';
                setSubscribed(true);
                setTimeout(() => {
                    setSubscribed(false);
                }, 2500);
            }
            else {

            }
        }).catch(() => {

        });
    }


    const navigate = (path, pathENG, pathSI) => {
        if (
            props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR'
        ) {
            animateNavigate(path);
        }
        else if (props.location === 'SI') {
            animateNavigate(pathSI);
        }
        else {
            animateNavigate(pathENG);
        }
    }

    return (
        <div className='component__footer'>
            <div className='component__footer__contact'>
                <div style={{ display: messageSent ? 'block' : 'none' }} id='mess-sent'>
                    <h2>{props.language.PorukaUspjesnoPoslana}</h2>
                </div>
                <p>
                    {props.language.NakonStoPopunite}
                </p>
                <input ref={nameRef} type='text' placeholder={props.language.ImeIprezime} />
                <input ref={phoneRef} type='text' placeholder={props.language.BrojTelefona} />
                <Button className='callMeBtn' value={props.language.NazoviMe} accent='#0458AD' onClick={() => sendMessage()} />
            </div>
            <div className='component__footer__main'>
                <div className='component__footer__main__left'>
                    <img src='images/logo.svg' alt='' />
                    <div className='component__footer__main__left__gridbox'>

                        <div>
                            <p onClick={() => navigate('/', '/', '/')}>{props.language.Pocetna}</p>
                            <p onClick={() => navigate('/o-nama', '/aboutus', '/onas')}>{props.language.Onama}</p>
                            <p onClick={() => navigate('/katalog', '/catalog', '/katalog')}>{props.language.Katalog}</p>
                        </div>
                        <div>
                            <p onClick={() => navigate('/kontakt', '/contactus', '/stik')}>{props.language.KontaktirajteNas}</p>
                            <p onClick={() => navigate('/zasto-prirodno', '/whynatural', '/zakaj-naravno')}>{props.language.ZastoPrirodno}</p>
                            <p onClick={() => navigate('/uslovi-koristenja', '/terms-of-service', '/pogoji-uporabe')}>{props.language.UsloviKoristenja}</p>
                        </div>
                    </div>
                    <p id='copyright'>{props.language.Copyright}</p>
                </div>
                <div className='component__footer__main__right'>
                    <p>{props.language.DrustveneMreze}</p>
                    <span>
                        <img src='images/fb.png' alt='' />
                        <img src='images/twet.png' alt='' />
                        <img src='images/instagram.png' alt='' />
                    </span>
                    <p>{props.language.PrimanjeNovosti}</p>
                    <div className='component__footer__main__right__subscribe'>
                        <input type='text' placeholder={props.language.Email} ref={lastEmailRef} />
                        <Button className='subBtn' value={props.language.PretplatiSe} accent='#0458AD' onClick={() => subscribe()} />
                    </div>
                    <p style={{ display: subscribed ? 'block' : 'none', color: 'green', fontSize: '13px', marginTop: '10px' }}>Uspješno ste se pretplatili</p>
                    <h4>{props.language.Prihvatamo}</h4>
                    <div className='component__footer__main__right__cards'>
                        <img src='images/MasterCard.png' alt='' />
                        <img src='images/PayPal.png' alt='' />
                        <img src='images/Western Union.png' alt='' />
                        <img src='images/Payoneer.png' alt='' />
                        <img src='images/Bank transfer.png' alt='' />
                        <img src='images/Bank transfer (1).png' alt='' />
                    </div>
                    <div className='component__footer__main__right__distributors'>
                        <br></br>
                        <p >{props.language.Distributer} Obscale d.o.o. Srebrenik</p>
                        <p >{props.language.DistributerRS} Spark Natura d.o.o. Loznica</p>
                        <p >{props.language.DistributerHR} Natura Lifted d.o.o. Županj</p>
                    </div>
                </div>
                <p id='copyrightmobile' style={{ color: '#5E6366' }}>{props.language.Copyright}</p>
            </div>
        </div>
    )
}
