import React from 'react';
import "./index.scss";
import Footer from "../../components/Footer";
import Button from '../../components/customComponents/Button';
import * as backendModule from "../../modules/backendModule";
import axios from "axios";

export default function ContactUs(props) {

    const nameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const messageRef = React.useRef(null);

    const [messageSent, setMessageSent] = React.useState(false);
    const msgRef = React.useRef(null);

    const sendMessage = () => {

        if(
            !nameRef.current.value ||
            !emailRef.current.value ||
            !phoneRef.current.value ||
            !messageRef.current.value
        ){
            msgRef.current.innerText = 'Sva polja moraju biti popunjena!';
            msgRef.current.style.textAlign = 'center';
            return setTimeout(() => {
                msgRef.current.innerText = '';
            }, 2000);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/messages/addMessage`,
            data: {
                Name: nameRef.current.value,
                Email: emailRef.current.value,
                PhoneNumber: phoneRef.current.value,
                Text: messageRef.current.value,
                Country: props.location
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setMessageSent(true);
                setTimeout(() => {
                    setMessageSent(false);
                    nameRef.current.value = '';
                    emailRef.current.value = '';
                    phoneRef.current.value = '';
                    messageRef.current.value = '';
                }, 2000);
            }
            else{
                setMessageSent(false);
                msgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                msgRef.current.style.textAlign = 'center';
                setTimeout(() => {
                    msgRef.current.innerText = '';
                }, 2000);
            }
        }).catch(() => {
            setMessageSent(false);
            msgRef.current.innerText = 'Server error!';
            msgRef.current.style.textAlign = 'center';
            setTimeout(() => {
                msgRef.current.innerText = '';
            }, 2000);
        });
    }

    return (
        <>
            <div className='route__contactUs'>
                <h1>{props.language.KontaktForma}</h1>
                <div className='route__contactUs__gridbox'>
                    <div className='route__contactUs__gridbox__info'>
                        <div id='info'>
                            <div>
                                <img src='images/loc.png' alt='' />
                                <span>
                                    <strong>{props.language.Adresa}</strong>
                                    <p>Ćehaje bb, Srebrenik</p>
                                </span>
                            </div>
                            <div>
                                <img src='images/clock.png' alt='' />
                                <span>
                                    <strong>{props.language.RadnoVrijeme}</strong>
                                    <p>Ponedjeljak - Petak <br></br> 08:00h - 16:00h</p>
                                </span>
                            </div>
                            <div>
                                <img src='images/phone.png' alt='' />
                                <span>
                                    <strong>{props.language.BrojTelefona}</strong>
                                    <p>{props.language.CCphoneNumber}</p>
                                </span>
                            </div>
                            <div>
                                <img src='images/email.png' alt='' />
                                <span>
                                    <strong>{props.language.Email}</strong>
                                    <p>info@obscale.com</p>
                                </span>
                            </div>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1545.8726916479686!2d18.489660092972887!3d44.716693829635496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sba!4v1671803262271!5m2!1sen!2sba" width="100%" height="350" style={{marginTop: '20px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div id='form'>
                        <div style={{display: messageSent ? 'block' : 'none'}} id='message-sent'>
                            <h2>{props.language.PorukaUspjesnoPoslana}</h2>
                        </div>
                        <p>{props.language.Ime}</p>
                        <input type='text' placeholder='Ime i prezime' ref={nameRef} />
                        <p>{props.language.Email}</p>
                        <input type='text' placeholder='Email' ref={emailRef} />
                        <p>{props.language.Telefon} </p>
                        <input type='text' placeholder='Broj telefona' ref={phoneRef} />
                        <p>{props.language.Poruka}</p>
                        <textarea ref={messageRef} />
                        <Button className='sendBtn' value={props.language.KontaktirajteNas} accent='#0458AD' onClick={() => sendMessage()} />
                        <p ref={msgRef}></p>
                    </div>
                </div>
            </div>
            <Footer location={props.location} language={props.language} />
        </>
    )
}
