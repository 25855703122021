import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import { useLocation } from "react-router-dom"

import Button from '../../components/customComponents/Button';
import Footer from '../../components/Footer';

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import moment from 'moment';
import Spinner from '../../components/customComponents/Spinner';

export default function ShoppingCart(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [currency, setCurrency] = React.useState(props.location === 'BA' ? 'BAM ' : props.location === 'RS' ? 'RSD ' : props.location === 'HR' ? 'EUR ' : 'EUR ')
    const [PDVpercent, setPDVpercent] = React.useState(props.location === 'BA' ? 1.17 : props.location === 'RS' ? 1.20 : props.location === 'HR' ? 1.25 : props.location === 'SI' ? 1.22 : 0);

    const [isLogged, setIsLogged] = React.useState(false);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setIsLogged(true);
            }
            else {
                setIsLogged(false);
            }
        }).catch(() => {

        });
    }, []);

    const [localStorageShippingData, setLocalStorageShippingData] = React.useState([]);
    const [localStorageBillingData, setLocalStorageBillingData] = React.useState([]);
    React.useEffect(() => {
        if (localStorage.getItem('bioplarGuestShippingData') !== 'undefined') {
            setLocalStorageShippingData(JSON.parse(localStorage.getItem('bioplarGuestShippingData')));
        }
        if (localStorage.getItem('bioplarGuestBillingData') !== 'undefined') {
            setLocalStorageBillingData(JSON.parse(localStorage.getItem('bioplarGuestBillingData')));
        }
    }, []);

    const [loaded, setLoaded] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [allProducts, setAllProducts] = React.useState([]);

    const getAllProducts = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/products/getAllProducts`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                //res.data.data = 0;
                setAllProducts(res.data);
                setLoaded(true);
            };
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getAllProducts();
    }, []);

    const [cartItems, setCartItems] = React.useState([]);

    React.useEffect(() => {
        let tmp = [];
        if (allProducts.data) {
            for (let i = 0; i < allProducts.data.length; i++) {
                for (let j = 0; j < props.products.length; j++) {
                    if (allProducts.data[i].ID === props.products[j]) {
                        tmp.push({
                            ProductName: allProducts.data[i].ProductName,
                            Description: allProducts.data[i].Description,
                            CurrentPrice: props.location === 'BA' ? allProducts.data[i].CurrentPrice : props.location === 'HR' ? allProducts.data[i].CurrentPriceHR : props.location === 'RS' ? allProducts.data[i].CurrentPriceRS : allProducts.data[i].CurrentPriceSI,
                            ImageURL: allProducts.data[i].ImageURL,
                            Quantity: 1,
                            ID: allProducts.data[i].ID,
                            Discount: JSON.parse(allProducts.data[i].Discount),
                        });
                    }
                }
            }
        }
        setCartItems(tmp);
    }, [allProducts]);

    const [cartItemsPP, setCartItemsPP] = React.useState([]);

    React.useEffect(() => {
        if (props.productsPP.length > 0) {
            let tmp = [];
            if (allProducts.data) {
                for (let i = 0; i < allProducts.data.length; i++) {
                    for (let j = 0; j < props.productsPP.length; j++) {
                        if (allProducts.data[i].ID === props.productsPP[j].ID) {
                            tmp.push({
                                ProductName: allProducts.data[i].ProductName,
                                Description: allProducts.data[i].Description,
                                CurrentPrice: props.location === 'BA' ? allProducts.data[i].CurrentPrice : props.location === 'HR' ? allProducts.data[i].CurrentPriceHR : props.location === 'RS' ? allProducts.data[i].CurrentPriceRS : allProducts.data[i].CurrentPriceSI,
                                ImageURL: allProducts.data[i].ImageURL,
                                Quantity: props.productsPP[j].Quantity,
                                ID: allProducts.data[i].ID,
                                Discount: JSON.parse(allProducts.data[i].Discount),
                            });
                        }
                    }
                }
            }
            setCartItemsPP(tmp);
        }
    }, [allProducts]);

    const [qDiscount, setQdiscount] = React.useState(false);
    const [qDiscountPP, setQdiscountPP] = React.useState(false);
    React.useEffect(() => {
        cartItems.map((item) => {
            if (item.Discount.Value) {
                setQdiscount(true);
            }
        })
        cartItemsPP.map((item) => {
            if (item.Discount.Value) {
                setQdiscountPP(true);
            }
        })
    }, [cartItems, cartItemsPP]);

    const QuantityField = (props) => {
        const [counter, setCounter] = React.useState(props.quantity);

        const decrease = () => {
            if (counter > 1) {
                cartItems.map((item) => {
                    if (item.ID === props.id) {
                        item.Quantity = item.Quantity - 1;
                        setCounter(item.Quantity);
                        props.sum();
                    }
                });
                cartItemsPP.map((item) => {
                    if (item.ID === props.id) {
                        item.Quantity = item.Quantity - 1;
                        setCounter(item.Quantity);
                        props.sum();
                    }
                });
                if (generatedOrderProducts[0]) {
                    genProduct.map((item) => {
                        if (item.ProductID === props.id) {
                            item.Quantity = Number(item.Quantity) - 1;
                            setCounter(item.Quantity);
                            props.sum();
                        }
                    })
                }
            }
        }

        const increase = () => {
            cartItems.map((item) => {
                if (item.ID === props.id) {
                    item.Quantity = item.Quantity + 1;
                    setCounter(item.Quantity);
                    props.sum();
                }
            });
            cartItemsPP.map((item) => {
                if (item.ID === props.id) {
                    item.Quantity = item.Quantity + 1;
                    setCounter(item.Quantity);
                    props.sum();
                }
            });
            if (generatedOrderProducts[0]) {
                genProduct.map((item) => {
                    if (item.ProductID === props.id) {
                        item.Quantity = Number(item.Quantity) + 1;
                        setCounter(item.Quantity);
                        props.sum();
                    }
                })
            }
        }

        return (
            <div id='quantity'><strong onClick={() => decrease()}>-</strong><p>{counter}</p><strong onClick={() => increase()}>+</strong></div>
        )
    }

    const [totalWithoutPDV, setTotalWithoutPDV] = React.useState(0);
    const [totalWithDiscount, setTotalWithDiscount] = React.useState(0);
    const [total, setTotal] = React.useState(0);

    const sum = () => {
        let tmp = 0;
        let tmpPP = 0;
        let tmpTotal = 0;
        let tmpTotalPP = 0;
        let tmpTotalD = 0;
        let tmpTotalDPP = 0;
        cartItems.map((item) => {
            if (qDiscount && item.Quantity >= item.Discount.Quantity) {
                tmp = tmp + (((Number(item.CurrentPrice) * Number(item.Discount.Value) / 100) * Number(item.Quantity)) / PDVpercent);
            }
            else {
                tmp = tmp + ((Number(item.CurrentPrice) * Number(item.Quantity)) / PDVpercent);
            }
            if (discount === null) {
                tmpTotal = tmpTotal + (Number(item.CurrentPrice) * Number(item.Quantity)) - (qDiscount && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
            }
            else {
                if (discountOption === '%') {
                    tmpTotal = tmpTotal + (Number(item.CurrentPrice) * Number(item.Quantity)) - (qDiscount && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                    tmpTotalD = (tmpTotalD + (Number(item.CurrentPrice) * Number(item.Quantity))) - (qDiscount && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                }
                else {
                    tmpTotal = tmpTotal + (Number(item.CurrentPrice) * Number(item.Quantity)) - (qDiscount && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                    tmpTotalD = (tmpTotalD + (Number(item.CurrentPrice) * Number(item.Quantity))) - (qDiscount && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                }
            }
        });
        cartItemsPP.map((item) => {
            if (qDiscountPP && item.Quantity >= item.Discount.Quantity) {
                tmpPP = tmpPP + (((Number(item.CurrentPrice) * Number(item.Discount.Value) / 100) * Number(item.Quantity)) / PDVpercent);
            }
            else {
                tmpPP = tmpPP + ((Number(item.CurrentPrice) * Number(item.Quantity)) / PDVpercent);
            }
            if (discount === null) {
                tmpTotal = tmpTotal + (Number(item.CurrentPrice) * Number(item.Quantity)) - (qDiscountPP && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
            }
            else {
                if (discountOption === '%') {
                    tmpTotal = tmpTotal + (Number(item.CurrentPrice) * Number(item.Quantity)) - (qDiscountPP && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                    tmpTotalD = (tmpTotalD + (Number(item.CurrentPrice) * Number(item.Quantity))) - (qDiscountPP && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                }
                else {
                    tmpTotal = tmpTotal + (Number(item.CurrentPrice) * Number(item.Quantity)) - (qDiscountPP && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                    tmpTotalD = (tmpTotalD + (Number(item.CurrentPrice) * Number(item.Quantity))) - (qDiscountPP && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0);
                }
            }
        });
        if (generatedOrderProducts[0]) {
            genProduct.map((item) => {
                switch (generatedOrder.data?.Country) {
                    case 'Bosna i Hercegovina':
                        item.Price = item.Price;
                        break;
                    case 'Srbija':
                        item.Price = item.PriceRS;
                        break;
                    case 'Hrvatska':
                        item.Price = item.PriceHR;
                        break;
                    case 'Slovenija':
                        item.Price = item.PriceSI;
                        break;
                    default: item.Price = item.PriceSI;
                }
                tmp = tmp + ((Number(item.Price / item.Quantity) * Number(item.Quantity)) / PDVpercent);
                if (discount === null) {
                    tmpTotal = tmpTotal + (Number(item.Price / item.Quantity) * Number(item.Quantity));
                }
                else {
                    if (discountOption === '%') {
                        tmpTotal = tmpTotal + (Number(item.Price) * Number(item.Quantity));
                        tmpTotalD = (tmpTotalD + (Number(item.Price) * Number(item.Quantity)));
                    }
                    else {
                        tmpTotal = tmpTotal + (Number(item.Price) * Number(item.Quantity));
                        tmpTotalD = (tmpTotalD + (Number(item.Price) * Number(item.Quantity)));
                    }
                }
            });
        }
        setTotalWithoutPDV((tmp + tmpPP).toFixed(2));
        setTotal((tmpTotal + tmpTotalPP).toFixed(2));
        setTotalWithDiscount((discountOption === '%' ? (tmpTotalD + tmpTotalDPP) - ((tmpTotalD + tmpTotalDPP) * discount / 100) : (tmpTotalD + tmpTotalDPP) - discount).toFixed(2));
    }

    const [PDVvalue, setPDVvalue] = React.useState(0);

    const innerPDV = () => {
        let tmp = 0;
        tmp = total - totalWithoutPDV;
        setPDVvalue(tmp.toFixed(2));
    }

    const deleteProduct = (id) => {
        setCartItems(i => i.filter((_, idx) => idx !== id));
        props.removeFromCart(id);
    }
    const deleteProductPP = (id) => {
        setCartItemsPP(i => i.filter((_, idx) => idx !== id));
        props.removeFromCartPP(id);
    }

    const couponRef = React.useRef(null);
    const [discount, setDiscount] = React.useState(null);
    const [discountOption, setDiscountOption] = React.useState(null);
    const [noCoupon, setNoCoupon] = React.useState(true);
    const [couponCode, setCouponCode] = React.useState(null);
    const msgRef = React.useRef(null);

    const applyCoupon = () => {
        if (total > 0) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/coupons/verifyCoupon`,
                data: {
                    Code: couponRef.current.value
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    if (res.data.data.CouponOption === 'Currency' && total > Number(res.data.data.CouponValue)) {
                        if (moment(res.data.data.Expires).isAfter(moment())) {
                            switch (props.location) {
                                case 'BA':
                                    setDiscount(res.data.data.CouponValue);
                                    break;
                                case 'RS':
                                    setDiscount(res.data.data.CouponValueRS);
                                    break;
                                case 'HR':
                                    setDiscount(res.data.data.CouponValueHR);
                                    break;
                                case 'SI':
                                    setDiscount(res.data.data.CouponValueSI);
                                    break;
                                default: setDiscount(res.data.data.CouponValueSI);
                            }
                            setDiscountOption(res.data.data.CouponOption);
                            setNoCoupon(false);
                            setCouponCode(res.data.data.CouponCode);
                        }
                        else {
                            msgRef.current.innerText = 'Uneseni kod je istekao...';
                            setTimeout(() => {
                                msgRef.current.innerText = '';
                            }, [3000]);
                        }
                    }
                    else if (res.data.data.CouponOption === '%' && total > 0) {
                        if (moment(res.data.data.Expires).isAfter(moment())) {
                            switch (props.location) {
                                case 'BA':
                                    setDiscount(res.data.data.CouponValue);
                                    break;
                                case 'RS':
                                    setDiscount(res.data.data.CouponValueRS);
                                    break;
                                case 'HR':
                                    setDiscount(res.data.data.CouponValueHR);
                                    break;
                                case 'SI':
                                    setDiscount(res.data.data.CouponValueSI);
                                    break;
                                default: setDiscount(res.data.data.CouponValueSI);
                            }
                            setDiscountOption(res.data.data.CouponOption);
                            setNoCoupon(false);
                            setCouponCode(res.data.data.CouponCode);
                        }
                        else {
                            msgRef.current.innerText = 'Uneseni kod je istekao...';
                            setTimeout(() => {
                                msgRef.current.innerText = '';
                            }, [3000]);
                        }
                    }
                } else {
                    msgRef.current.innerText = 'Uneseni kod je nevažeći...';
                    setTimeout(() => {
                        msgRef.current.innerText = '';
                    }, [3000]);
                }
            }).catch(() => {

            });
        }
    }

    const [shippingView, setShippingView] = React.useState(false);
    const continueToShipping = () => {
        setShippingView(s => !s);
    }

    const customerNameRef = React.useRef(null);
    const customerHouseNumberRef = React.useRef(null);
    const customerStreetRef = React.useRef(null);
    const customerZIPref = React.useRef(null);
    const customerCityRef = React.useRef(null);
    const customerPhoneRef = React.useRef(null);
    const loggedCustomerNameRef = React.useRef(null);
    const loggedCustomerHouseNumberRef = React.useRef(null);
    const loggedCustomerStreetRef = React.useRef(null);
    const loggedCustomerZIPref = React.useRef(null);
    const loggedCustomerCityRef = React.useRef(null);
    const loggedCustomerPhoneRef = React.useRef(null);
    const errorMsgRef = React.useRef(null);

    const [shippingDetails, setShippingDetails] = React.useState();
    const [paymentView, setPaymentView] = React.useState(false);
    const continueToPayment = () => {
        if (Object.keys(shippingData).length === 0) {
            if (
                !customerNameRef.current.value ||
                !customerHouseNumberRef.current.value ||
                !customerCityRef.current.value ||
                !customerStreetRef.current.value ||
                !customerZIPref.current.value ||
                !customerPhoneRef.current.value
            ) {
                errorMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
            }
            else {
                let details = [
                    {
                        Name: customerNameRef.current.value,
                        HouseNumber: customerHouseNumberRef.current.value,
                        City: customerCityRef.current.value,
                        Street: customerStreetRef.current.value,
                        ZIP: customerZIPref.current.value,
                        PhoneNumber: customerPhoneRef.current.value
                    }
                ];
                setShippingDetails(details);
                setPaymentView(p => !p);
            }
        }
        else {
            if (
                !loggedCustomerNameRef.current.value ||
                !loggedCustomerHouseNumberRef.current.value ||
                !loggedCustomerCityRef.current.value ||
                !loggedCustomerStreetRef.current.value ||
                !loggedCustomerZIPref.current.value ||
                !loggedCustomerPhoneRef.current.value
            ) {
                errorMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
            }
            else {
                setPaymentView(p => !p);
            }
        }
    }

    const [shippingData, setShippingData] = React.useState([]);
    const getUserData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getCurUserShippingDetails`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let counter = 0;
                Object.values(res.data.data).map((item) => {
                    if (item === '') {
                        counter++;
                    }
                });
                if (counter <= 1) {
                    setShippingData(res.data.data);
                    setDropdownSelected(res.data.data.PaymentMethod);
                }
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getUserData();
    }, []);

    const [dropdown, setDropdown] = React.useState(false);
    const [dropdownSelected, setDropdownSelected] = React.useState('Mastercard');

    const dropdownHandler = () => {
        setDropdown(d => !d);
    }

    const selectPaymentMethod = (method) => {
        setDropdownSelected(method);
        setDropdown(true);
    }

    const [filters, setFilters] = React.useState([]);

    const billingNameRef = React.useRef(null);
    const billingCityRef = React.useRef(null);
    const billingHouseNumberRef = React.useRef(null);
    const billingPhoneNumberRef = React.useRef(null);
    const billingStreetRef = React.useRef(null);
    const billingZIPRef = React.useRef(null);

    const remarkRef = React.useRef(null);

    const companyNameRef = React.useRef(null);
    const companyAddressRef = React.useRef(null);
    const companyIDnumRef = React.useRef(null);
    const companyPDVnumRef = React.useRef(null);

    const cardHolderRef = React.useRef(null);
    const cardNumberRef = React.useRef(null);
    const cardExpiryMonthRef = React.useRef(null);
    const cardExpiryYearRef = React.useRef(null);
    const cardCVVref = React.useRef(null);
    const cardNameRef = React.useRef(null);
    const cardLastNameRef = React.useRef(null);
    const cardCityRef = React.useRef(null);
    const cardStreetRef = React.useRef(null);
    const cardZIPref = React.useRef(null);
    const cardEmialRef = React.useRef(null);
    const cardPhoneNumberRef = React.useRef(null);

    const errorPaymentMethodMsgRef = React.useRef(null);

    const [paymentSuccess, setPaymentSuccess] = React.useState(false);
    const processPayment = () => {
        if (dropdownSelected === 'Plaćanje pouzećem') {
            let products = [];
            cartItems.map((item) => {
                products.push({ ProductName: item.ProductName, Quantity: item.Quantity, ProductID: item.ID });
            });
            cartItemsPP.map((item) => {
                products.push({ ProductName: item.ProductName, Quantity: item.Quantity, ProductID: item.ID });
            });
            if (generatedOrderProducts[0]) {
                genProduct.map((item) => {
                    products.push({ ProductName: item.Product, Quantity: item.Quantity, ProductID: item.ID });
                });
            }

            let billingData;
            let guestShippingData;
            let loggedShippingData;

            if (isLogged) {
                if (
                    !loggedCustomerCityRef.current.value ||
                    !loggedCustomerHouseNumberRef.current.value ||
                    !loggedCustomerNameRef.current.value ||
                    !loggedCustomerPhoneRef.current.value ||
                    !loggedCustomerZIPref.current.value
                ) {
                    if (
                        !customerCityRef.current.value ||
                        !customerHouseNumberRef.current.value ||
                        !customerNameRef.current.value ||
                        !customerStreetRef.current.value ||
                        !customerZIPref.current.value
                    ) {
                        return errorPaymentMethodMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                    }
                }

                loggedShippingData = {
                    City: loggedCustomerCityRef.current.value ? loggedCustomerCityRef.current.value : customerCityRef.current.value,
                    HouseNumber: loggedCustomerHouseNumberRef.current.value ? loggedCustomerHouseNumberRef.current.value : customerHouseNumberRef.current.value,
                    ShippingName: loggedCustomerNameRef.current.value ? loggedCustomerNameRef.current.value : customerNameRef.current.value,
                    Street: loggedCustomerStreetRef.current.value ? loggedCustomerStreetRef.current.value : customerStreetRef.current.value,
                    PhoneNumber: loggedCustomerPhoneRef.current.value ? loggedCustomerPhoneRef.current.value : customerPhoneRef.current.value,
                    ZIP: loggedCustomerZIPref.current.value ? loggedCustomerZIPref.current.value : customerZIPref.current.value
                }
            }
            else {
                if (
                    !customerCityRef.current.value ||
                    !customerHouseNumberRef.current.value ||
                    !customerNameRef.current.value ||
                    !customerStreetRef.current.value ||
                    !customerZIPref.current.value
                ) {
                    return errorPaymentMethodMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                }

                guestShippingData = {
                    City: customerCityRef.current.value,
                    HouseNumber: customerHouseNumberRef.current.value,
                    ShippingName: customerNameRef.current.value,
                    Street: customerStreetRef.current.value,
                    PhoneNumber: customerPhoneRef.current.value,
                    ZIP: customerZIPref.current.value
                }
            }

            billingData = {
                Name: billingNameRef.current.value,
                HouseNumber: billingHouseNumberRef.current.value,
                City: billingCityRef.current.value,
                ZIP: billingZIPRef.current.value,
                Street: billingStreetRef.current.value,
                PhoneNumber: billingPhoneNumberRef.current.value
            }

            localStorage.setItem('bioplarGuestShippingData', JSON.stringify(guestShippingData));
            localStorage.setItem('bioplarGuestBillingData', JSON.stringify(billingData));

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/orders/addOrder`,
                data: {
                    Products: products,
                    Country: props.location,
                    Price: discount === null ? total : totalWithDiscount,
                    Discount: discount,
                    DiscountOption: discountOption,
                    BillingData: billingData,
                    ShippingData: isLogged ? loggedShippingData : guestShippingData,
                    PaymentMethod: dropdownSelected,
                    Remark: remarkRef.current.value
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setPaymentSuccess(true);
                    setTimeout(() => {
                        setPaymentSuccess(false);
                        setShippingView(false);
                        setPaymentView(false);
                    }, 6000);
                }
                else {
                    setPaymentSuccess(false);
                }
            }).catch(() => {

            });
        }
        else if (dropdownSelected === 'Virman') {
            let products = [];
            cartItems.map((item) => {
                products.push({ ProductName: item.ProductName, Quantity: item.Quantity, ProductID: item.ID, CurrentPrice: item.CurrentPrice, Discount: item.Discount });
            });
            cartItemsPP.map((item) => {
                products.push({ ProductName: item.ProductName, Quantity: item.Quantity, ProductID: item.ID, CurrentPrice: item.CurrentPrice, Discount: item.Discount });
            });
            if (generatedOrderProducts[0]) {
                genProduct.map((item) => {
                    products.push({ ProductName: item.Product, Quantity: item.Quantity, ProductID: item.ID, CurrentPrice: item.CurrentPrice, Discount: item.Discount });
                });
            }

            let billingData;
            let guestShippingData;
            let loggedShippingData;

            if (isLogged) {
                if (
                    !companyNameRef.current.value ||
                    !companyIDnumRef.current.value ||
                    !companyAddressRef.current.value ||
                    !companyPDVnumRef.current.value
                ) {
                    return errorPaymentMethodMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                }
                loggedShippingData = {
                    City: loggedCustomerCityRef.current.value ? loggedCustomerCityRef.current.value : customerCityRef.current.value,
                    HouseNumber: loggedCustomerHouseNumberRef.current.value ? loggedCustomerHouseNumberRef.current.value : customerHouseNumberRef.current.value,
                    ShippingName: loggedCustomerNameRef.current.value ? loggedCustomerNameRef.current.value : customerNameRef.current.value,
                    Street: loggedCustomerStreetRef.current.value ? loggedCustomerStreetRef.current.value : customerStreetRef.current.value,
                    PhoneNumber: loggedCustomerPhoneRef.current.value ? loggedCustomerPhoneRef.current.value : customerPhoneRef.current.value,
                    ZIP: loggedCustomerZIPref.current.value ? loggedCustomerZIPref.current.value : customerZIPref.current.value
                }
            }
            else {
                if (
                    !companyNameRef.current.value ||
                    !companyIDnumRef.current.value ||
                    !companyAddressRef.current.value ||
                    !companyPDVnumRef.current.value ||
                    !customerCityRef.current.value ||
                    !customerHouseNumberRef.current.value ||
                    !customerNameRef.current.value ||
                    !customerStreetRef.current.value ||
                    !customerZIPref.current.value ||
                    !customerPhoneRef.current.value
                ) {
                    return errorPaymentMethodMsgRef.current.innerText = 'Sva polja moraju biti popunjena!';
                }
                guestShippingData = {
                    City: customerCityRef.current.value,
                    HouseNumber: customerHouseNumberRef.current.value,
                    ShippingName: customerNameRef.current.value,
                    Street: customerStreetRef.current.value,
                    PhoneNumber: customerPhoneRef.current.value,
                    ZIP: customerZIPref.current.value
                }
            }

            billingData = {
                Name: billingNameRef.current.value,
                HouseNumber: billingHouseNumberRef.current.value,
                City: billingCityRef.current.value,
                ZIP: billingZIPRef.current.value,
                Street: billingStreetRef.current.value,
                PhoneNumber: billingPhoneNumberRef.current.value,
                PDVnumber: companyPDVnumRef.current.value,
                IDnumber: companyIDnumRef.current.value,
                CompanyName: companyNameRef.current.value,
                CompanyAddress: companyAddressRef.current.value,
            }

            localStorage.setItem('bioplarGuestShippingData', JSON.stringify(guestShippingData ? guestShippingData : loggedShippingData));
            localStorage.setItem('bioplarGuestBillingData', JSON.stringify(billingData));
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/orders/addOrder`,
                data: {
                    Products: products,
                    Country: props.location,
                    Price: discount === null ? total : totalWithDiscount,
                    Discount: discount,
                    DiscountOption: discountOption,
                    BillingData: billingData,
                    ShippingData: isLogged ? loggedShippingData : guestShippingData,
                    PaymentMethod: dropdownSelected,
                    Remark: remarkRef.current.value
                },
                ...backendModule.axiosConfig
            }).then(order => {
                if (order.data.status === "ok") {
                    setPaymentSuccess(true);
                    axios({
                        method: 'POST',
                        url: `http://localhost:5001/api/invoices/createPDF`,
                        data: {
                            token: 'iRZJHmOJJ4Mb4OEo0aNI94gPklIX0q3IEg6je4BQcl2ZoYUIQGAxALXNyaGFaelyu7ZrtvBf1FwiXbmm4MXQAW2wbtxWcKA304vj',
                            InvoiceID: order.data.data,
                        },
                        responseType: "blob",
                    }).then(pdf => {
                        const href = URL.createObjectURL(pdf.data);
                        const link = document.createElement('a');

                        link.href = href;
                        link.setAttribute('download', 'file.pdf');

                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }).catch(() => {

                    }).finally(() => {
                        setPaymentSuccess(false);
                        setShippingView(false);
                        setPaymentView(false);
                    });
                }
                else {
                    setPaymentSuccess(false);
                }
            }).catch(() => {

            });
        }
        else if (dropdownSelected === 'Mastercard' || dropdownSelected === 'Visa') {

            let products = [];
            cartItems.map((item) => {
                products.push({ ProductName: item.ProductName, Quantity: item.Quantity, ProductID: item.ID });
            });
            cartItemsPP.map((item) => {
                products.push({ ProductName: item.ProductName, Quantity: item.Quantity, ProductID: item.ID });
            });
            if (generatedOrderProducts[0]) {
                genProduct.map((item) => {
                    products.push({ ProductName: item.Product, Quantity: item.Quantity, ProductID: item.ID });
                });
            }

            let billingData = {
                firstName: cardNameRef.current.value,
                lastName: cardLastNameRef.current.value,
                street: cardStreetRef.current.value,
                city: cardCityRef.current.value,
                zip: cardZIPref.current.value,
                countryCode: props.location
            };

            let contact = {
                email: cardEmialRef.current.value,
                phone: cardPhoneNumberRef.current.value
            };

            let browserInfo = {
                width: window.innerWidth,
                height: window.innerHeight,
                language: navigator.language,
                javaEnabled: navigator.javaEnabled,
                userAgent: navigator.userAgent,
                acceptHeader: 'application/json',
                depthColor: 24
            }

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/payment/processPayment`,
                data: {
                    CardNumber: cardNumberRef.current.value,
                    CardExpiryMonth: cardExpiryMonthRef.current.value,
                    CardExpiryYear: cardExpiryYearRef.current.value,
                    CardCVV: cardCVVref.current.value,
                    CardHolder: cardHolderRef.current.value,
                    BillingDetails: billingData,
                    PaymentCurrency: currency,
                    PaymentAmount: discount !== null ? totalWithDiscount : total,
                    Contact: contact,
                    BrowserInfo: browserInfo,
                    Products: products
                },
                ...backendModule.axiosConfig
            }).then(res => {
            }).catch(() => {

            });
        }
    }

    const location = useLocation();
    const [generatedOrder, setGeneratedOrder] = React.useState([]);
    const [generatedOrderProducts, setGeneratedOrderProducts] = React.useState([]);
    const [genProduct, setGenProduct] = React.useState([]);
    const [generated, setGenerated] = React.useState(false);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search)
        const generatedOrderID = params.get("orderID");

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/generatedOrders/getGeneratedOrderByID`,
            data: {
                ID: generatedOrderID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setGeneratedOrder(res.data);
                setGenProduct(JSON.parse(res.data.data.Products));
                if (Object.keys(res.data.data).length > 0) {
                    setGenerated(true);
                    JSON.parse(res.data.data.Products).map((product) => {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/products/getProductByID`,
                            data: {
                                ID: product.ProductID
                            },
                            ...backendModule.axiosConfig
                        }).then(productres => {
                            if (productres.data.status === "ok") {
                                setGeneratedOrderProducts(p => [...p, [productres.data.data]]);
                            }
                            else {

                            }
                        }).catch(() => {

                        });
                    });
                }
            }
            else {

            }
        }).catch(() => {

        });
    }, []);

    React.useEffect(() => {
        sum();
        innerPDV();
    }, [cartItems, total, cartItemsPP, discount, genProduct, generatedOrderProducts]);

    return (
        <>
            <div className='route__shoppingCart'>
                <div className='route__shoppingCart__gridbox'>
                    <div style={{ display: paymentSuccess ? 'flex' : 'none' }} className='route__shoppingCart__gridbox__paymentProcessed'>
                        <h1>{props.language.UspjesnoSteNarucili}</h1>
                        <img src='images/payment.png' alt='' />
                        <p style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none', marginTop: '10px' }}>{props.language.PreuzimanjePDF}</p>
                        <span style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none', marginBottom: '10px' }}><Spinner width='16px' height='16px' /></span>
                        <Button className='shoppingBtn' value='NASTAVI KUPOVINU' accent='#0458AD' onClick={() => animateNavigate('/catalog')} />
                    </div>
                    <div style={{ display: !paymentView || paymentSuccess ? 'none' : '' }} className='route__shoppingCart__gridbox__payment'>
                        <span>
                            <strong>{props.language.Isporuka}</strong>
                            <div></div>
                            <img src='images/verified 1.png' alt='' />
                            <div></div>
                            <p>{props.language.Placanje}</p>
                        </span>
                        <h2>{props.language.NacinPlacanja}</h2>
                        <p>{props.language.NacinPlacanja}</p>
                        <div className='route__shoppingCart__gridbox__payment__dropdown' onClick={() => dropdownHandler()}>
                            <p>{dropdownSelected} <img src='images/ddicnb.svg' alt='' /></p>
                            <div style={{ display: dropdown ? 'flex' : 'none' }}>
                                <p onClick={() => selectPaymentMethod('Mastercard')}>Mastercard</p>
                                <p onClick={() => selectPaymentMethod('Visa')}>Visa</p>
                                <p onClick={() => selectPaymentMethod('Payoneer')}>Payoneer</p>
                                <p onClick={() => selectPaymentMethod('Paypal')}>Paypal</p>
                                <p onClick={() => selectPaymentMethod('Plaćanje pouzećem')}>Plaćanje pouzećem</p>
                                <p onClick={() => selectPaymentMethod('Virman')}>Virman</p>
                            </div>
                        </div>
                        <div style={{ display: dropdownSelected !== 'Mastercard' && dropdownSelected !== 'Visa' ? 'none' : '' }} className='route__shoppingCart__gridbox__payment__form'>
                            <div>
                                <p>{props.language.PunoIme}</p>
                                <input type='text' ref={cardHolderRef} />
                            </div>
                            <div>
                                <p>{props.language.BrojKartice}</p>
                                <input type='text' ref={cardNumberRef} />
                            </div>
                            <div>
                                <p>{props.language.DatumIsticanja}</p>
                                <span><input type='number' ref={cardExpiryMonthRef} /><input id='year' type='number' ref={cardExpiryYearRef} /></span>
                            </div>
                            <div>
                                <p>CVV/CVC kod</p>
                                <input type='text' ref={cardCVVref} />
                            </div>
                            <div>
                                <p>{props.language.ImeIprezime}</p>
                                <span><input type='text' ref={cardNameRef} /><input id='year' type='text' ref={cardLastNameRef} /></span>
                            </div>
                            <div>
                                <p>{props.language.BrojTelefona}</p>
                                <input type='text' ref={cardPhoneNumberRef} defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).PhoneNumber : localStorageBillingData ? localStorageBillingData.PhoneNumber : ''} />
                            </div>
                            <div>
                                <p>{props.language.Grad}</p>
                                <input ref={cardCityRef} type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).City : localStorageBillingData ? localStorageBillingData.City : ''} />
                            </div>
                            <div>
                                <p>{props.language.Ulica}</p>
                                <input type='text' ref={cardStreetRef} defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).Street : localStorageBillingData ? localStorageBillingData.Street : ''} />
                            </div>
                            <div>
                                <p>{props.language.ZIP}</p>
                                <input type='text' ref={cardZIPref} defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).ZIP : localStorageBillingData ? localStorageBillingData.ZIP : ''} />
                            </div>
                            <div>
                                <p>{props.language.Email}</p>
                                <input type='text' ref={cardEmialRef} />
                            </div>
                        </div>
                        <div style={{ display: dropdownSelected === 'Plaćanje pouzećem' || dropdownSelected === 'Virman' ? 'grid' : 'none' }} className='route__shoppingCart__gridbox__payment__form'>
                            <div>
                                <p>{props.language.ImeIprezime}</p>
                                <input ref={billingNameRef} placeholder='Ime i prezime' type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).Name : localStorageBillingData ? localStorageBillingData.Name : ''} />
                            </div>
                            <div>
                                <p>{props.language.BrojKuce}</p>
                                <input ref={billingHouseNumberRef} placeholder='Broj kuce' type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).HouseNumber : localStorageBillingData ? localStorageBillingData.HouseNumber : ''} />
                            </div>
                            <div>
                                <p>{props.language.Grad}</p>
                                <input ref={billingCityRef} placeholder='Grad' type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).City : localStorageBillingData ? localStorageBillingData.City : ''} />
                            </div>
                            <div>
                                <p>{props.language.Ulica}</p>
                                <input ref={billingStreetRef} placeholder='Ulica' type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).Street : localStorageBillingData ? localStorageBillingData.Street : ''} />
                            </div>
                            <div>
                                <p>{props.language.PostanskiBroj}</p>
                                <input ref={billingZIPRef} placeholder='Poštanski broj' type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).ZIP : localStorageBillingData ? localStorageBillingData.ZIP : ''} />
                            </div>
                            <div>
                                <p>{props.language.BrojTelefona}</p>
                                <input ref={billingPhoneNumberRef} placeholder='Broj telefona' type='text' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).PhoneNumber : localStorageBillingData ? localStorageBillingData.PhoneNumber : ''} />
                            </div>
                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                <p>{props.language.NazivKompanije}</p>
                                <input ref={companyNameRef} type='text' placeholder='Naziv kompanije' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).CompanyName : localStorageBillingData ? localStorageBillingData.CompanyName : ''} />
                            </div>
                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                <p>{props.language.AdresaKompanije}</p>
                                <input ref={companyAddressRef} type='text' placeholder='Adresa kompanije' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).CompanyAddress : localStorageBillingData ? localStorageBillingData.CompanyAddress : ''} />
                            </div>
                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                <p>{props.language.PDVbroj}</p>
                                <input ref={companyPDVnumRef} type='text' placeholder='PDV broj' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).CompanyPDVnumber : localStorageBillingData ? localStorageBillingData.PDVnumber : ''} />
                            </div>
                            <div style={{ display: dropdownSelected === 'Virman' ? 'block' : 'none' }}>
                                <p>{props.language.IDbroj}</p>
                                <input ref={companyIDnumRef} type='text' placeholder='ID broj' defaultValue={shippingData.BillingDetails ? JSON.parse(shippingData.BillingDetails).CompanyIDnumber : localStorageBillingData ? localStorageBillingData.IDnumber : ''} />
                            </div>
                        </div>
                        <p style={{ textAlign: 'center', marginBottom: '10px' }} >{props.language.Napomena}</p>
                        <textarea ref={remarkRef} />
                        <p style={{ display: dropdownSelected === 'Plaćanje pouzećem' ? 'block' : 'none', textAlign: 'center' }}>{props.language.PlatiPostaru}</p>
                        <p id='errmsg' ref={errorPaymentMethodMsgRef}></p>
                    </div>
                    <div style={{ display: !shippingView || paymentView ? 'none' : '' }} className='route__shoppingCart__gridbox__shippingDetails'>
                        <p ref={errorMsgRef} id='msg'></p>
                        <span>
                            <strong>{props.language.Isporuka}</strong>
                            <div></div>
                            <img src='images/verified 1.png' alt='' />
                            <div></div>
                            <p>{props.language.Placanje}</p>
                        </span>
                        <h2>{props.language.DetaljiIsporuke}</h2>
                        <div style={{ display: Object.keys(shippingData).length !== 0 ? 'none' : '' }} className='route__shoppingCart__gridbox__shippingDetails__form'>
                            <div>
                                <p>{props.language.ImeIprezime}</p>
                                <input ref={customerNameRef} type='text' placeholder='Vaše ime' defaultValue={localStorageShippingData ? localStorageShippingData.ShippingName : ''} />
                                <p>{props.language.BrojKuce}</p>
                                <input ref={customerHouseNumberRef} type='text' placeholder='46' defaultValue={localStorageShippingData ? localStorageShippingData.HouseNumber : ''} />
                                <p>{props.language.Grad}</p>
                                <input ref={customerCityRef} type='text' placeholder='Vaš grad' defaultValue={localStorageShippingData ? localStorageShippingData.City : ''} />
                            </div>
                            <div>
                                <p>{props.language.Ulica}</p>
                                <input ref={customerStreetRef} type='text' placeholder='Vaša ulica' defaultValue={localStorageShippingData ? localStorageShippingData.Street : ''} />
                                <p>{props.language.PostanskiBroj}</p>
                                <input ref={customerZIPref} type='text' placeholder='75350' defaultValue={localStorageShippingData ? localStorageShippingData.ZIP : ''} />
                                <p>{props.language.BrojTelefona}</p>
                                <input ref={customerPhoneRef} type='text' placeholder='Vaš broj telefona' defaultValue={localStorageShippingData ? localStorageShippingData.PhoneNumber : ''} />
                            </div>
                        </div>
                        <div style={{ display: Object.keys(shippingData).length !== 0 ? 'grid' : 'none' }} className='route__shoppingCart__gridbox__shippingDetails__form'>
                            <div>
                                <p>{props.language.ImeIprezime}</p>
                                <input ref={loggedCustomerNameRef} defaultValue={shippingData.ShippingName} type='text' />
                                <p>{props.language.BrojKuce}</p>
                                <input ref={loggedCustomerHouseNumberRef} defaultValue={shippingData.HouseNumber} type='text' />
                                <p>{props.language.Grad}</p>
                                <input ref={loggedCustomerCityRef} defaultValue={shippingData.City} type='text' />
                            </div>
                            <div>
                                <p>{props.language.Ulica}</p>
                                <input ref={loggedCustomerStreetRef} defaultValue={shippingData.Street} type='text' />
                                <p>{props.language.PostanskiBroj}</p>
                                <input ref={loggedCustomerZIPref} defaultValue={shippingData.ZIP} type='text' />
                                <p>{props.language.BrojTelefona}</p>
                                <input ref={loggedCustomerPhoneRef} defaultValue={shippingData.PhoneNumber} type='text' />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: generated && !shippingView ? '' : 'none' }} className='route__shoppingCart__gridbox__left'>
                        {
                            generatedOrderProducts[0] ? generatedOrderProducts.map((item, index) => {
                                return <div className='route__shoppingCart__gridbox__left__singleProduct'>
                                    <img src={item[0].ImageURL} alt='' />
                                    <div className='route__shoppingCart__gridbox__left__singleProduct__body'>
                                        <h2>{item[0].ProductName}</h2>
                                        <p>{item[0].Description}</p>
                                        <strong>
                                            {
                                                (() => {
                                                    switch (generatedOrder.data.Country) {
                                                        case 'Bosna i Hercegovina':
                                                            return 'BAM ';
                                                            break;
                                                        case 'Srbija':
                                                            return 'RSD ';
                                                            break;
                                                        case 'Hrvatska':
                                                            return 'EUR ';
                                                            break;
                                                        case 'Slovenija':
                                                            return 'EUR ';
                                                            break;
                                                        default: return 'EUR ';
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    switch (generatedOrder.data.Country) {
                                                        case 'Bosna i Hercegovina':
                                                            return Number(genProduct[index].Price).toFixed(2);
                                                            break;
                                                        case 'Srbija':
                                                            return Number(genProduct[index].PriceRS).toFixed(2);
                                                            break;
                                                        case 'Hrvatska':
                                                            return Number(genProduct[index].PriceHR).toFixed(2);
                                                            break;
                                                        case 'Slovenija':
                                                            return Number(genProduct[index].PriceSI).toFixed(2);
                                                            break;
                                                        default: return Number(genProduct[index].PriceSI).toFixed(2);
                                                    }
                                                })()
                                            }
                                        </strong>
                                        <QuantityField quantity={Number(genProduct[index].Quantity)} sum={sum} id={Number(genProduct[index].ProductID)} />
                                        <div id='shipping'><span><img src='images/dostava.png' alt='' /> <strong>{props.language.BesplatnaDostava}</strong></span></div>
                                    </div>
                                </div>
                            })
                                : <Spinner align='center' />
                        }
                    </div>
                    <div style={{ display: shippingView || generated ? 'none' : '' }} className='route__shoppingCart__gridbox__left'>
                        <div style={{display: spinner ? 'block' : 'none'}}><Spinner align='center' /></div>
                        <p id='emptyMessage' style={{ display: cartItems.length === 0 && cartItemsPP.length === 0 ? 'block' : 'none' }}>{props.language.NemateProizvodaUkosarici}</p>
                        {
                            cartItems.map((item, index) => {
                                return <div className='route__shoppingCart__gridbox__left__singleProduct'>
                                    <img src={item.ImageURL} alt='' />
                                    <div className='route__shoppingCart__gridbox__left__singleProduct__body'>
                                        <h2>{item.ProductName}</h2>
                                        <p>{item.Description}</p>
                                        <strong>{currency} {(Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2)}</strong>
                                        <QuantityField quantity={item.Quantity} sum={sum} id={item.ID} />
                                        <div id='shipping'><span><img src='images/dostava.png' alt='' /> <strong>{props.language.BesplatnaDostava}</strong></span> <span onClick={() => deleteProduct(index)}><img id='delIcon' src='images/delete 1.png' alt='' /><p>{props.language.Obrisi}</p></span></div>
                                    </div>
                                </div>
                            })
                        }
                        {
                            cartItemsPP.map((item, index) => {
                                return <div className='route__shoppingCart__gridbox__left__singleProduct'>
                                    <img src={item.ImageURL} alt='' />
                                    <div className='route__shoppingCart__gridbox__left__singleProduct__body'>
                                        <h2>{item.ProductName}</h2>
                                        <p>{item.Description}</p>
                                        <strong>{currency} {(Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2)}</strong>
                                        <QuantityField quantity={item.Quantity} sum={sum} id={item.ID} />
                                        <div id='shipping'><span><img src='images/dostava.png' alt='' /> <strong>{props.language.BesplatnaDostava}</strong></span> <span onClick={() => deleteProductPP(index)}><img id='delIcon' src='images/delete 1.png' alt='' /><p>{props.language.Obrisi}</p></span></div>
                                    </div>
                                </div>
                            })
                        }
                        <div className='route__shoppingCart__gridbox__left__info'>
                            <div>
                                <img src='images/plaviTelefon.png' alt='' />
                                <span>
                                    <strong>{props.language.ImatePitanja}</strong>
                                    <strong>{props.language.CCphoneNumber}</strong>
                                    <p>Pozovite nas na broj telefona prikazan iznad i naši agenti će Vam pružiti sve potrebne informacije.</p>
                                </span>
                            </div>
                            <div>
                                <img src='images/plavaTorba.png' alt='' />
                                <span>
                                    <strong>{props.language.sigurna100kupovina}</strong>
                                    <p>{props.language.Pobrinuli}</p>
                                </span>
                            </div>
                            <div>
                                <img src='images/plaviLajk.png' alt='' />
                                <span>
                                    <strong>{props.language.PovratNovca}</strong>
                                    <p>{props.language.UkolikoIzBiloKojeg}</p>
                                </span>
                            </div>
                            <div>
                                <img src='images/plavaKartica.png' alt='' />
                                <span>
                                    <strong>{props.language.PlacanjeNaRate}</strong>
                                    <p>{props.language.NudimoVamPlacanjeNaRate}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='route__shoppingCart__gridbox__right'>
                        <div className='route__shoppingCart__gridbox__right__box'>
                            <h3>{props.language.SazetakNarudzbe}</h3>
                            {
                                cartItems.map((item) => {
                                    return <div className='route__shoppingCart__gridbox__right__box__products'>
                                        <p>{item.ProductName}</p>
                                        <span>
                                            <strong>{currency}</strong>
                                            <strong>
                                                {((Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) - (qDiscount && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0)).toFixed(2)}
                                            </strong>
                                        </span>
                                    </div>
                                })
                            }
                            {
                                cartItemsPP.map((item) => {
                                    return <div className='route__shoppingCart__gridbox__right__box__products'>
                                        <p>{item.ProductName}</p>
                                        <span>
                                            <strong>{currency}</strong>
                                            <strong>{((Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) - (qDiscountPP && item.Quantity >= item.Discount.Quantity ? (Number(item.CurrentPrice) * Number(item.Quantity)).toFixed(2) * Number(item.Discount.Value) / 100 : 0)).toFixed(2)}</strong>
                                        </span>
                                    </div>
                                })
                            }
                            {
                                generatedOrderProducts[0] ? JSON.parse(generatedOrder.data.Products).map((item, index) => {
                                    return <div className='route__shoppingCart__gridbox__right__box__products'>
                                        <p>{item.Product}</p>
                                        <span>
                                            <strong>
                                                {
                                                    (() => {
                                                        switch (generatedOrder.data.Country) {
                                                            case 'Bosna i Hercegovina':
                                                                return 'BAM ';
                                                                break;
                                                            case 'Srbija':
                                                                return 'RSD ';
                                                                break;
                                                            case 'Hrvatska':
                                                                return 'EUR ';
                                                                break;
                                                            case 'Slovenija':
                                                                return 'EUR ';
                                                                break;
                                                            default: return 'EUR ';
                                                        }
                                                    })()
                                                }
                                            </strong>
                                            <strong>
                                                {
                                                    (() => {
                                                        switch (generatedOrder.data.Country) {
                                                            case 'Bosna i Hercegovina':
                                                                return <p>{Number(generatedOrderProducts[0][0].CurrentPrice * item.Quantity).toFixed(2)}</p>
                                                                break;
                                                            case 'Srbija':
                                                                return Number(genProduct[index].PriceRS).toFixed(2);
                                                                break;
                                                            case 'Hrvatska':
                                                                return Number(genProduct[index].PriceHR).toFixed(2);
                                                                break;
                                                            case 'Slovenija':
                                                                return Number(genProduct[index].PriceSI).toFixed(2);
                                                                break;
                                                            default: return Number(genProduct[index].PriceSI).toFixed(2);
                                                        }
                                                    })()
                                                }
                                            </strong>
                                        </span>
                                    </div>
                                })
                                    : ''
                            }
                            <span style={{ display: !qDiscount && !qDiscountPP ? 'none' : 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                {
                                    cartItems.map((item) => {
                                        if (item.Quantity >= item.Discount.Quantity && item.Discount.Quantity) {
                                            return <span style={{ marginTop: '10px', width: '100%' }}><p>{props.language.PopustNa} {item.ProductName}</p><p>{item.Discount.Value} %</p></span>
                                        }
                                    })
                                }
                                {
                                    cartItemsPP.map((item) => {
                                        if (item.Quantity >= item.Discount.Quantity && item.Discount.Quantity) {
                                            return <span style={{ marginTop: '10px', width: '100%' }}><p>{props.language.PopustNa} {item.ProductName}</p><p>{item.Discount.Value} %</p></span>
                                        }
                                    })
                                }
                            </span>
                            <div style={{ display: noCoupon ? 'block' : 'none' }} className='route__shoppingCart__gridbox__right__box__cuponCode'>
                                <small>{props.language.KuponKod}</small>
                                <span><input ref={couponRef} type='text' /><Button className='cuponCodeBtn' value='PRIMIJENI' accent='#0458AD' onClick={() => applyCoupon()} /></span>
                                <p style={{ color: 'red', marginTop: '5px', fontSize: '12px', textAlign: 'center' }} ref={msgRef}></p>
                            </div>
                            <div style={{ display: noCoupon ? 'none' : 'block' }} className='route__shoppingCart__gridbox__right__box__cuponCode'>
                                <strong>{props.language.KuponKod2} {couponCode}</strong>
                            </div>
                            <span>
                                <p>{props.language.UkupnoBezPDV}</p>
                                <p>
                                    {
                                        (() => {
                                            if (!generated) {
                                                return currency;
                                            }
                                            else {
                                                switch (generatedOrder.data.Country) {
                                                    case 'Bosna i Hercegovina':
                                                        return 'BAM ';
                                                        break;
                                                    case 'Srbija':
                                                        return 'RSD ';
                                                        break;
                                                    case 'Hrvatska':
                                                        return 'EUR ';
                                                        break;
                                                    case 'Slovenija':
                                                        return 'EUR ';
                                                        break;
                                                    default: return 'EUR ';
                                                }
                                            }
                                        })()
                                    }
                                    {totalWithoutPDV}
                                </p>
                            </span>
                            <span>
                                <p>{props.language.PDV}({(PDVpercent.toString()).replace('1.', '')}%)</p>
                                <p>
                                    {
                                        (() => {
                                            if (!generated) {
                                                return currency;
                                            }
                                            else {
                                                switch (generatedOrder.data.Country) {
                                                    case 'Bosna i Hercegovina':
                                                        return 'BAM ';
                                                        break;
                                                    case 'Srbija':
                                                        return 'RSD ';
                                                        break;
                                                    case 'Hrvatska':
                                                        return 'EUR ';
                                                        break;
                                                    case 'Slovenija':
                                                        return 'EUR ';
                                                        break;
                                                    default: return 'EUR ';
                                                }
                                            }
                                        })()
                                    }
                                    {PDVvalue}
                                </p>
                            </span>
                            <span>
                                <p>{props.language.Dostava}</p>
                                <p>{props.language.Besplatno}</p>
                            </span>
                            <span style={{ display: discount === null ? 'none' : 'flex' }}><p>Popust</p><p>{discount} {discountOption === '%' ? discountOption : currency}</p></span>
                            <span style={{ display: discount === null ? 'flex' : 'none' }}>
                                <strong>{props.language.Ukupno}</strong>
                                <strong>
                                    {
                                        (() => {
                                            if (!generated) {
                                                return currency;
                                            }
                                            else {
                                                switch (generatedOrder.data.Country) {
                                                    case 'Bosna i Hercegovina':
                                                        return 'BAM ';
                                                        break;
                                                    case 'Srbija':
                                                        return 'RSD ';
                                                        break;
                                                    case 'Hrvatska':
                                                        return 'EUR ';
                                                        break;
                                                    case 'Slovenija':
                                                        return 'EUR ';
                                                        break;
                                                    default: return 'EUR ';
                                                }
                                            }
                                        })()
                                    }
                                    {total}
                                </strong>
                            </span>
                            <span style={{ display: discount === null ? 'none' : 'flex' }}>
                                <strong>{props.language.Ukupno}</strong>
                                <strong>
                                    {
                                        (() => {
                                            if (!generated) {
                                                return currency;
                                            }
                                            else {
                                                switch (generatedOrder.data.Country) {
                                                    case 'Bosna i Hercegovina':
                                                        return 'BAM ';
                                                        break;
                                                    case 'Srbija':
                                                        return 'RSD ';
                                                        break;
                                                    case 'Hrvatska':
                                                        return 'EUR ';
                                                        break;
                                                    case 'Slovenija':
                                                        return 'EUR ';
                                                        break;
                                                    default: return 'EUR ';
                                                }
                                            }
                                        })()
                                    }
                                    {totalWithDiscount}
                                </strong>
                            </span>
                        </div>
                        <div style={{ display: cartItems.length === 0 && cartItemsPP.length === 0 && !generated || shippingView ? 'none' : '' }} className='route__shoppingCart__continue'>
                            <Button className='continueShopping' value={props.language.NastaviKupovinu} accent='transparent' onClick={() => { animateNavigate('/katalog') }} />
                            <Button className='continueBtn' value={props.language.NastaviNaOdabirDostave} accent='#0458AD' onClick={() => continueToShipping()} />
                        </div>
                        <div style={{ display: shippingView && !paymentView ? 'flex' : 'none' }} className='route__shoppingCart__continueToPayment'>
                            <Button className='cancelOrderBtn' value={props.language.PrekiniNarudzbu} accent='transparent' onClick={() => { setShippingView(false); setPaymentView(false) }} />
                            <Button className='continueToPaymentBtn' value={props.language.NastaviNaPlacanje} accent='#0458AD' onClick={() => continueToPayment()} />
                        </div>
                        <div style={{ display: paymentView && !paymentSuccess ? 'flex' : 'none' }} className='route__shoppingCart__continueToPayment'>
                            <Button className='cancelOrderBtn' value={props.language.PrekiniNarudzbu} accent='transparent' onClick={() => { setShippingView(false); setPaymentView(false) }} />
                            <Button className='continueToPaymentBtn' value={dropdownSelected === 'Plaćanje pouzećem' ? props.language.Naruci : props.language.Plati} accent='#0458AD' onClick={() => processPayment()} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer location={props.location}  language={props.language} />
        </>
    )
}
