import React from 'react';
import "./index.scss";
import Button from "../../components/customComponents/Button";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import Footer from '../../components/Footer';

export default function WhyNatural(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));
    const [width, setWidth] = React.useState(null);

    React.useEffect(() => {
        setWidth(window.innerWidth);
    }, []);
    
    const navigate = (path, pathENG, pathSI) => {
        if (
            props.location === 'BA' ||
            props.location === 'RS' ||
            props.location === 'HR'
        ) {
            animateNavigate(path);
        }
        else if(props.location === 'SI'){
            animateNavigate(pathSI);
        }
        else {
            animateNavigate(pathENG);
        }
    }

    return (
        <>
            <div className='route__whyNatural'>
                <div className='route__whyNatural__gridbox'>
                    <div className='route__whyNatural__gridbox__left'>
                        <h1>{props.language.ZastoPrirodno}</h1>
                        <p>
                            {props.language.KontentSest}
                        </p>
                    </div>
                    <img src='images/zastoprirodno1.png' alt='' />
                </div>
                <h2>{props.language.NekeOdPrednostiNasihProizvodaSu}</h2>
                <img style={{display: width < 1200 ? 'none' : ''}} id='secimg' src='images/zastoprirodno2.svg' alt='' />
                <img style={{display: width < 1200 && width > 700 ? 'block' : 'none'}} id='secimg' src='images/Tablet.svg' alt='' />
                <img style={{display: width < 700 ? 'block' : 'none'}} id='secimg' src='images/Phone.svg' alt='' />
                <Button className='gotoBtn' value={props.language.IdiNaWebShop} onClick={() => navigate('/katalog','/catalog', '/katalog')} />
            </div>
            <Footer location={props.location}  language={props.language} />
        </>
    )
}
